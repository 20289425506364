import * as React from "reactn";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import * as services from "_services";
import { Quiz, QuizWithStage } from "src/services/quizzes";

const useStyles = makeStyles((theme) => ({
  container: {},
  chips: {
    margin: theme.spacing(1, -0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  actions: {
    "&>button": {
      backgroundColor: "transparent",
      border: "none",
      padding: "10px 0",
      marginRight: "15px",
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  addAll: {},
  removeAll: {},
}));

type Props = {
  quizzes: Array<QuizWithStage>;
  onChange: (newState: Array<QuizWithStage>) => void;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<QuizWithStage | null>(null);
  const [search, setSearch] = React.useState<string>("");
  const [quizzes, setQuizzes] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    services.quizzes.findAll().then((items: any) => {
      const nextState = items.reduce(
        (acc: Array<QuizWithStage>, quiz: Quiz) => {
          if (quiz.slug !== "HABITOS") {
            for (const score of quiz.scores) {
              acc.push({
                id: `${quiz.slug}-${score.title}`,
                quizId: quiz.id,
                quiz: quiz.slug,
                stage: score.title,
                displayName: `${quiz.name} - ${score.description}`,
              });
            }
          }
          return acc;
        },
        []
      );
      setQuizzes(
        nextState.sort((a: any, b: any) => {
          if (a.displayName < b.displayName) return -1;
          if (a.displayName > b.displayName) return 1;
          return 0;
        })
      );
    });
  }, []);

  const onAdd = (quiz: QuizWithStage) => {
    if (props.quizzes.some((s: QuizWithStage) => s.id == quiz.id)) {
      return;
    }
    props.onChange([...props.quizzes, quiz]);
  };

  const onDelete = (quiz: QuizWithStage) => {
    props.onChange(
      props.quizzes.filter((s: QuizWithStage) => s.id !== quiz.id)
    );
  };

  const addAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onChange(quizzes);
  };

  const removeAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onChange([]);
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        value={selected}
        onChange={(event: any, newValue: QuizWithStage | null) => {
          onAdd(newValue as QuizWithStage);
          setSelected(null);
          setSearch("");
        }}
        options={
          quizzes.filter(
            (q: QuizWithStage) =>
              !props.quizzes.some((pq: QuizWithStage) => pq.id === q.id)
          ) as QuizWithStage[]
        }
        getOptionLabel={(option: QuizWithStage) => option.displayName}
        renderInput={(params) => (
          <TextField {...params} label="Mapeamentos" variant="outlined" />
        )}
        noOptionsText="Não encontrado"
      />

      <div className={classes.actions}>
        <button className={classes.addAll} onClick={addAll}>
          Adicionar todos
        </button>
        <button className={classes.removeAll} onClick={removeAll}>
          Remover todos
        </button>
      </div>

      <div className={classes.chips}>
        {props.quizzes.map((quiz: QuizWithStage) => {
          return (
            <Chip
              key={quiz.id.toString()}
              label={quiz.displayName}
              onDelete={() => onDelete(quiz)}
              className={classes.chip}
              variant="outlined"
              color="primary"
            />
          );
        })}
      </div>
    </div>
  );
};

Component.displayName = "Components_Quiz_SelectMany";

export default Component;
