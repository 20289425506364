const config = {
  apiURI: `${process.env.REACT_APP_API_URI}`,
  CNDEndpoint: `${process.env.REACT_APP_CDN_ENDPOINT}`,
  forgotPasswordRedirectUrl: `${process.env.REACT_APP_FORGOT_PASSWORD_REDIREC_URL}`,
  google: {
    geocode: process.env.REACT_APP_GOOGLE_GEOCODE,
  },
};

export default config;
