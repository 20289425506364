import React from "reactn";
import { useHistory, RouteProps } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";

import * as Term from "_components/Term";
import { PrivatePage } from "_components/Layout";
import { success as successReducer, error as errorReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const success = React.useDispatch<any>(successReducer);
  const error = React.useDispatch<any>(errorReducer);

  const onSuccess = () => {
    success("Termo cadastrado com sucesso");
    history.push("/documentos");
  };

  const onError = () => {
    error("Ocorreu um erro ao salvar. Verifique os campos e tente novamente.");
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "DOCUMENTOS",
                to: "/documentos",
              },
              {
                label: "CADASTRAR",
              },
            ]}
          />
        }
      ></AppBar>
      <Term.Form onSuccess={onSuccess} onError={onError} />
    </PrivatePage>
  );
};

Component.displayName = "screens_Term_Create";

export default Component;
