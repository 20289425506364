import React from "reactn";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Paper } from "@material-ui/core";
import { AppError } from "_errors";
import * as service from "_services";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#4A0B61",
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "80vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    maxWidth: 280,
  },
  success: {
    fontSize: "18px",
    textAlign: "center",
  },
  error: {
    fontSize: "18px",
    textAlign: "center",
  },
}));

type Props = {};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const params = useParams<{ token: string }>();
  const [classError, setClassError] = React.useState<string>("");
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setLoading(true);
    service.auth
      .validateEmail(params.token)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((e: any) => {
        setError(e as any);
        setClassError("animate__shakeX");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  let messageError: any = error?.toJSON();
  messageError = messageError?.server[0] || "";

  return (
    <div className={classes.wrapper}>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Paper elevation={4} className={classes.paper}>
          {success && (
            <div className={classes.success}>
              <strong>Email confirmado com sucesso.</strong> <br />
              <br />
              Agora você pode acessar o aplicativo normalmente.
            </div>
          )}
          {error !== null && (
            <div className={classes.error}>{messageError}</div>
          )}
        </Paper>
      </Container>
    </div>
  );
};

Component.displayName = "Components_Auth_ValidateEmail";

export default Component;
