import React from "react";
import { RouteProps } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Quiz from "_components/Quiz";
import { PrivatePage } from "_components/Layout";

const Component: React.FC<RouteProps> = () => {
  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "MAPEAMENTOS",
              },
            ]}
          />
        }
        Right={<></>}
      ></AppBar>
      <Quiz.List />
    </PrivatePage>
  );
};

Component.displayName = "screens_Pacient_List";

export default Component;
