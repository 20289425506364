import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, IconButton, Dialog, DialogContent } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import AppError from "src/errors/AppError";
import { QuizOption } from "src/services/quizzes";
import FormOption from "./form";
import TemplateOptions from "./Templates";
import * as services from "_services";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  optionContainer: {
    background: theme.palette.grey[100],
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    borderRadius: 4,
  },
  optionContent: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  title: {
    display: "flex",
    flex: 1,
    marginLeft: theme.spacing(2),
  },
  addOptionContainer: {
    background: theme.palette.grey[100],
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    borderRadius: 4,
  },
  drag: {
    cursor: "move",
    padding: 0,
    marginRight: 4,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
}));

type Props = {
  onSuccess?: () => void;
  onError?: (e: AppError | null) => void;
  options: Array<QuizOption>;
  quizId: number;
  questionId: number;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [showAddForm, setShowAddForm] = React.useState<boolean>(false);
  const [showModalTemplates, setShowModalTemplates] = React.useState<boolean>(
    false
  );
  const onCreateOption = () => {
    setShowAddForm(false);
    setShowModalTemplates(false);
    if (props.onSuccess) props.onSuccess();
  };

  const handleOnDragEnd = async (result: any) => {
    const option = props.options[result.source.index];
    const order = result.destination.index;
    await services.options.order(
      props.quizId,
      props.questionId,
      option.id,
      order
    );
    if (props.onSuccess) props.onSuccess();
  };

  return (
    <div className={classes.container}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div
              className="questions"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.options.map((option: QuizOption, index: number) => (
                <Draggable
                  key={option.id}
                  draggableId={option.id.toString()}
                  index={index}
                >
                  {(provided: any) => (
                    <div
                      key={option.id}
                      className={classes.optionContainer}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <IconButton
                        aria-label="ordenar"
                        className={classes.drag}
                        {...provided.dragHandleProps}
                      >
                        <DragHandleIcon />
                      </IconButton>
                      <FormOption
                        onSuccess={onCreateOption}
                        option={option}
                        quizId={props.quizId}
                        questionId={props.questionId}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className={classes.addOptionContainer}>
        {showAddForm === false && (
          <div className={classes.actions}>
            <Button
              color="primary"
              onClick={() => {
                setShowModalTemplates(showAddForm ? false : true);
              }}
            >
              Utilizar template
            </Button>
            <Button
              color="primary"
              startIcon={<AddIcon color="primary" />}
              onClick={() => {
                setShowAddForm(showAddForm ? false : true);
              }}
            >
              Adicionar resposta
            </Button>
          </div>
        )}
        {showAddForm && (
          <FormOption
            quizId={props.quizId}
            questionId={props.questionId}
            onSuccess={onCreateOption}
            onCancel={() => {
              setShowAddForm(false);
            }}
          />
        )}
      </div>

      <Dialog
        open={showModalTemplates}
        onClose={() => setShowModalTemplates(false)}
      >
        <DialogContent>
          <TemplateOptions
            quizId={props.quizId}
            questionId={props.questionId}
            onSuccess={onCreateOption}
            onCancel={() => {
              setShowModalTemplates(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

Component.displayName = "Components_Quiz_Forms_Option";

export default Component;
