import { IRoutes } from "./types";
import Dashboard from "_pages/Dashboard";
import * as Auth from "_pages/Auth";
import * as MyAccount from "_pages/MyAccount";
import * as Admin from "_pages/Admin";
import * as Pacient from "_pages/Pacient";
import * as Doctor from "_pages/Doctor";
import * as Evaluation from "_pages/Evaluation";
import * as Quiz from "_pages/Quiz";
import * as Content from "_pages/Content";
import * as Planner from "_pages/Planner";
import * as Specialty from "_pages/Specialty";
import * as Plan from "_pages/Plan";
import * as Terms from "_pages/Terms";
import Pdf from "_pages/Pdf";
import Video from "_pages/Video";

const routes: IRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    Component: Doctor.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/pdf",
    name: "Preview PDF",
    Component: Pdf,
    isModal: false,
    redirectToDashboardIfLogged: false,
    isPrivate: false,
  },
  {
    path: "/video",
    name: "Video",
    Component: Video,
    isModal: false,
    redirectToDashboardIfLogged: false,
    isPrivate: false,
  },
  {
    path: "/",
    name: "Login",
    Component: Auth.Login,
    isModal: false,
    redirectToDashboardIfLogged: true,
  },
  {
    path: "/recuperar-senha",
    name: "Recuperar senha",
    Component: Auth.ForgotPassword,
    isModal: false,
    redirectToDashboardIfLogged: true,
  },
  {
    path: "/atualizar-senha/:token",
    name: "Atualizar senha",
    Component: Auth.UpdatePassword,
    isModal: false,
    redirectToDashboardIfLogged: false,
  },
  {
    path: "/atualizar-senha-app/:token",
    name: "Atualizar senha app",
    Component: Auth.UpdatePasswordApp,
    isModal: false,
    redirectToDashboardIfLogged: false,
  },
  {
    path: "/excluir-conta/:token",
    name: "Excluir conta",
    Component: Auth.RemoveAccount,
    isModal: false,
    redirectToDashboardIfLogged: false,
  },
  {
    path: "/confirmar-email/:token",
    name: "Confrmar Email",
    Component: Auth.ValidateEmail,
    isModal: false,
    redirectToDashboardIfLogged: false,
  },
  {
    path: "/minha-conta",
    name: "Minha conta",
    Component: MyAccount.Update,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/administradores",
    name: "Administradores",
    Component: Admin.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/administradores/cadastrar",
    name: "Cadastrar administradores",
    Component: Admin.Create,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/administradores/editar/:id",
    name: "Editar administrador",
    Component: Admin.Update,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/pacientes",
    name: "ClienteS",
    Component: Pacient.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/pacientes/cadastrar",
    name: "Cadastrar Cliente",
    Component: Pacient.Create,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/pacientes/:id",
    name: "Cliente",
    Component: Pacient.Detail,
    isModal: false,
    isPrivate: true,
  },

  {
    path: "/pacientes/editar/:id",
    name: "Editar Cliente",
    Component: Pacient.Update,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/profissionais",
    name: "Profissionais",
    Component: Doctor.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/profissionais/cadastrar",
    name: "Cadastrar Profissional",
    Component: Doctor.Create,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/profissionais/editar/:id",
    name: "Editar Profissional",
    Component: Doctor.Update,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/areas-de-atuacao",
    name: "Áreas de atuação",
    Component: Specialty.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/areas-de-atuacao/cadastrar",
    name: "Cadastrar Área de atuação",
    Component: Specialty.Create,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/areas-de-atuacao/editar/:id",
    name: "Editar Área de atuação",
    Component: Specialty.Update,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/prognosticos",
    name: "Prognósticos",
    Component: Evaluation.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/prognosticos/:id",
    name: "Prognóstico",
    Component: Evaluation.Detail,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/mapeamentos",
    name: "Mapeamentos",
    Component: Quiz.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/mapeamentos/editar/:id",
    name: "Editar mapeamento",
    Component: Quiz.Update,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/conteudos",
    name: "Conteúdo",
    Component: Content.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/conteudos/cadastrar",
    name: "Cadastrar Conteúdo",
    Component: Content.Create,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/conteudos/editar/:id",
    name: "Editar Conteúdo",
    Component: Content.Update,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/planner",
    name: "Planner",
    Component: Planner.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/planos",
    name: "Planos",
    Component: Plan.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/planos/recursos",
    name: "Recursos dos planos",
    Component: Plan.Resources,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/planos/descontos",
    name: "Descontos dos planos",
    Component: Plan.Discounts,
    isModal: false,
    isPrivate: true,
  },

  {
    path: "/documentos",
    name: "Termos",
    Component: Terms.List,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/documentos/cadastrar",
    name: "Cadastrar Termo",
    Component: Terms.Create,
    isModal: false,
    isPrivate: true,
  },
  {
    path: "/documentos/visualizar/",
    name: "Termo",
    Component: Terms.Detail,
    isModal: false,
    isPrivate: false,
  },

  {
    path: "/documentos/editar/:id",
    name: "Editar Termo",
    Component: Terms.Update,
    isModal: false,
    isPrivate: true,
  },

  // {
  //   path: "/documentos/privacidade",
  //   name: "",
  //   Component: Terms.Privacy,
  //   isModal: false,
  //   isPrivate: false,
  //   redirectToDashboardIfLogged: false,
  // },
  // {
  //   path: "/documentos/privacidade/eng",
  //   name: "",
  //   Component: Terms.PrivacyEng,
  //   isModal: false,
  //   isPrivate: false,
  //   redirectToDashboardIfLogged: false,
  // },
  // {
  //   path: "/documentos/gtdp",
  //   name: "",
  //   Component: Terms.LGPD,
  //   isModal: false,
  //   redirectToDashboardIfLogged: false,
  //   isPrivate: false,
  // },
];

export default routes;
