const domains = {
  FACEBOOK: "https://m.facebook.com",
  INSTAGRAM: "https://m.instagram.com",
  LINKEDIN: "https://linkedin.com/in",
};

const isURL = (input: string): boolean => {
  return input.includes(".com");
};

export const getURL = (
  input: string,
  platform: "FACEBOOK" | "LINKEDIN" | "INSTAGRAM"
): string => {
  if (input) {
    if (isURL(input)) {
      return input;
    }

    if (domains[platform]) {
      return domains[platform] + "/" + input;
    }
  }

  return "";
};
