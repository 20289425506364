import * as React from "reactn";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Switch,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { Check } from "@material-ui/icons";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as mask from "_helpers/masks";
import * as services from "_services";
import * as Media from "_components/Media";
import config from "_config";
import Address from "_components/Address";
import * as Specialty from "_components/Specialty";
import Phone from "_UI/Phone";
import { Alert, Autocomplete } from "@material-ui/lab";
import { getURL } from "_helpers/social";
import replaceAll from "_helpers/replaceAll";
import { IDoctor } from "src/services/doctors";
import RefusedMessage from "./RefusedMessage";
import { bodies } from "../constants";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
	container: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
	},
	title: {
		fontWeight: 400,
		fontSize: 20,
		marginBottom: theme.spacing(1),
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: theme.spacing(1.5, 5),
		alignSelf: "flex-end",
		display: "flex",
		float: "right",
	},
	center: {
		textAlign: "center",
	},
	error: {
		margin: theme.spacing(2, 0),
	},
	spacing: {
		margin: theme.spacing(2, 0),
	},
	noSpacing: {
		marginTop: theme.spacing(0) + " !important",
		marginBottom: theme.spacing(0) + " !important",
		paddingTop: theme.spacing(0) + " !important",
		paddingBottom: theme.spacing(0) + " !important",
	},
	group: {
		marginBottom: theme.spacing(3),
	},
	groupTitle: {
		marginTop: theme.spacing(0) + " !important",
		marginBottom: theme.spacing(0) + " !important",
		fontSize: "12px",
		textTransform: "uppercase",
	},
	avatar: {
		width: 230,
		margin: theme.spacing(2, 0),
		padding: 15,
		borderRadius: 6,
		background: theme.palette.grey[100],
	},
	documents: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(2),
		borderRadius: 6,
		background: theme.palette.grey[100],
	},
	loading: {
		marginRight: theme.spacing(1),
	},
	social: {
		position: "relative",
		top: -5,
	},
}));

const schema = yup.object().shape({
	// avatar: yup.string().required("Campo obrigatório"),

	name: yup.string().required("Campo obrigatório"),

	email: yup.string().email("E-mail inválido").required("Campo obrigatório"),

	password: yup
		.string()
		.test("password", "Campo obrigatório", function (value: any) {
			if (!value && !this.parent.isUpdate) {
				return false;
			}
			return true;
		}),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "As senhas estão diferentes"),

	fullAddress: yup.string().required("Campo obrigatório"),

	cep: yup.string().nullable().required("Campo obrigatório"),

	birthdate: yup
		.string()
		.required("Campo obrigatório")
		.test("birthdate", "Data inválida", function (value: any) {
			if (!value) {
				return true;
			}

			if (!value || value?.length < 10) {
				return false;
			}

			const diff = moment(value, "DD.MM.YYYY").diff(new Date(), "years");
			if (diff > -1 || diff < -120 || !moment(value, "DD.MM.YYYY").isValid()) {
				return false;
			}

			const years = moment().diff(value, "years", false);
			if (years < 18) {
				return false;
			}

			return true;
		}),

	education: yup.string().required("Campo obrigatório"),

	bio: yup.string().nullable().required("Campo obrigatório"),

	description: yup.string().nullable().required("Campo obrigatório"),

	cpf: yup
		.string()
		.test("oneOfRequired", "Preencha o CPF ou CNPJ", function (item) {
			return this.parent.cpf || this.parent.cnpj;
		}),

	cnpj: yup
		.string()
		.test("oneOfRequired", "Preencha o CPF ou CNPJ", function (item) {
			return this.parent.cpf || this.parent.cnpj;
		}),

	// phone: yup
	// 	.string()
	// 	.nullable()
	// 	.test("oneOfRequired", "Preencha o telefone ou whatsapp", function (item) {
	// 		return this.parent.phone || this.parent.cellphone;
	// 	}),

	cellphone: yup
		.string()
		.nullable()
		.required("Campo Obrigatório")
		.test("oneOfRequired", "Preencha o com o seu Whatsapp", function (item) {
			return this.parent.phone || this.parent.cellphone;
		}),

	assistancePacientAgeGroup: yup
		.string()
		.nullable()
		.required("Campo obrigatório"),

	assistanceGenders: yup
		.array()
		.of(yup.mixed().required())
		.required()
		.min(1, "Campo obrigatório"),

	assistanceStages: yup
		.array()
		.of(yup.mixed().required())
		.required()
		.min(1, "Campo obrigatório"),

	assistanceSchedules: yup
		.array()
		.of(yup.mixed().required())
		.required()
		.min(1, "Campo obrigatório"),

	assistanceTypes: yup
		.array()
		.of(yup.mixed().required())
		.required()
		.min(1, "Campo obrigatório"),

	specialties: yup
		.array()
		.of(yup.mixed().required())
		.required()
		.min(1, "Campo obrigatório"),

	bodies: yup
		.array()
		.of(yup.mixed().required())
		.required()
		.min(1, "Campo obrigatório"),
});

type Props = {
	onSuccess?: () => void;
	onError?: () => void;
	id?: number;
};

const Component: React.FC<Props> = (props) => {
	const [authId, setAuthId] = React.useState<number | null>(null);
	const [doctorId, setAdminId] = React.useState<number | null>(null);
	const [doctor, setDoctor] = React.useState<IDoctor | null>(null);
	const [stages, setStages] = React.useState<Array<any>>([]);
	const [genders, setGenders] = React.useState<Array<any>>([]);
	const [types, setTypes] = React.useState<Array<any>>([]);
	const [schedules, setSchedules] = React.useState<Array<any>>([]);
	/* const [ageGroup, setAgeGroup] = React.useState<Array<any>>([]); */
	const [classError, setClassError] = React.useState<string>("");
	const classes = useStyles();
	const [error, setError] = React.useState<AppError | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [prevStatus, setPrevStatus] = React.useState<string | null>(null);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [numberChange, setNumberChange] = React.useState<string>("");
	const [DDIChange, setDDIChange] = React.useState<string>("");
	const [numberPhoneChange, setNumberPhoneChange] = React.useState<string>("");
	const [DDIPhoneChange, setDDIPhoneChange] = React.useState<string>("");

	const [showRefusedMessageDialog, setShowRefusedMessageDialog] =
		React.useState<boolean>(false);
	const {
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = useForm<any>({
		resolver: yupResolver(schema),
		defaultValues: {
			isUpdate: false,
			avatar: null,
			media: null,
			currentAvatar: "",
			documents: [],
			specialties: [],
			bodies: [],
			savingDocuments: false,
			name: "",
			email: "",
			cpf: "",
			cnpj: "",
			doc: [],
			employees: "0",
			bio: "",
			description: "",
			education: "",
			password: "",
			confirmPassword: "",
			phone: "",
			cellphone: "",
			gender: "",
			birthdate: "",
			lat: "",
			lng: "",
			crm: "",
			cep: "",
			neighborhood: "",
			street: "",
			number: "",
			complement: "",
			city: "",
			state: "",
			country: "",
			fullAddress: "",
			status: "",
			authStatus: "",
			assistancePacientAgeGroup: "",
			assistanceStages: [],
			assistanceSchedules: [],
			assistanceTypes: [],
			assistanceGenders: [],
			quizAssistance: "",
			refusedMessage: "",
			hide: false,
			facebook: "",
			instagram: "",
			linkedin: "",
			isVip: false,
		},
	});
	watch();

	const values = getValues();

	React.useEffect(() => {
		if (!values.cellphone) {
			return;
		}

		if (values.cellphone.length >= 5) {
			setDDIChange(`${values.cellphone.replace(/[^0-9]/g, "").slice(0, 2)}`);
		}
		if (values.cellphone.length >= 3) {
			setNumberChange(
				values.cellphone
					.replace(/[^0-9]/g, "")
					.slice(2, values.cellphone.length)
			);
		}
	}, [values.cellphone]);

	React.useEffect(() => {
		if (!values.phone) {
			return;
		}

		if (values.phone.length >= 5) {
			setDDIPhoneChange(`${values.phone.replace(/[^0-9]/g, "").slice(0, 2)}`);
		}
		if (values.phone.length >= 3) {
			setNumberPhoneChange(
				values.phone.replace(/[^0-9]/g, "").slice(2, values.phone.length)
			);
		}
	}, [values.phone]);

	React.useEffect(() => {
		const newStatus = getValues().status;
		if (
			newStatus === "DISABLED" &&
			prevStatus !== null &&
			prevStatus != newStatus
		) {
			setShowRefusedMessageDialog(true);
		} else {
			// setShowRefusedMessageDialog(false);
		}

		if (props.id && loaded) {
			if (prevStatus === null || prevStatus !== newStatus) {
				setPrevStatus(newStatus);
			}
		}
	}, [props.id, getValues()]);

	const setValues = async (id: number) => {
		watch();
		setValue("isUpdate", id ? true : false);
		const doctor = await services.doctors.findById(id);
		setDoctor(doctor);
		setAdminId(doctor.id);
		setAuthId(doctor.authId);
		setValue("name", doctor.name);
		setValue("email", doctor.email);
		if (doctor.birthdate) {
			setValue("birthdate", mask.dateToBirthdate(doctor.birthdate));
		}
		if (doctor.cpf) {
			setValue("cpf", mask.cpf(doctor.cpf));
		}

		setValue("cnpj", doctor.cnpj || "");
		setValue("employees", doctor.employees || "0-3");
		setValue("bio", doctor.bio);
		setValue("description", doctor.description || "");
		setValue("education", doctor.education);
		setValue("phone", doctor.phone);
		setValue("cellphone", doctor.cellphone);
		setValue("gender", doctor.gender);
		setValue("city", doctor.city);
		setValue("state", doctor.state);
		setValue("country", doctor.country);
		setValue("lat", doctor.lat);
		setValue("lng", doctor.lng);
		setValue("specialties", doctor.specialties || []);
		setValue("bodies", doctor.bodies || []);
		setValue("crm", doctor.crm);
		setValue("cep", doctor.cep);
		setValue("neighborhood", doctor.neighborhood);
		setValue("street", doctor.street);
		setValue("number", doctor.number);
		setValue("complement", doctor.complement);
		setValue("status", doctor.status);
		setValue("authStatus", doctor.authStatus);
		setValue("quizAssistance", doctor.quizAssistance);
		setValue("hide", doctor.hide);
		setValue("assistanceStages", doctor.stages);
		setValue("assistanceGenders", doctor.genders);
		setValue("assistanceSchedules", doctor.schedules);
		setValue("assistanceTypes", doctor.types);
		setValue("assistancePacientAgeGroup", doctor.assistancePacientAgeGroup);
		setValue("facebook", doctor.facebook);
		setValue("instagram", doctor.instagram);
		setValue("linkedin", doctor.linkedin);
		setValue("refusedMessage", doctor.refusedMessage || null);
		setValue("isVip", doctor.planStatus === "VIP");

		if (doctor.city && doctor.state && doctor.country) {
			setValue(
				"fullAddress",
				`${doctor.city}, ${doctor.state}, ${doctor.country}`
			);
		}

		if (doctor?.media?.AVATAR) {
			setValue(
				"currentAvatar",
				`${config.CNDEndpoint}/${doctor?.media?.AVATAR?.media?.src}`
			);

			setValue(
				"avatar",
				`${config.CNDEndpoint}/${doctor?.media?.AVATAR?.media?.src}`
			);
		}

		if (doctor?.media) {
			setValue("media", doctor?.media);
		}
		setLoaded(true);
	};

	const clearState = () => {
		setError(null);
		setValue("avatar", null);
		setValue("media", null);
		setValue("currentAvatar", "");
		setValue("documents", []);
		setValue("specialties", []);
		setValue("bodies", []);
		setValue("savingDocuments", false);
		setValue("savingDocumentsError", "");
		setValue("email", "");
		setValue("cpf", "");
		setValue("bio", "");
		setValue("description", "");
		setValue("education", "");
		setValue("password", "");
		setValue("confirmPassword", "");
		setValue("name", "");
		setValue("birthdate", "");
		setValue("phone", "");
		setValue("cellphone", "");
		setValue("lat", "");
		setValue("lng", "");
		setValue("city", "");
		setValue("state", "");
		setValue("country", "");
		setValue("fullAddress", "");
		setValue("crm", "");
		setValue("cep", "");
		setValue("neighborhood", "");
		setValue("street", "");
		setValue("number", "");
		setValue("complement", "");
		setValue("status", "");
		setValue("authStatus", "");
		setValue("assistanceStages", "");
		setValue("assistancePacientAgeGroup", "");
		setValue("assistanceSchedules", "");
		setValue("assistanceTypes", "");
		setValue("assistanceGenders", "");
		setValue("quizAssistance", "");
		setValue("hide", "");
		setValue("facebook", "");
		setValue("instagram", "");
		setValue("linkedin", "");
		setValue("isVip", false);
	};

	React.useEffect(() => {
		if (props.id) {
			setValues(props.id);
		}

		services.stages.findAll().then(({ items }) => {
			setStages(items);
		});

		services.types.findAll().then(({ items }) => {
			setTypes(items);
		});

		services.genders.findAll().then(({ items }) => {
			setGenders(items);
		});

		/* services.ageGroup.findAll().then((response) => {
      setAgeGroup(response);
    }); */

		services.schedules.findAll().then(({ items }) => {
			setSchedules(items);
		});
	}, []);

	const onError = (e: any) => {
		if (props.onError) props.onError();
		console.log(e);
		setClassError("");
		setTimeout(() => {
			setClassError("animate__shakeX");
		}, 0);
	};

	const onChange = (e: any): void => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		if (name == "birthdate") {
			setValue(name, mask.birthdate(value));
		} else if (name == "cpf") {
			setValue(name, mask.cpf(value));
		} else if (name == "cnpj") {
			setValue(name, mask.cnpj(value));
		} else {
			setValue(name, value);
		}
	};

	const setAddress = (newState: any) => {
		if (newState) {
			setValue("lat", newState.location.lat);
			setValue("lng", newState.location.lng);
			setValue("city", newState.city);
			setValue("state", newState.state);
			setValue("country", newState.country);
			setValue("fullAddress", newState.fullAddress);
		} else {
			setValue("lat", "");
			setValue("lng", "");
			setValue("city", "");
			setValue("state", "");
			setValue("country", "");
			setValue("fullAddress", "");
		}
	};

	const onNewAvatar = async (form: FormData) => {
		form.append("filename", "avatar");
		form.append("tag", "AVATAR");

		//@ts-ignore
		setValue("avatar", form);

		if (props.id) {
			saveAvatar(props.id, form);
		}
	};

	const saveAvatar = async (doctorId: number, form: FormData) => {
		if (values.media?.AVATAR) {
			await services.doctors.removeFile(
				props.id || 0,
				values.media?.AVATAR?.id
			);
		}

		try {
			const doctor = await services.doctors.saveFile(doctorId as number, form);
			setValue("media", doctor.media);
		} catch (e) {
			console.error("ERRO AO SALVAR A IMAGEM", e);
		}
	};

	const onRemoveAvatar = async () => {
		if (props.id && values.media?.AVATAR) {
			await services.doctors.removeFile(props.id, values.media?.AVATAR?.id);
			const doctor = await services.doctors.findById(props.id);
			setValue("media", doctor.media);
		}

		setValue("avatar", null);
		setValue("currentAvatar", "");
	};

	const createDocuments = async (documents: any) => {
		setValue("documents", documents);

		if (props.id) {
			saveDocuments(props.id, documents);
		}
	};

	const removeDocument = async (documentId: number) => {
		if (props.id && documentId) {
			setValue("savingDocuments", true);
			await services.doctors.removeFile(props.id as number, documentId);
			const doctor = await services.doctors.findById(props.id as number);
			setValue("media", doctor.media);
			setValue("savingDocuments", false);
		}
	};

	const donwloadDocument = async (src: string) => {
		const href = await services.doctors.downloadFile(props.id as number, src);
		const a = document.createElement("a");
		a.setAttribute("href", href);
		a.setAttribute("download", "true");
		document.body.append(a);
		a.click();
		a.remove();
	};

	const saveDocuments = async (doctorId: number, documents: any) => {
		try {
			setValue("savingDocuments", true);
			await Promise.all(
				documents.map((doc: any) => {
					const form = new FormData();
					form.append("file", doc.file);
					form.append("filename", doc.filename);
					form.append("tag", doc.tag);
					form.append("doctorId", doctorId.toString());
					return services.doctors.saveFile(doctorId, form);
				})
			);

			const doctor = await services.doctors.findById(props.id as number);
			setValue("savingDocuments", false);
			setValue("savingDocumentsError", "");
			setValue("documents", []);

			if (doctor) {
				setValue("media", doctor.media);
			}
		} catch (e) {
			setValue("savingDocuments", false);
			setValue("savingDocumentsError", e);
			console.error("ERRO AO SALVAR OS DOCUMENTOS", e);
		}
	};

	const onSubmit = async () => {
		setError(null);
		setLoading(true);
		setClassError("");

		const values = getValues();
		const requests = [];
		let doctorData: any = {
			name: values.name,
			bio: values.bio,
			education: values.education,
			birthdate: mask.birthdateToDate(values.birthdate),
			cpf: values.cpf ? mask.cpfToNumber(values.cpf) : "",
			gender: values.gender,
			phone: `${DDIPhoneChange}${numberPhoneChange}`,
			cellphone: `${DDIChange}${numberChange}`,
			lat: values.lat.toString(),
			lng: values.lng.toString(),
			cep: values.cep,
			neighborhood: values.neighborhood,
			street: values.street,
			complement: values.complement,
			crm: values.crm,
			number: values.number,
			city: values.city,
			state: values.state,
			country: values.country,
			specialties: values.specialties.map((s: any) => s.id),
			bodies: values.bodies.map((b: any) => b.body),
			status: values.status,
			description: values.description,
			assistancePacientAgeGroup: values.assistancePacientAgeGroup,
			quizAssistance: values.quizAssistance,
			hide: values.hide,
			stages: values.assistanceStages,
			schedules: values.assistanceSchedules,
			types: values.assistanceTypes,
			genders: values.assistanceGenders,
			facebook: values.facebook ? replaceAll(values.facebook, "@", "") : null,
			instagram: values.instagram
				? replaceAll(values.instagram, "@", "")
				: null,
			linkedin: values.linkedin ? replaceAll(values.linkedin, "@", "") : null,
			isVip: values.isVip,
			refusedMessage: values.refusedMessage || "",
		};

		doctorData = Object.keys(doctorData).reduce((acc: any, prop: any) => {
			if (doctorData[prop] !== "") {
				acc[prop] = doctorData[prop];
			} else {
				acc[prop] = null;
			}
			return acc;
		}, {});

		if (values.cnpj) {
			doctorData.cnpj = values.cnpj;
			doctorData.employees = values.employees;
		} else {
			// doctorData.cnpj = "";
			doctorData.employees = "0";
		}

		if (props.id) {
			const dataAuth: any = {
				id: authId,
				email: values.email,
				status: values.authStatus,
			};

			if (values.password) {
				dataAuth.password = values.password;
			}

			// update auth
			requests.push(services.auth.update(dataAuth));

			// update doctor
			const validatedDocuments = await validateDocuments(documents);
			if (validatedDocuments === true) {
				requests.push(
					services.doctors.update({
						id: doctorId,
						...doctorData,
					})
				);
			}
		} else {
			const validatedDocuments = await validateDocuments(values.documents);
			if (validatedDocuments === true) {
				requests.push(
					services.doctors.create({
						email: values.email,
						password: values.password,
						...doctorData,
					})
				);
			}
		}

		try {
			const data = await Promise.all(requests);
			const doctor = data[data.length - 1];

			if (doctor && !props.id) {
				if (Array.isArray(values.documents) && values.documents.length > 0) {
					await saveDocuments(doctor.id, values.documents);
				}

				if (values.avatar) {
					await saveAvatar(doctor.id, values.avatar);
				}
			}

			if (props.onSuccess) {
				props.onSuccess();
			} else {
				clearState();
			}
		} catch (e) {
			setClassError("animate__shakeX");
			setError(e as any);
			if (props.onError) props.onError();
		} finally {
			setLoading(false);
		}
	};

	const validateDocuments = (documents: Array<Document>) => {
		return new Promise<boolean>((resolve, reject) => {
			const documentsContainer = document.querySelector(".certificates");
			const errorText =
				documentsContainer?.parentElement?.querySelector(".custom-error-text");

			documentsContainer?.classList.remove("custom-error");
			if (errorText) {
				documentsContainer?.parentElement?.removeChild(errorText);
			}

			if (Array.isArray(values.documents) && documents.length === 0) {
				const errorMessage = document.createElement("p");
				errorMessage.innerHTML = "Campo obrigatório";
				errorMessage.classList.add("custom-error-text");

				setClassError("animate__shakeX");
				if (props.onError) props.onError();

				documentsContainer?.classList.add("custom-error");
				documentsContainer?.parentElement?.appendChild(errorMessage);

				setLoading(false);
				return reject();
			} else {
				return resolve(true);
			}
		});
	};

	const media = values?.media;
	let documents: Array<any> = [];
	if (media) {
		documents = [...(media.CURRICULUM || []), ...(media.EDUCATION || [])];
	}

	useEffect(() => {
		const especialidadesInput = document.querySelector(
			".especialidades-container input"
		);
		if (errors.specialties) {
			if (especialidadesInput) {
				especialidadesInput.parentElement!.parentElement!.style.border =
					"1px solid #E73E5E";
			}
		} else {
			especialidadesInput!.parentElement!.parentElement!.style.border = "none";
		}
	}, [errors.specialties]);

	return (
		<div className={classes.container}>
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
				className={classes.form}
				noValidate
			>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} lg={12} xl={8}>
						<h3 className={classes.title}>Dados pessoais</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<div
										className={classes.avatar}
										style={errors.avatar && { border: "1px solid #E73E5E" }}
									>
										<Media.Avatar
											onCancel={onRemoveAvatar}
											onNewFile={onNewAvatar}
											currentFile={values.currentAvatar}
											width={200}
											height={200}
										/>
									</div>
									{errors.avatar && (
										<p className="custom-error-text" id="education-helper-text">
											Campo obrigatório
										</p>
									)}
								</Grid>

								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="name"
										label="Nome"
										name="name"
										onChange={onChange}
										error={hasInputError(error, errors, "name")}
										helperText={getInputError(error, errors, "name")}
										value={values.name}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="cpf"
										label="CPF"
										name="cpf"
										onChange={onChange}
										error={hasInputError(error, errors, "cpf")}
										helperText={getInputError(error, errors, "cpf")}
										value={values.cpf}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="crm"
										label="Registro Profissional"
										name="crm"
										onChange={onChange}
										error={hasInputError(error, errors, "crm")}
										helperText={getInputError(error, errors, "crm")}
										value={values.crm}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="birthdate"
										label="Data de nascimento (+18)"
										name="birthdate"
										onChange={onChange}
										error={hasInputError(error, errors, "birthdate")}
										helperText={getInputError(error, errors, "birthdate")}
										value={values.birthdate}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<FormControl
										variant="outlined"
										fullWidth
										className={classes.spacing}
									>
										<InputLabel id="gender-id">Me identifico como</InputLabel>
										<Select
											labelId="gender-id"
											value={values.gender}
											error={hasInputError(error, errors, "gender")}
											onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
												const value = e.target.value as string;
												setValue("gender", value);
											}}
											name="gender"
											label="Me identifico como"
										>
											<MenuItem value="">
												<em>Prefiro não informar</em>
											</MenuItem>
											<MenuItem value="MULHER_CISGENERO">
												Mulher Cisgênero
											</MenuItem>
											<MenuItem value="HOMEM_CISGENERO">
												Homem Cisgênero
											</MenuItem>
											<MenuItem value="MULHER_TRANSGENERO">
												Mulher Transgênero
											</MenuItem>
											<MenuItem value="HOMEM_TRANSGENERO">
												Homem Transgênero
											</MenuItem>
											<MenuItem value="GENERO_NAO_BINARIO">
												Gênero Não-Binário
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								{/* <Grid item xs={12} md={4} lg={3}>
									<Phone
										label="Telefone"
										onChange={(newState: string) => setValue("phone", newState)}
										error={hasInputError(error, errors, "phone")}
										helperText={getInputError(error, errors, "phone")}
										value={values.phone}
										DDIChange={DDIPhoneChange}
										setDDIChange={setDDIPhoneChange}
										numberChange={numberPhoneChange}
										setNumberChange={setNumberPhoneChange}
									/>
								</Grid> */}
								<Grid item xs={12} md={4} lg={3}>
									<Phone
										label="WhatsApp"
										onChange={(newState: string) =>
											setValue("cellphone", newState)
										}
										error={hasInputError(error, errors, "cellphone")}
										helperText={getInputError(error, errors, "cellphone")}
										value={values.cellphone}
										DDIChange={DDIChange}
										setDDIChange={setDDIChange}
										numberChange={numberChange}
										setNumberChange={setNumberChange}
									/>
								</Grid>

								<Grid item xs={12} md={4} lg={3}>
									<FormControl
										variant="outlined"
										fullWidth
										className={classes.spacing}
									>
										<InputLabel id="education-id">Escolaridade</InputLabel>
										<Select
											labelId="education-id"
											value={values.education}
											error={hasInputError(error, errors, "education")}
											onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
												const value = e.target.value as string;
												setValue("education", value);
											}}
											name="education"
											label="Escolaridade"
										>
											<MenuItem value="Curso livre">Curso livre</MenuItem>
											<MenuItem value="Curso técnico">Curso técnico</MenuItem>
											<MenuItem value="Tecnólogo">Tecnólogo</MenuItem>
											<MenuItem value="Graduação completa">
												Graduação completa
											</MenuItem>
											<MenuItem value="Especialização">Especialização</MenuItem>
										</Select>
										{errors.education && (
											<p
												className="custom-error-text"
												id="education-helper-text"
											>
												Campo obrigatório
											</p>
										)}
									</FormControl>
								</Grid>

								<Grid item xs={12} md={12}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={3}>
											<TextField
												variant="outlined"
												margin="normal"
												fullWidth
												id="cnpj"
												label="CNPJ"
												name="cnpj"
												onChange={onChange}
												error={hasInputError(error, errors, "cnpj")}
												helperText={getInputError(error, errors, "cnpj")}
												value={values.cnpj}
												autoComplete="new-password"
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											{values.cnpj !== "" && (
												<FormControl
													variant="outlined"
													fullWidth
													className={classes.spacing}
												>
													<InputLabel id="employees-id">
														Número de colaboradores
													</InputLabel>
													<Select
														labelId="education-id"
														value={values.employees}
														error={hasInputError(error, errors, "employees")}
														onChange={(
															e: React.ChangeEvent<{ value: unknown }>
														) => {
															const value = e.target.value as string;
															setValue("employees", value);
														}}
														name="employees"
														label="Número de colaboradores"
													>
														<MenuItem value="0">Somente eu</MenuItem>
														<MenuItem value="1-3">1 a 3</MenuItem>
														<MenuItem value="4-7">4 a 7</MenuItem>
														<MenuItem value="7-10">7 a 10</MenuItem>
														<MenuItem value=">10">Mais que 10</MenuItem>
													</Select>
												</FormControl>
											)}
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} md={6} lg={6}>
									<TextField
										multiline
										rows={6}
										variant="outlined"
										margin="normal"
										fullWidth
										id="bio"
										label="Descrição"
										name="bio"
										onChange={onChange}
										error={hasInputError(error, errors, "bio")}
										helperText={getInputError(error, errors, "bio")}
										value={values.bio}
										autoComplete="new-password"
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={6}>
									<TextField
										multiline
										rows={6}
										variant="outlined"
										margin="normal"
										fullWidth
										id="description"
										label="Atendimento indicado para:"
										name="description"
										onChange={onChange}
										error={hasInputError(error, errors, "description")}
										helperText={getInputError(error, errors, "description")}
										value={values.description}
										autoComplete="new-password"
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} md={12} lg={12} xl={8}>
						<h3 className={classes.title}>Endereço</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4} lg={3}>
									<div className={classes.spacing}>
										<Address
											onChange={setAddress}
											defaultValue={values.fullAddress}
											error={(errors?.fullAddress?.message || "") as string}
										/>
									</div>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="cep"
										label="Cep"
										name="cep"
										onChange={onChange}
										error={hasInputError(error, errors, "cep")}
										helperText={getInputError(error, errors, "cep")}
										value={values.cep}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="neighborhood"
										label="Bairro"
										name="neighborhood"
										onChange={onChange}
										error={hasInputError(error, errors, "neighborhood")}
										helperText={getInputError(error, errors, "neighborhood")}
										value={values.neighborhood}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="street"
										label="Rua"
										name="street"
										onChange={onChange}
										error={hasInputError(error, errors, "street")}
										helperText={getInputError(error, errors, "street")}
										value={values.street}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="number"
										label="Número"
										name="number"
										onChange={onChange}
										error={hasInputError(error, errors, "number")}
										helperText={getInputError(error, errors, "number")}
										value={values.number}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="complement"
										label="Complemento"
										name="complement"
										onChange={onChange}
										error={hasInputError(error, errors, "complement")}
										helperText={getInputError(error, errors, "complement")}
										value={values.complement}
										autoComplete="new-password"
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} md={12} lg={12} xl={8}>
						<h3 className={classes.title}>Redes sociais</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4} lg={4}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="facebook"
										label="Facebook"
										name="facebook"
										onChange={onChange}
										error={hasInputError(error, errors, "facebook")}
										helperText={getInputError(error, errors, "facebook")}
										value={values.facebook}
										autoComplete="new-password"
									/>
									{getValues("facebook") && (
										<a
											className={classes.social}
											href={getURL(getValues("facebook"), "FACEBOOK")}
											target="_blank"
										>
											{getURL(getValues("facebook"), "FACEBOOK")}
										</a>
									)}
								</Grid>

								<Grid item xs={12} md={4} lg={4}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="instagram"
										label="Instagram"
										name="instagram"
										onChange={onChange}
										error={hasInputError(error, errors, "instagram")}
										helperText={getInputError(error, errors, "instagram")}
										value={values.instagram}
										autoComplete="new-password"
									/>
									{getValues("instagram") && (
										<a
											className={classes.social}
											href={getURL(getValues("instagram"), "INSTAGRAM")}
											target="_blank"
										>
											{getURL(getValues("instagram"), "INSTAGRAM")}
										</a>
									)}
								</Grid>

								<Grid item xs={12} md={4} lg={4}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="linkedin"
										label="Linkedin"
										name="linkedin"
										onChange={onChange}
										error={hasInputError(error, errors, "linkedin")}
										helperText={getInputError(error, errors, "linkedin")}
										value={values.linkedin}
										autoComplete="new-password"
									/>
									{getValues("linkedin") && (
										<a
											className={classes.social}
											href={getURL(getValues("linkedin"), "LINKEDIN")}
											target="_blank"
										>
											{getURL(getValues("linkedin"), "LINKEDIN")}
										</a>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Currículo</h3>
						<Paper
							elevation={2}
							className={`${classes.paper} documents-container`}
						>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Media.Documents
										saving={values.savingDocuments}
										files={documents}
										createDocuments={createDocuments}
										removeDocument={removeDocument}
										donwloadFile={donwloadDocument}
										tag={"CURRICULUM"}
									/>
								</Grid>
								<Grid item xs={12}>
									<ShowErrors.Field
										error={getValues("savingDocumentsError")}
										field="server"
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Certificados e diplomas</h3>
						<Paper
							elevation={2}
							className={`${classes.paper} documents-container certificates`}
						>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Media.Documents
										saving={values.savingDocuments}
										files={documents}
										createDocuments={createDocuments}
										removeDocument={removeDocument}
										donwloadFile={donwloadDocument}
										tag={"EDUCATION"}
										requiredField={true}
									/>
								</Grid>
								<Grid item xs={12}>
									<ShowErrors.Field
										error={getValues("savingDocumentsError")}
										field="server"
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Especialidades</h3>
						<Paper
							elevation={2}
							className={classes.paper + " especialidades-container"}
						>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Specialty.SelectMany
										specialties={values.specialties}
										onChange={(newState) => {
											setValue("specialties", newState);
										}}
									/>
									{errors.specialties && (
										<p className="custom-error-text" id="education-helper-text">
											Campo obrigatório
										</p>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Atendo cliente com o quadro</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Autocomplete
										multiple
										noOptionsText="Nenhuma opção"
										onChange={(event, newValue) => {
											setValue("assistanceStages", newValue);
										}}
										value={getValues().assistanceStages}
										options={stages.filter(
											(stage: any) =>
												!getValues().assistanceStages.some(
													(v: any) => v.id == stage.id
												)
										)}
										getOptionLabel={(option) => option.name}
										filterSelectedOptions
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Opções"
												placeholder="Opções"
												error={hasInputError(error, errors, "assistanceStages")}
												helperText={getInputError(
													error,
													errors,
													"assistanceStages"
												)}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Horários de atendimento</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Autocomplete
										multiple
										noOptionsText="Nenhuma opção"
										onChange={(event, newValue) => {
											setValue("assistanceSchedules", newValue);
										}}
										value={getValues().assistanceSchedules}
										options={schedules.filter(
											(schedule: any) =>
												!getValues().assistanceSchedules.some(
													(v: any) => v.id == schedule.id
												)
										)}
										getOptionLabel={(option) => option.name}
										filterSelectedOptions
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Horários de atendimento"
												placeholder="Horários de atendimento"
												error={hasInputError(
													error,
													errors,
													"assistanceSchedules"
												)}
												helperText={getInputError(
													error,
													errors,
													"assistanceSchedules"
												)}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Modalidade de atendimento</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Autocomplete
										multiple
										noOptionsText="Nenhuma opção"
										onChange={(event, newValue) => {
											setValue("assistanceTypes", newValue);
										}}
										value={getValues().assistanceTypes}
										options={types.filter(
											(type: any) =>
												!getValues().assistanceTypes.some(
													(v: any) => v.id == type.id
												)
										)}
										getOptionLabel={(option) => option.name}
										filterSelectedOptions
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Modalidade de atendimento"
												placeholder="Modalidade de atendimento"
												error={hasInputError(error, errors, "assistanceTypes")}
												helperText={getInputError(
													error,
													errors,
													"assistanceTypes"
												)}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Atende</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Autocomplete
										multiple
										noOptionsText="Nenhuma opção"
										onChange={(event, newValue) => {
											setValue("assistanceGenders", newValue);
										}}
										value={getValues().assistanceGenders}
										options={genders.filter(
											(gender: any) =>
												!getValues().assistanceGenders.some(
													(v: any) => v.id == gender.id
												)
										)}
										getOptionLabel={(option) => option.name}
										filterSelectedOptions
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Atende"
												placeholder="Atende"
												error={hasInputError(
													error,
													errors,
													"assistanceGenders"
												)}
												helperText={getInputError(
													error,
													errors,
													"assistanceGenders"
												)}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Faixa etária de atendimento</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<FormControl variant="outlined" fullWidth>
										<InputLabel id="assistancePacientAgeGroup-id">
											Faixa etária
										</InputLabel>
										<Select
											labelId="assistancePacientAgeGroup-id"
											value={values.assistancePacientAgeGroup}
											error={hasInputError(
												error,
												errors,
												"assistancePacientAgeGroup"
											)}
											onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
												const value = e.target.value as string;
												setValue("assistancePacientAgeGroup", value);
											}}
											name="assistancePacientAgeGroup"
											label="Faixa etária"
										>
											<MenuItem value="0-12">0-12</MenuItem>
											<MenuItem value="13-24">13-24</MenuItem>
											<MenuItem value="25-65">25-65</MenuItem>
											<MenuItem value="65<">{"65<"}</MenuItem>
											<MenuItem value="ALL">Todos</MenuItem>
										</Select>
										{errors.assistancePacientAgeGroup && (
											<p
												className="custom-error-text"
												id="education-helper-text"
											>
												Campo obrigatório
											</p>
										)}
									</FormControl>
									{/* <Autocomplete
                    multiple
                    noOptionsText="Nenhuma opção"
                    onChange={(event, newValue) => {
                      setValue("assistancePacientAgeGroup", newValue);
                    }}
                    value={getValues().assistancePacientAgeGroup}
                    options={ageGroup.filter(
                      (ageGroup: any) =>
                        !getValues().assistancePacientAgeGroup.some(
                          (v: any) => v.id == ageGroup.id
                        )
                    )}
                    getOptionLabel={(option) => `${option.start}-${option.end}`}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Faixa etária de atendimento"
                        placeholder="Faixa etária de atendimento"
                      />
                    )}
                  /> */}
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Atendo corpo</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Autocomplete
										multiple
										noOptionsText="Nenhuma opção"
										onChange={(event, newValue) => {
											setValue("bodies", newValue);
										}}
										value={getValues().bodies}
										options={bodies.filter(
											(body: any) =>
												!getValues().bodies.some(
													(v: any) => v.body == body.body
												)
										)}
										getOptionLabel={(option) => option.displayName}
										filterSelectedOptions
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Atendo corpo"
												placeholder="Atendo corpo"
												error={hasInputError(error, errors, "bodies")}
												helperText={getInputError(error, errors, "bodies")}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Mapeamento assistido</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<FormControl variant="outlined" fullWidth>
										<FormControlLabel
											control={
												<Checkbox
													checked={values.quizAssistance}
													onChange={(
														e: React.ChangeEvent<{ checked: unknown }>
													) => {
														const value = e.target.checked as boolean;
														setValue("quizAssistance", value);
													}}
												/>
											}
											label="Suporte a mapeamento assistido"
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<h3 className={classes.title}>Ocultar indicação?</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<FormControl variant="outlined" fullWidth>
										<FormControlLabel
											control={
												<Checkbox
													checked={values.hide}
													onChange={(
														e: React.ChangeEvent<{ checked: unknown }>
													) => {
														const value = e.target.checked as boolean;
														setValue("hide", value);
													}}
												/>
											}
											label="Ocultar indicação do profissional"
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} md={12} lg={12} xl={8}>
						<h3 className={classes.title}>Segurança</h3>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="email"
										label="E-mail"
										name="email"
										onChange={onChange}
										error={hasInputError(error, errors, "email")}
										helperText={getInputError(error, errors, "email")}
										value={values.email}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										label="Senha"
										type="password"
										id="password"
										name="password"
										onChange={onChange}
										error={hasInputError(error, errors, "password")}
										helperText={getInputError(error, errors, "password")}
										value={values.password}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										label="Repetir senha"
										type="password"
										id="confirmPassword"
										name="confirmPassword"
										onChange={onChange}
										error={errors.confirmPassword ? true : false}
										helperText={getInputError(error, errors, "confirmPassword")}
										value={values.confirmPassword}
										autoComplete="new-password"
									/>
								</Grid>
								{values.isUpdate && (
									<Grid item xs={12} md={4} lg={3} style={{ marginTop: 15 }}>
										<FormControl variant="outlined" fullWidth>
											<InputLabel id="authStatus-id">Status</InputLabel>
											<Select
												labelId="authStatus-id"
												value={values.authStatus}
												error={hasInputError(error, errors, "authStatus")}
												onChange={(
													e: React.ChangeEvent<{ value: unknown }>
												) => {
													const value = e.target.value as string;
													if (value !== "UNVERIFIED") {
														setValue("authStatus", value);
													}
												}}
												name="authStatus"
												label="Status"
												disabled={values.authStatus === "UNVERIFIED"}
											>
												<MenuItem value="UNVERIFIED">
													E-mail não verificado
												</MenuItem>
												<MenuItem value="ENABLED">Habilitado</MenuItem>
												<MenuItem value="DISABLED">Desabilitado</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								)}
							</Grid>
						</Paper>
					</Grid>

					{doctor !== null && (
						<Grid item xs={12} md={12} lg={12} xl={8}>
							<h3 className={classes.title}>Assinatura</h3>
							<Paper elevation={2} className={classes.paper}>
								<Grid container spacing={3}>
									{doctor?.plan !== null &&
										doctor?.planStatus !== "VIP" &&
										doctor?.planStatus !== "DISABLED" && (
											<Grid item xs={12}>
												<strong style={{ fontSize: "18px" }}>
													{` `} {doctor?.plan?.name} {doctor?.plan?.period}
												</strong>
												<br />
												<a
													target="_blank"
													href={`https://alia.iugu.com/receive/billing/${doctor?.financeSubscriptionId}`}
												>
													Ver detalhes
												</a>
											</Grid>
										)}

									{(doctor?.planStatus === null ||
										doctor?.planStatus === "VIP" ||
										doctor?.planStatus === "DISABLED") && (
										<Grid item xs={12}>
											<FormControl variant="outlined" fullWidth>
												<FormControlLabel
													control={
														<>
															<Checkbox
																checked={values.isVip}
																onChange={(
																	e: React.ChangeEvent<{ checked: unknown }>
																) => {
																	const value = e.target.checked as boolean;
																	setValue("isVip", value);
																}}
															/>
														</>
													}
													label={
														<div
															style={{
																display: "flex",
																justifyContent: "center",
																flexDirection: "column",
															}}
														>
															<strong style={{ marginBottom: "2px" }}>
																Usuário VIP
															</strong>
															<p
																style={{
																	marginTop: 0,
																	marginBottom: 0,
																	lineHeight: "18px",
																	fontSize: "12px",
																}}
															>
																Conceda acesso a todos os recursos do plano SOL
																gratuitamente.
															</p>
														</div>
													}
												/>
											</FormControl>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
					)}

					{values.refusedMessage && values.status === "DISABLED" && (
						<Grid item xs={12}>
							<h3 className={classes.title}>Perfil recusado</h3>
							<Paper
								elevation={1}
								style={{ padding: 15, backgroundColor: "#ff00291f" }}
							>
								<strong>Mensagem sobre perfil recusado:</strong>
								<p>{values.refusedMessage}</p>
								<Button
									type="button"
									variant="contained"
									color="primary"
									onClick={() => setShowRefusedMessageDialog(true)}
								>
									ALTERAR
								</Button>
							</Paper>
						</Grid>
					)}

					<Grid item xs={12}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<ShowErrors.Field error={error} field="server" />
							</Grid>

							<Grid item xs={12}>
								<Grid container xs={12}>
									<Grid
										item
										xs={12}
										md={4}
										lg={3}
										className={classes.noSpacing}
									>
										{values.isUpdate && (
											<FormControl
												variant="outlined"
												fullWidth
												className={classes.spacing}
											>
												<InputLabel id="status-id">Status</InputLabel>
												<Select
													labelId="status-id"
													value={values.status}
													error={hasInputError(error, errors, "status")}
													onChange={(
														e: React.ChangeEvent<{ value: unknown }>
													) => {
														const value = e.target.value as string;
														setValue("status", value);
													}}
													name="status"
													label="Status"
												>
													<MenuItem value="ENABLED">Aprovado</MenuItem>
													<MenuItem value="DISABLED">Recusado</MenuItem>
													<MenuItem value="PENDING">Pendente</MenuItem>
												</Select>
											</FormControl>
										)}
									</Grid>

									<Grid
										item
										xs={12}
										md={8}
										lg={9}
										className={classes.noSpacing}
									>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											className={`${classes.submit} animate__animated ${classError}`}
											disabled={loading || values.savingDocuments}
											startIcon={
												loading || values.savingDocuments ? null : <Check />
											}
										>
											{loading || values.savingDocuments ? (
												<>
													<CircularProgress
														size={20}
														className={classes.loading}
													/>
													<span>
														{values.savingDocuments
															? "SALVANDO ARQUIVOS"
															: "SALVANDO"}
													</span>
												</>
											) : (
												<>SALVAR</>
											)}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>

			<RefusedMessage
				open={showRefusedMessageDialog}
				message={values.refusedMessage}
				onSuccess={(value: string) => {
					setValue("refusedMessage", value);
					setShowRefusedMessageDialog(false);
				}}
				onClose={() => {
					setShowRefusedMessageDialog(false);

					setValue("status", prevStatus);
					// setValue("status", "PENDING");
				}}
			/>
		</div>
	);
};

Component.displayName = "Components_Doctor_Form";

export default Component;
