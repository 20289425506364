import * as React from "reactn";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Tabs,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { ITermCategory } from "src/services/terms";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 2, 8, 2),
    position: "relative",
  },
  actionsContainer: {
    padding: theme.spacing(1, 2),
    position: "fixed",
    height: "100px",
    width: "calc(100% - 200px)",
    boxSizing: "border-box",
    bottom: 0,
    right: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textareaContainer: {
    padding: theme.spacing(2, 0),
  },
  textarea: {
    border: "1px solid #c8c8c8",
    borderRadius: "6px",
    padding: theme.spacing(2),
    minHeight: 400,
    width: "100%",
    fontSize: 14,
    background: "#f9f9f9",
  },
  textareaError: {
    borderColor: theme.palette.error.main,
  },
  textareaErrorMessage: {
    color: theme.palette.error.main,
    margin: theme.spacing(1, 0, 2, 0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5, 5),
  },
  center: {
    textAlign: "center",
  },
  error: {
    margin: theme.spacing(2, 0),
  },
}));

const schema = yup.object().shape({
  category: yup.string().required("Campo obrigatório"),
  contentPt: yup
    .string()
    .test("contentPt", "Campo obrigatório", function (
      value: any,
      context: any
    ) {
      if (context.parent.isDraft) {
        return true;
      }

      if (value == "") {
        return false;
      }

      return true;
    }),
});

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
  id?: number;
};

const Component: React.FC<Props> = (props) => {
  const [showConfirmModal, setShowConfirmModal] = React.useState<boolean>(
    false
  );
  const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false);
  const [termCategories, setTermCategories] = React.useState<
    Array<ITermCategory>
  >([]);
  const [langSelected, setLangSelected] = React.useState<string>("PT");
  const [classError, setClassError] = React.useState<string>("");
  const classes = useStyles();
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isUpdate: false,
      category: "",
      contentPt: "",
      contentEng: "",
      isDraft: false,
    },
  });

  watch();

  const setValues = async (id: number) => {
    watch();
    setValue("isUpdate", id ? true : false);
    const term = await services.terms.findById(id);
    setValue("category", term.category);
    setValue("contentPt", term.contentPt);
    setValue("contentEng", term.contentEng);
    setValue("isDraft", term.isDraft);
  };

  React.useEffect(() => {
    if (props.id) {
      setValues(props.id);
    }

    services.terms.findAllCategories().then((cats: Array<ITermCategory>) => {
      setTermCategories(cats);
    });
  }, []);

  const onRemove = async () => {
    if (props?.id) {
      try {
        const term = await services.terms.remove(props?.id);
        if (props.onSuccess) props.onSuccess();
      } catch (e) {
        setClassError("animate__shakeX");
        setError(e as any);
        if (props.onError) props.onError();
      } finally {
        setLoading(false);
      }
    }
  };

  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    setClassError("");

    const values = getValues();

    let body: any = {
      category: values.category,
      contentPt: values.contentPt,
      contentEng: values.contentEng,
      isDraft: values.isDraft,
    };

    if (props.id) {
      body.id = props.id;
    }

    if (body.isDraft === false && body.contentEng === "") {
      body.contentEng = body.contentPt;
    }

    try {
      const term = await services.terms.save({ ...body });
      if (props.onSuccess) props.onSuccess();
    } catch (e) {
      setClassError("animate__shakeX");
      setError(e as any);
      if (props.onError) props.onError();
    } finally {
      setLoading(false);
    }
  };

  const onError = (e: any) => {
    if (e.contentPt) {
      setLangSelected("PT");
    }

    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);

    if (props.onError) {
      props.onError();
    }
  };

  const onSaveDraft = () => {
    setValue("isDraft", true);
    handleSubmit(onSubmit, onError)();
  };

  const onRequestPublish = () => {
    handleSubmit(onRequestPublishModal, onError)();
  };

  const onRequestPublishModal = () => {
    setShowConfirmModal(true);
  };

  const onPublish = () => {
    setValue("isDraft", false);
    handleSubmit(onSubmit, onError)();
  };

  return (
    <div className={classes.container}>
      <Paper elevation={4} className={classes.paper}>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className={classes.form}
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={5} lg={4}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 3 }}
                  >
                    <InputLabel id="category-id">Categoria</InputLabel>
                    <Select
                      labelId="category-id"
                      value={getValues().category}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        setValue("category", e.target.value as string);
                      }}
                      name="category"
                      label="Category"
                      disabled={getValues().category === "UNVERIFIED"}
                    >
                      <MenuItem value="">Selecione a categoria</MenuItem>
                      {termCategories.map((category: ITermCategory) => (
                        <MenuItem value={category.id}>
                          {category.titlePt}
                        </MenuItem>
                      ))}
                    </Select>
                    {hasInputError(error, errors, "category") && (
                      <p className={classes.textareaErrorMessage}>
                        {getInputError(error, errors, "category")}
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TabContext value={langSelected}>
                <TabList
                  value={langSelected}
                  onChange={(event: any, newValue: any) =>
                    setLangSelected(newValue)
                  }
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="Conteúdo"
                >
                  <Tab value="PT" label="Português" />
                  <Tab value="ENG" label="Inglês" />
                </TabList>
                <TabPanel value="PT" className={classes.textareaContainer}>
                  <TextareaAutosize
                    aria-label="Conteúdo em Português"
                    placeholder="Conteúdo em Português"
                    className={`${classes.textarea} ${
                      hasInputError(error, errors, "contentPt")
                        ? classes.textareaError
                        : ""
                    }`}
                    onChange={(e: any) => setValue("contentPt", e.target.value)}
                    value={getValues().contentPt}
                  />
                  {hasInputError(error, errors, "contentPt") && (
                    <p className={classes.textareaErrorMessage}>
                      {getInputError(error, errors, "contentPt")}
                    </p>
                  )}
                </TabPanel>
                <TabPanel value="ENG" className={classes.textareaContainer}>
                  <TextareaAutosize
                    aria-label="Conteúdo em Inglês"
                    placeholder="Conteúdo em Inglês"
                    className={`${classes.textarea} ${
                      hasInputError(error, errors, "contentEng")
                        ? classes.textareaError
                        : ""
                    }`}
                    onChange={(e: any) =>
                      setValue("contentEng", e.target.value)
                    }
                    value={getValues().contentEng}
                  />
                  {hasInputError(error, errors, "contentEng") && (
                    <p className={classes.textareaErrorMessage}>
                      {getInputError(error, errors, "contentEng")}
                    </p>
                  )}
                </TabPanel>
              </TabContext>
            </Grid>

            <Paper elevation={4} className={classes.actionsContainer}>
              <ShowErrors.Field error={error} field="server" />

              {getValues().isDraft && getValues().isUpdate && (
                <Button
                  type="button"
                  variant="text"
                  color="primary"
                  size="small"
                  className={`${classes.submit}`}
                  style={{ marginRight: 30 }}
                  onClick={() => setShowRemoveModal(true)}
                >
                  REMOVER RASCUNHO
                </Button>
              )}

              <Button
                type="button"
                variant="text"
                color="primary"
                size="small"
                className={`${classes.submit} animate__animated ${classError}`}
                disabled={loading}
                style={{ marginRight: 30 }}
                onClick={onSaveDraft}
              >
                {loading ? "SALVANDO..." : "SALVAR COMO RASCUNHO"}
              </Button>

              <Button
                type="button"
                variant="contained"
                color="primary"
                size="small"
                className={`${classes.submit} animate__animated ${classError}`}
                disabled={loading}
                onClick={onRequestPublish}
              >
                {loading ? "PUBLICANDO..." : "PUBLICAR"}
              </Button>
            </Paper>
          </Grid>
        </form>
      </Paper>

      <Dialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deseja publicar este documento?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao confirmar a publicação, os usuários do sistema serão notificados
            via e-mail e orientados a aceitar os novos termos. Esta ação não
            poderá ser revertida.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="text"
            color="primary"
            size="small"
            className={`${classes.submit} animate__animated ${classError}`}
            onClick={() => setShowConfirmModal(false)}
          >
            Cancelar
          </Button>

          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            className={`${classes.submit} animate__animated ${classError}`}
            disabled={loading}
            onClick={onPublish}
          >
            {loading ? "PUBLICANDO..." : "CONFIRMAR"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja remover este rascunho?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="text"
            color="primary"
            size="small"
            className={`${classes.submit} animate__animated ${classError}`}
            onClick={() => setShowRemoveModal(false)}
          >
            Cancelar
          </Button>

          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            className={`${classes.submit} animate__animated ${classError}`}
            disabled={loading}
            onClick={onRemove}
          >
            {loading ? "REMOVENDO..." : "SIM"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Component.displayName = "Components_Admn_Forms";

export default Component;
