import React, { setGlobal } from "reactn";
import { render } from "react-dom";
import addReactNDevTools from "reactn-devtools";
import App from "./App";
import state from "./state";
import "./helpers/localStorage";
import bootstrapService from "_services/middleware";
// import reportWebVitals from "./reportWebVitals";
import "../node_modules/animate.css/animate.min.css";
import "./index.css";

if (process.env.NODE_ENV === "development") {
  addReactNDevTools();
}

setGlobal({
  ...state,
});

bootstrapService();
render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
