import React from "react";
import { makeStyles } from "@material-ui/core";
import { INewStateOnCroppedFile } from "./Crop/cropImage";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Crop from "./Crop";
import Image from "./Image";
import SelectFile, { INewStateOnSelectedFile } from "./SelectFile";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  thumb: {

  },
  cancelBtn: {
    position: "absolute",
    right: 0,
    top: 5,

    marginLeft: 6,
    background: "red",
    // background: theme.palette.error[200],
    padding: "4px 6px",
    width: "auto",
    minWidth: "auto",
    "& span": {
      marginRight: 0 + "!important",
      marginLeft: 0 + "!important",
    },
  },
}));

const states = {
  SHOW_IMAGE: "SHOW_IMAGE",
  SELECT_FILE: "SELECT_FILE",
  CROP_IMAGE: "CROP_IMAGE",
};

type Props = {
  onCancel: () => void;
  onNewFile: (formData: FormData) => void;
  currentFile?: string;
  width: number,
  height: number,
  // onError?: () => void;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState<string>(states.SELECT_FILE);
  const [file, setFile] = React.useState<File | null>(null);
  const [blob, setBlob] = React.useState<Blob | null>(null);
  const [image, setImage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (props.currentFile) {
      setImage(props.currentFile);
      setState(states.SHOW_IMAGE);
    }
  }, [props.currentFile]);

  // "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"

  const onFileSelected = (newState: INewStateOnSelectedFile) => {
    setState(states.CROP_IMAGE);
    setImage(newState.preview);
    setFile(newState.file);
  };

  const onCropped = (newState: INewStateOnCroppedFile) => {
    setState(states.SHOW_IMAGE);
    setImage(newState.preview);
    setBlob(newState.blob);

    const form = new FormData();
    form.append("file", newState.blob || "", "avatar.png");
    props.onNewFile(form);
  };

  const onCancel = () => {
    setState(states.SELECT_FILE);
    setImage(null);
    setBlob(null);
    setFile(null);
    props.onCancel();
  };

  return (
    <div className={classes.container}>
      {state == states.CROP_IMAGE && (
        <Crop
          width={props.width}
          height={props.height}
          image={image}
          onCropped={onCropped}
          onCancel={onCancel}
        />
      )}
      {state == states.SELECT_FILE && <SelectFile onChange={onFileSelected} />}
      {state == states.SHOW_IMAGE && image && (
        <img
          src={image || ''}
          className={classes.thumb}
          style={{
            width: props.width,
            height: props.height,
          }}
        />
      )}

      {state == states.SHOW_IMAGE && (
        <Button
          size="small"
          variant="contained"
          startIcon={<CloseIcon />}
          className={classes.cancelBtn}
          onClick={onCancel}
        ></Button>
      )}
    </div>
  );
};

Component.displayName = "Component_Media_Avatar";

export default Component;
