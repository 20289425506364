import React from "reactn";
import { useHistory, useParams, RouteProps } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Content from "_components/Content";
import { PrivatePage } from "_components/Layout";
import { success as successReducer, error as errorReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const success = React.useDispatch<any>(successReducer);
  const error = React.useDispatch<any>(errorReducer);

  const onError = () => {
    error(
      "Ocorreu um erro ao salvar os dados. Verifique os campos e tente novamente."
    );
  };

  const onSuccess = () => {
    success("Dados atualizados com sucesso");
    history.push("/conteudos");
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "CONTEÚDOS",
                to: "/conteudos",
              },
              {
                label: "EDITAR",
              },
            ]}
          />
        }
      ></AppBar>
      <Content.Form
        onSuccess={onSuccess}
        onError={onError}
        id={Number(params.id)}
      />
    </PrivatePage>
  );
};

Component.displayName = "screens_Content_Update";

export default Component;
