import * as React from "reactn";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, Paper } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import AppError from "src/errors/AppError";
import { Discount } from "src/services/plans";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  paper: {
    maxWidth: "340px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  center: {
    textAlign: "center",
  },
  error: {
    margin: theme.spacing(2, 0),
  },
}));

const schema = yup.object().shape({
  value: yup
    .number()
    .test("len", "excede o valor máximo permitido de 100%", (val: any) =>
      Number(val) > 100 ? false : true
    ),
  // name: yup.string().required("Campo obrigatório"),
});

type Props = {
  onSuccess?: (nextState: Discount) => void;
  onError?: () => void;
  discount: Discount;
};

type State = {
  id: number;
  name: string;
  description: string;
};

const Component: React.FC<Props> = (props) => {
  const [classError, setClassError] = React.useState<string>("");
  const classes = useStyles();
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...props.discount },
  });

  watch();

  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    setClassError("");

    const values = getValues();

    try {
      await services.plans.updateDiscount(values);
      if (props.onSuccess) props.onSuccess(values);
    } catch (e:any) {
      setClassError("animate__shakeX");
      setError(e as any);
      if (props.onError) props.onError();
    } finally {
      setLoading(false);
    }
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  const values = getValues();
  const onChange = (e: any): void => {
    const name = e.currentTarget.name as keyof State;
    const value = e.currentTarget.value;
    setValue(name, value);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      className={classes.form}
      noValidate
    >
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="% de desconto por cobrança"
        name="value"
        type="number"
        onChange={onChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        error={hasInputError(error, errors, "value")}
        helperText={getInputError(error, errors, "value")}
        value={values.value}
      />

      <TextField
        multiline
        rows={2}
        variant="outlined"
        name="description"
        margin="normal"
        fullWidth
        label="Descrição (utilizado na fatura)"
        onChange={onChange}
        error={hasInputError(error, errors, "description")}
        helperText={getInputError(error, errors, "description")}
        value={values.description}
      />

      <ShowErrors.Field error={error} field="server" />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={`${classes.submit} animate__animated ${classError}`}
        disabled={loading}
      >
        {loading ? "SALVANDO..." : "SALVAR"}
      </Button>
    </form>
  );
};

Component.displayName = "Components_Plan_Discount";

export default Component;
