import React from "react";
import { RouteProps } from "react-router-dom";
// import { Button } from "@material-ui/core";
// import { RouteProps, Link } from "react-router-dom";
// import AppBar from "_components/Layout/AppBar";
// import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
// import * as Admin from "_components/Admin";
import { PrivatePage } from "_components/Layout";
import DevMessage from "_UI/DevMessage";
// import AddIcon from "@material-ui/icons/Add";

// Right={
//   <>
//     <Button
//       size="small"
//       variant="contained"
//       color="primary"
//       startIcon={<AddIcon />}
//       component={Link}
//       to="/pacientes/cadastrar"
//     >
//       CADASTRAR
//     </Button>
//   </>
// }

const Component: React.FC<RouteProps> = () => {
  return (
    <PrivatePage>
      <DevMessage />
    </PrivatePage>
  );
};

Component.displayName = "screens_Planner_List";

export default Component;
