import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import { IQuery } from "./PaginateDatasource/types";

export type GroupQuestion = {
  id: number;
  name: string;
};

export type Specialty = {
  id: number;
  specialtyId?: number | null;
  name: string;
  description?: string | null;
  // specialty?: Specialty | null;
};

export type QuizOption = {
  id: number;
  questionId: number;
  description: string | null;
  strong: number;
  order: number;
  showDescriptionInApp: boolean;
};

export type QuizQuestion = {
  id: number;
  groupId: number | null;
  group: GroupQuestion | null;
  quizId: number;
  options: Array<QuizOption>;
  title: string;
  description: string | null;
  order: number;
};

export type QuizScore = {
  id: number;
  quizId: number;
  title: string;
  description: string;
  message: string;
  start: number;
  end: number;
  specialties?: Array<Specialty>;
};

export type Quiz = {
  id: number;
  name: string;
  slug: string;
  description: string | null;
  questions: Array<QuizQuestion>;
  scores: Array<QuizScore>;
};

export type QuizWithStage = {
  id: string;
  quizId: number;
  stage: string;
  quiz: string;
  displayName: string;
};

/**
 * findALl
 */
export async function findAll(query?: IQuery): Promise<Array<Quiz>> {
  const queryString = qs.stringify(query || {}, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/quizzes/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * findById
 */
export async function findById(id: number) {
  try {
    const response = await axios.get(`/quizzes/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * save
 */
export async function save(body: any) {
  try {
    let response = null;

    if (body.id) {
      response = await axios.put(`/quizzes/${body.id}`, body, {
        useToken: true,
      });
    } else {
      response = await axios.post(`/quizzes`, body, {
        useToken: true,
      });
    }

    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(id: number) {
  try {
    const response = await axios.delete(`/quizzes/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}
