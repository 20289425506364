export const bodies = [
  {
    displayName: "Físico",
    body: "FISICO",
  },
  {
    displayName: "Emocional",
    body: "EMOCIONAL",
  },
  {
    displayName: "Mental",
    body: "MENTAL",
  },
  {
    displayName: "Espiritual",
    body: "ESPIRITUAL",
  },
];
