import axios from "axios";
import configApp from "_config";
import { auth as authStorage } from "_helpers/localStorage";

export default () => {
  /**
   * baseURL
   */
  axios.defaults.baseURL = configApp.apiURI;

  /**
   * request
   */
  axios.interceptors.request.use(
    (config) => {
      if (config.useToken) {
        const access_token = authStorage.get("access_token");
        if (access_token) {
          config.headers["Authorization"] = `Bearer ${access_token}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  /**
   * response
   */
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );
};
