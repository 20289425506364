import { Dispatch, State } from "reactn/default";

const INITIAL_STATE = {
  type: "success",
  message: "",
  show: false,
};

export const initialState = { ...INITIAL_STATE };

export async function success(
  global: State,
  dispatch: Dispatch,
  message: string
) {
  return {
    alert: {
      type: "success",
      show: true,
      message,
    },
  };
}

export async function error(
  global: State,
  dispatch: Dispatch,
  message: string
) {
  return {
    alert: {
      type: "error",
      show: true,
      message,
    },
  };
}

export async function clearAlert(global: State, dispatch: Dispatch) {
  return {
    alert: {
      type: "success",
      show: false,
      message: "",
    },
  };
}
