import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as services from "_services";

type IOption = {
  description: string;
  placeId: string;
};

type Props = {
  onChange: (value: any) => void;
  defaultValue: string | null;
  error: string;
};

let searchTimeout: any = null;

const Component: React.FC<Props> = (props) => {
  const [error, setError] = React.useState<string>("");
  const [value, setValue] = React.useState<IOption | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<Array<IOption>>([]);

  React.useEffect(() => {
    setError(props.error);
  }, [props.error]);

  React.useEffect(() => {
    if (!value && props.defaultValue) {
      setInputValue(props.defaultValue || "");
      setValue({
        placeId: "none",
        description: props.defaultValue || "",
      });
    }
  }, [props.defaultValue]);

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    (async () => {
      setOptions([]);

      if (!value || value.description !== inputValue) {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
          services.address
            .find(inputValue)
            .then((results: any) => {
              if (active) {
                setOptions(results.address);
              }
            })
            .catch((e: any) => {
              setError("Não foi possível localizar o seu endereço");
            });
        }, 400);
      }
    })();

    return () => {
      active = false;
    };
  }, [inputValue, value, fetch]);

  const setAddress = async (placeId: string) => {
    try {
      const response = await services.address.get(placeId);
      if (response.address?.fullAddress) {
        setOptions([]);
        // @ts-ignore
        setValue({
          ...value,
          description: response.address?.fullAddress,
        });
      }
      props.onChange(response?.address || null);
    } catch (e) {
      // setError("Não foi possível localizar o seu endereço");
    }
  };

  const clearAddress = async () => {
    props.onChange(null);
  };

  return (
    <Autocomplete
      // autoComplete
      // includeInputInList
      // filterSelectedOptions
      getOptionSelected={(option, value) =>
        option.description === value.description
      }
      value={value}
      onChange={(event: any, newValue: IOption | null) => {
        if (newValue) {
          setAddress(newValue.placeId);
        } else {
          clearAddress();
        }
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => option.description}
      options={options}
      loadingText="procurando..."
      noOptionsText="Não encontramos o seu endereço"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Cidade"
          variant="outlined"
          id="field1"
          error={error !== ""}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default Component;
