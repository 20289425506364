import { Dispatch, State } from "reactn/default";
import * as service from "_services";
import {
  ISpecialtiesDataState,
  ISpecialtiesState,
  ISpecialtyState,
} from "./types";

const INITIAL_STATE: ISpecialtiesState = {
  data: {},
  update: null,
};

export const initialState = { ...INITIAL_STATE };

export async function setSpecialty(
  global: State,
  dispatch: Dispatch,
  body: Partial<ISpecialtyState>
) {
  return {
    specialty: {
      ...global.specialty,
      ...body,
    },
  };
}

export async function findAll(global: State, dispatch: Dispatch) {
  const specialties = await service.specialties.findAll();
  const newState = specialties.items.reduce(
    (acc: ISpecialtiesDataState, cur: ISpecialtyState) => {
      acc[cur.id] = cur;
      return acc;
    },
    {}
  );
  return {
    specialty: {
      ...global.specialty,
      data: { ...newState },
    },
  };
}

export async function save(
  global: State,
  dispatch: Dispatch,
  body: Partial<ISpecialtyState>
) {
  const specialty: ISpecialtyState = await service.specialties.save(body);

  let newState: ISpecialtiesDataState = { ...global.specialty.data };

  if (body.id) {
    newState[body.id] = specialty;

    // update children
    for (let specialtyItem of Object.values(newState)) {
      if (specialtyItem.specialty?.id == specialty.id) {
        newState[specialtyItem.id] = {
          ...specialtyItem,
          specialty,
        };
      }
    }
  } else {
    newState = { ...{ [specialty.id]: specialty }, ...newState };
  }

  return {
    specialty: {
      ...global.specialty,
      data: { ...newState },
    },
  };
}

export async function remove(
  global: State,
  dispatch: Dispatch,
  specialtyId: number
) {
  await service.specialties.remove(specialtyId);
  let newState: ISpecialtiesDataState = { ...global.specialty.data };
  delete newState[specialtyId];

  // update children
  for (let specialtyItem of Object.values(newState)) {
    if (specialtyItem.specialty?.id == specialtyId) {
      newState[specialtyItem.id].specialty = null;
    }
  }

  let update = global.specialty.update;
  if (update && update == specialtyId) {
    update = null;
  }

  return {
    specialty: {
      ...global.specialty,
      data: { ...newState },
      update,
    },
  };
}

export async function update(
  global: State,
  dispatch: Dispatch,
  specialtyId: number
) {
  return {
    specialty: { ...global.specialty, update: specialtyId },
  };
}
