import React from "react";
import { RouteProps } from "react-router-dom";
import { PublicPage } from "_components/Layout";

const Component: React.FC<RouteProps> = () => {
  return (
    <PublicPage>
      <p>
        ESSENCIA DO SER LTDA, a legal entity governed by private law, registered
        with the CNPJ under No. 27.106.666\0001-85, headquartered at Avenida
        Oswaldo Rodrigues Cabral, 1570 sala 207, CEP 88015710, Florianópolis,
        Santa Catarina, committed to protecting privacy and protect the personal
        data of its customers and provide contact with the person in charge of
        privacy and data protection, who is the intermediary between the company
        and the data subjects. It understands as extremely relevant the
        electronic records and personal data left by you - Holder, in the use of
        the various sites and services, of the MUP application, serving the
        present as a Privacy Notice to regularize, and clarify in a transparent,
        objective and simple way, as the your personal data is collected, used
        and protected.
      </p>
      <p>This document is intended for MUP beneficiaries.</p>
      <p>This privacy notice provides, among other matters, about:</p>
      <p>
        What data is collected about you; What are cookies; How we use your
        personal data; With whom we share your personal data; How we keep your
        personal data safe; How long your personal data will be stored; Your
        rights as Data Subject and the way to exercise them.
      </p>
      <p>
        In case of additional questions or requests, please contact our Data
        Protection Officer via the email address:
        mupterapiasholisticas@gmail.com
      </p>
      <p>Who is a Data Processing Agent? MUP</p>
      <p>What is your role in data processing? Predominantly controlling.</p>
      <p>
        What is the nature of the data processed? Personal data provided by the
        Holder and/or collected automatically, of a cadastral, financial and
        sensitive nature (article 5, II of Law 13.709/2018).
      </p>
      <p>
        Main purposes of treatment? ESSENCIA DO SER LTDA, may use personal data
        to provide its products and services, carry out online assessments,
        issue alerts about appointments and exams, preventive care, prepare
        continuous and progressive prevention plans.
      </p>
      <p>
        With whom is my data shared? Operators and service providers essential
        to our activities; therapeutic professionals, partner companies;
        ANS-National Supplementary Health Agency, authorities/governmental
        bodies as a result of legal or regulatory obligations.
      </p>
      <p>
        What does the MUP do to ensure data protection? Appropriate security,
        technical and administrative measures.
      </p>
      <p>
        What are your rights? Confirmation of the existence of treatment,
        access, correction, etc.
      </p>
      <p>
        This Notice may be updated at any time by ESSENCIA DO SER LTDA, by means
        of a notice on the website and/or by email, if the Holder has opted to
        receive communications from ESSENCIA DO SER LTDA.
      </p>
      <p>WHAT DATA IS COLLECTED ABOUT YOU</p>

      <p>
        In order for us to provide our services, it is essential to collect some
        information about you.
      </p>
      <p>What are these data?</p>
      <p>
        Personal data provided by third parties: ESSENCIA DO SER LTDA, receives
        personal data from third parties, whether partners and service
        providers, related to you. The data received from such sources enable
        ESSENCIA DO SER LTDA to provide its services to you.
      </p>
      <p>
        Personal data provided directly by you: ESSENCIA DO SER Ltda will
        collect all personal data entered or actively forwarded by the user when
        registering on our portal and customer channel. The data collected
        varies according to the type of service you are registered with. Below
        we present a simple and objective list of the services provided by
        ESSENCIA DO SER LTDA:
      </p>
      <p>DATA COLLECTION BY SERVICES:</p>
      <p>
        Appointment Scheduling: Name; CPF; Telephone; address; email; Whatsapp;
        Professional Data; Clinical data;
      </p>
      <p>
        Hiring: Name; ID; CPF; Telephone; Address; personal health statement
        with health data such as pre-existing illnesses.
      </p>
      <p>
        Registration for face-to-face service: Name; address; age; telephone;
        sex; CPF; RG;; diagnosis; birth date; mother's name; evaluations carried
        out; clinical history; weight and height.
      </p>
      <p>Searches: Name; CPF; Medical record data.</p>
      <p>
        Data collected automatically: ESSENCIA DO SER Ltda also collects a
        series of information automatically, such as: characteristics of the
        access device, wi-fi, browser, IP (with date and time), IP origin,
        information about clicks, pages accessed, search terms typed in our
        portals, among others. Such collection is carried out through the use of
        some standard technologies, such as cookies.
      </p>
      <p>WHAT ARE COOKIES</p>
      <p>
        Cookies are files or information that may be stored on your devices when
        you visit the website or use the online services of ESSENCIA DO SER
        LTDA.
      </p>
      <p>TYPES OF COOKIES AND WHAT THEY DO:</p>
      <p>
        Necessary: ​​Cookies are essential for ESSENCIA DO SER LTDA websites to
        load properly and allow you to browse correctly.
      </p>
      <p>
        Performance: Cookies help us understand how visitors interact with
        ESSENCIA DO pages.
      </p>
    </PublicPage>
  );
};

Component.displayName = "screens_Terms_GTDP";

export default Component;
