import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppError from "src/errors/AppError";
import * as services from "_services";
import { Quiz, QuizScore } from "src/services/quizzes";
import Form from "./Form";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  score: {},
  content: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  title: {
    display: "flex",
    flex: 1,
    marginLeft: theme.spacing(2),
  },
  remove: {
    cursor: "pointer",
    padding: 0,
  },
  addItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: theme.palette.grey[100],
    borderRadius: 8,
  },
}));

type Props = {
  onSuccess?: () => void;
  onError?: (e: AppError | null) => void;
  quiz: Quiz | null;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const data = props?.quiz?.scores || [];
  const [showAddForm, setShowAddForm] = React.useState<boolean>(false);

  const onSuccess = () => {
    setShowAddForm(false);
    if (props.onSuccess) props.onSuccess();
  };

  const onRemove = async (questionId: number) => {
    await services.score.remove(props.quiz?.id || 0, questionId);
    if (props.onSuccess) props.onSuccess();
  };

  return (
    <div className={classes.container}>
      {data.map((score: QuizScore) => (
        <Accordion key={score.id.toString()}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.content}>
              <span className={classes.title}>
                {score.start} - {score.end}
              </span>
            </div>
            <IconButton
              aria-label="Remover"
              className={classes.remove}
              onClick={(e: any) => {
                e.preventDefault();
                onRemove(score.id);
              }}
            >
              <CloseIcon />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <Form
              quizId={props.quiz?.id || 0}
              score={score}
              onSuccess={onSuccess}
            />
          </AccordionDetails>
        </Accordion>
      ))}

      <div className={classes.addItem}>
        {showAddForm === false && (
          <Button
            color="primary"
            startIcon={<AddIcon color="primary" />}
            onClick={() => {
              setShowAddForm(showAddForm ? false : true);
            }}
          >
            Adicionar feedback de resultado
          </Button>
        )}
        {showAddForm && (
          <Form quizId={props.quiz?.id || 0} onSuccess={onSuccess} />
        )}
      </div>
    </div>
  );
};

Component.displayName = "Components_Quiz_Forms_Question";

export default Component;
