import * as React from "reactn";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import * as services from "_services";
import { Quiz } from "src/services/quizzes";

const useStyles = makeStyles((theme) => ({
  container: {},
  chips: {
    margin: theme.spacing(1, -0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

type Props = {
  quizzes: Array<Quiz>;
  onChange: (newState: Array<Quiz>) => void;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<Quiz | null>(null);
  const [search, setSearch] = React.useState<string>("");
  const [quizzes, setQuizzes] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    services.quizzes.findAll().then((items: any) => {
      setQuizzes(items);
    });
  }, []);

  const onAdd = (quiz: Quiz) => {
    if (props.quizzes.some((s: Quiz) => s.id == quiz.id)) {
      return;
    }
    props.onChange([...props.quizzes, quiz]);
  };

  const onDelete = (quiz: Quiz) => {
    props.onChange(props.quizzes.filter((s: Quiz) => s.id !== quiz.id));
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        value={selected}
        onChange={(event: any, newValue: Quiz | null) => {
          onAdd(newValue as Quiz);
          setSelected(null);
          setSearch("");
        }}
        options={quizzes as Quiz[]}
        getOptionLabel={(option: Quiz) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Mapeamentos" variant="outlined" />
        )}
        noOptionsText="Não encontrado"
      />
      <div className={classes.chips}>
        {props.quizzes.map((quiz: Quiz) => {
          return (
            <Chip
              key={quiz.id.toString()}
              label={quiz.name}
              onDelete={() => onDelete(quiz)}
              className={classes.chip}
              variant="outlined"
              color="primary"
            />
          );
        })}
      </div>
    </div>
  );
};

Component.displayName = "Components_Quiz_SelectMany";

export default Component;
