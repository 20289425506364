import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import { IQuery } from "./PaginateDatasource/types";

export type ITermCategory = {
  id: string;
  titlePt: string;
  titleEng: string;
};

export type ITerm = {
  id: number;
  category: string;
  version: number;
  titlePt: string;
  contentPt: string;
  titleEng: string;
  contentEng: string;
  isDraft: boolean;
  publishedAt: Date | null;
};

export type ITermQuery = {
  id: number;
  category: string;
  group: boolean;
  single: boolean;
  lang?: any;
  print?: any;
};

/**
 * findALl
 */
export async function findAll(query: IQuery) {
  const queryString = qs.stringify(query, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/terms/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * Get
 */
export async function get(query: ITermQuery) {
  const queryString = qs.stringify(query, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/terms/public/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * findById
 */
export async function findById(id: number) {
  try {
    const response = await axios.get(`/terms/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * findAllCategories
 */
export async function findAllCategories() {
  try {
    const response = await axios.get(`/terms/categories`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * save
 */
export async function save(body: any) {
  try {
    let response = null;
    if (body.id) {
      response = await axios.put(`/terms/${body.id}`, body, {
        useToken: true,
      });
    } else {
      response = await axios.post(`/terms/`, body, {
        useToken: true,
      });
    }
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(id: number) {
  try {
    const response = await axios.delete(`/terms/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}
