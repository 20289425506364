import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter } from "@material-ui/core";
import Actions from "./Actions";
import { IRow, ICol } from "./types";

interface Props {
	rows: Array<IRow>;
	cols: Array<ICol>;
	page: number;
	rowsPerPage: number;
	count: number;
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => void;
	onRowsPerPageChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
}

const Component: React.FC<Props> = (props) => {
	return (
		<TableFooter>
			<TableRow>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50]}
					colSpan={props.cols.length + 1}
					count={props.count}
					rowsPerPage={props.rowsPerPage}
					page={props.page - 1}
					labelRowsPerPage="Itens por página"
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} de ${count}`
					}
					SelectProps={{
						inputProps: { "aria-label": "Itens por página" },
						native: true,
					}}
					onPageChange={props.onPageChange}
					onRowsPerPageChange={props.onRowsPerPageChange}
				/>
			</TableRow>
		</TableFooter>
	);
};
// TODO: ADicioanr isso...
// ActionsComponent={Actions}

Component.displayName = "Components_Table_TablePagination_Footer";

export default Component;
