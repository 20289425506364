import React from "reactn";
import { RouteProps } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import * as Auth from "_components/Auth";
import { PublicPage } from "_components/Layout";
import { success as successReducer } from "_state/alert";
import { Container, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#4A0B61",
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "80vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    maxWidth: 280,
    textAlign: "center",
  },
}));

const Component: React.FC<RouteProps> = () => {
  const [success, setSuccess] = React.useState<boolean>(false);
  const params = useParams<{ token: string }>();
  const classes = useStyles();

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <PublicPage>
      {success ? (
        <div className={classes.wrapper}>
          <Container
            component="main"
            maxWidth="xs"
            className={classes.container}
          >
            <Paper elevation={4} className={classes.paper}>
              <h3>Senha atualizada com sucesso</h3>
              <p>
                Agora você pode acessar o aplicativo utilizando a sua nova
                senha.
              </p>
            </Paper>
          </Container>
        </div>
      ) : (
        <Auth.UpdatePasswordApp token={params.token} onSuccess={onSuccess} />
      )}
    </PublicPage>
  );
};

Component.displayName = "screens_Auth_UpdatePassword";

export default Component;
