import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppError from "src/errors/AppError";
import { QuizQuestion } from "src/services/quizzes";
import FormQuestion from "./form";
import * as services from "_services";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  question: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  questionContent: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  title: {
    display: "flex",
    flex: 1,
    marginLeft: theme.spacing(2),
  },
  addQuestionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    background: theme.palette.grey[100],
    borderRadius: 8,
  },
  drag: {
    cursor: "move",
    padding: 0,
  },
  remove: {
    cursor: "pointer",
    padding: 0,
  },
  groupContainer: {
    margin: theme.spacing(0, 2),
  },
}));

type Props = {
  onSuccess?: () => void;
  onError?: (e: AppError | null) => void;
  quizId: number;
  questions: Array<QuizQuestion>;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [showAddForm, setShowAddForm] = React.useState<boolean>(false);
  const [remove, setRemove] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onCreateQuestion = () => {
    setShowAddForm(false);
    if (props.onSuccess) props.onSuccess();
  };

  const onRemove = async () => {
    setLoading(true);
    await services.questions.remove(props.quizId, remove || 0);
    setLoading(false);
    setRemove(null);
    if (props.onSuccess) props.onSuccess();
  };

  const handleOnDragEnd = async (result: any) => {
    const question = props.questions[result.source.index];
    const order = result.destination.index;
    await services.questions.order(props.quizId, question.id, order);
    if (props.onSuccess) props.onSuccess();
  };

  return (
    <div className={classes.container}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div
              className="questions"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.questions.map((question: QuizQuestion, index: number) => (
                <Draggable
                  key={question.id}
                  draggableId={question.id.toString()}
                  index={index}
                >
                  {(provided: any) => (
                    <Accordion
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <AccordionSummary
                        className={classes.question}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <IconButton
                          aria-label="ordenar"
                          className={classes.drag}
                          {...provided.dragHandleProps}
                        >
                          <DragHandleIcon />
                        </IconButton>
                        <div className={classes.questionContent}>
                          <span className={classes.title}>
                            {question.title}
                          </span>
                        </div>

                        {question.group && (
                          <div className={classes.groupContainer}>
                            <Chip label={question.group.name} size="small" />
                          </div>
                        )}

                        <IconButton
                          aria-label="Remover"
                          className={classes.remove}
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setRemove(question.id);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormQuestion
                          onSuccess={onCreateQuestion}
                          quizId={props.quizId}
                          question={question}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className={classes.addQuestionContainer}>
        {showAddForm === false && (
          <Button
            color="primary"
            startIcon={<AddIcon color="primary" />}
            onClick={() => {
              setShowAddForm(showAddForm ? false : true);
            }}
          >
            Adicionar pergunta
          </Button>
        )}
        {showAddForm && (
          <FormQuestion
            quizId={props.quizId}
            onSuccess={onCreateQuestion}
            onClose={() => {
              setShowAddForm(false);
            }}
          />
        )}
      </div>

      <Dialog open={remove !== null} onClose={() => setRemove(null)}>
        <DialogContent>
          <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja remover a pergunta?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRemove(null)}>Cancelar</Button>
            <Button onClick={onRemove} autoFocus disabled={loading}>
              {loading ? "REMOVENDO..." : "SIM"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Component.displayName = "Components_Quiz_Forms_Question";

export default Component;
