import * as React from "reactn";
import { State } from "reactn/default";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "./Portal";
import { clearAlert } from "_state/alert";

type Props = {};

const Component: React.FC<Props> = () => {
  const [alert] = React.useGlobal<State, "alert">("alert");
  const clear = React.useDispatch<any>(clearAlert);
  const [message, setMessage] = React.useState<string>("");
  const [closing, setClosing] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (alert.message) {
      setMessage(alert.message);
      // setClosing(false);
    } else if (!closing) {
      setClosing(true);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [alert.message]);

  return (
    <Portal>
      <Snackbar
        open={alert.show}
        onClose={() => {
          clear();
        }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert severity={alert.type} elevation={6} variant="filled">
          {message}
        </MuiAlert>
      </Snackbar>
    </Portal>
  );
};

Component.displayName = "Components_MyAccount_Update";

export default Component;
