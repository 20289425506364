import React from "reactn";
import * as Table from "_components/Table";
import PaginateDatasource from "_services/PaginateDatasource";
import * as services from "_services";

const datasource = new PaginateDatasource<{
  id: number;
  name: string;
}>(services.admins);

const cols = [
  {
    id: "id",
    label: "ID",
    sort: true,
  },
  {
    id: "name",
    label: "Nome",
    sort: true,
  },
  {
    id: "email",
    label: "E-mail",
    sort: true,
    sortBy: "auth.email",
  },
];

type Props = {};

const Component: React.FC<Props> = () => {
  return (
    <Table.Service
      cols={cols}
      path="/administradores"
      datasource={datasource}
      hideView={true}
      onRemovedText="Administrador deletado com sucesso"
      onRemovedErrorText="Erro ao deletar o admnistrador"
      queryDefault={{
        order: "ASC",
        orderBy: "name",
      }}
    />
  );
};

Component.displayName = "Components_Admin_List";

export default Component;
