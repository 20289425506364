import * as React from "reactn";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import { QuizScore } from "src/services/quizzes";
import * as Specialty from "_components/Specialty";

const STATE = [
  {
    value: "ORIENTACAO",
    label: "Orientação/educação",
  },
  {
    value: "OTIMO",
    label: "Ótimo",
  },
  {
    value: "BOM",
    label: "Bom",
  },
  {
    value: "COMPROMETIDO",
    label: "Comprometido",
  },
  {
    value: "CRITICO",
    label: "Crítico",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  error: {
    margin: theme.spacing(2, 0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5, 5),
  },
}));

const schema = yup.object().shape({
  start: yup.number().required("Campo obrigatório"),
  end: yup.number().required("Campo obrigatório"),
});

type Props = {
  onSuccess?: () => void;
  onError?: (e: AppError | null) => void;
  score?: QuizScore | null;
  quizId: number;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [classError, setClassError] = React.useState<string>("");
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      start: 0,
      end: 0,
      title: "",
      message: "",
      specialties: [],
    },
  });

  watch();

  React.useEffect(() => {
    if (props.score) {
      setValues(props.score);
    }
  }, [props.score]);

  const setValues = async (score: QuizScore) => {
    watch();
    if (score) {
      setValue("start", score.start);
      setValue("end", score.end);
      setValue("message", score.message);
      setValue("title", score.title);
      setValue("specialties", score.specialties || []);
    }
  };

  const clearValues = () => {
    setValue("start", 0);
    setValue("end", 0);
    setValue("message", "");
    setValue("specialties", []);
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  const onChange = (e: any): void => {
    setValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    setClassError("");

    const values = getValues();

    let body: any = {
      start: Number(values.start),
      end: Number(values.end),
      title: values.title,
      message: values.message,
      specialtiesIds: values.specialties.map((s: any) => s.id),
    };

    if (props.score) {
      body.id = props.score.id;
    }

    try {
      await services.score.save(props.quizId, body);
      clearValues();
      if (props.onSuccess) props.onSuccess();
    } catch (e:any) {
      setClassError("animate__shakeX");
      setError(e as any);
      if (props.onError) props.onError(e);
    } finally {
      setLoading(false);
    }
  };

  const values = getValues();

  return (
    <form
      className={`${classes.form} animate__animated ${classError}`}
      noValidate
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} md={2}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Inicia em"
            name="start"
            type="number"
            onChange={onChange}
            error={hasInputError(error, errors, "start")}
            helperText={getInputError(error, errors, "start")}
            value={values.start}
            autoComplete="off"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Termina em"
            name="end"
            type="number"
            onChange={onChange}
            error={hasInputError(error, errors, "end")}
            helperText={getInputError(error, errors, "end")}
            value={values.end}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{
              marginTop: 15,
              marginBottom: 10,
            }}
          >
            <InputLabel id="title-id">Estágio</InputLabel>
            <Select
              labelId="title-id"
              value={values.title}
              error={hasInputError(error, errors, "title")}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                const value = e.target.value as string;
                setValue("title", value);
              }}
              name="title"
              label="Estágio"
            >
              {STATE.map((state: any) => (
                <MenuItem value={state.value}>{state.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            multiline
            minRows={5}
            maxRows={15}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Mensagem de resultado"
            name="message"
            onChange={onChange}
            error={hasInputError(error, errors, "message")}
            helperText={getInputError(error, errors, "message")}
            value={values.message}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12}>
          <Specialty.SelectMany
            specialties={values.specialties}
            onChange={(newState) => {
              setValue("specialties", newState);
            }}
          />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <ShowErrors.Field error={error} field="server" />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${classes.submit} animate__animated ${classError}`}
            disabled={loading}
          >
            {loading ? "SALVANDO..." : "SALVAR"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

Component.displayName = "Components_Admn_Forms";

export default Component;
