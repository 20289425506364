import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import { IQuery } from "./PaginateDatasource/types";

export type QuizOption = {
  id: number;
  questionId: number;
  description: string | null;
  strong: number;
  order: number;
};

export type QuizQuestion = {
  id: number;
  quizId: number;
  options: Array<QuizOption>;
  title: string;
  description: string | null;
  order: number;
};

/**
 * save
 */
export async function save(quizId: number, body: any) {
  try {
    let response = null;

    if (body.id) {
      response = await axios.put(
        `/quizzes/${quizId}/questions/${body.id}`,
        body,
        {
          useToken: true,
        }
      );
    } else {
      response = await axios.post(
        `/quizzes/${quizId}/questions/`,
        body,
        {
          useToken: true,
        }
      );
    }

    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(quizId: number, id: number) {
  try {
    const response = await axios.delete(
      `/quizzes/${quizId}/questions/${id}`,
      {
        useToken: true,
      }
    );
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * order
 */
export async function order(
  quizId: number,
  questionId: number,
  order: number
) {
  try {
    const response = await axios.put(
      `/quizzes/${quizId}/questions/${questionId}/sort`,
      { order },
      {
        useToken: true,
      }
    );
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
