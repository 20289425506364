import React from "reactn";
import { useHistory, RouteProps } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Doctor from "_components/Doctor";
import { PrivatePage } from "_components/Layout";
import { error as errorReducer, success as successReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const success = React.useDispatch<any>(successReducer);
  const error = React.useDispatch<any>(errorReducer);

  const onSuccess = () => {
    success("Profissional cadastrado com sucesso");
    history.push("/profissionais");
  };

  const onError = () => {
    error(
      "Ocorreu um erro ao atualizar os dados. Verifique os campos e tente novamente."
    );
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "PROFISSIONAIS",
                to: "/profissionais",
              },
              {
                label: "CADASTRAR",
              },
            ]}
          />
        }
      ></AppBar>
      <Doctor.Form onSuccess={onSuccess} onError={onError} />
    </PrivatePage>
  );
};

Component.displayName = "screens_Doctor_Create";

export default Component;
