import * as React from "reactn";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import AppError from "src/errors/AppError";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  paper: {
    maxWidth: "340px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  center: {
    textAlign: "center",
  },
  error: {
    margin: theme.spacing(2, 0),
  },
}));

const schema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
  password: yup.string(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas estão diferentes"),
});

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
  id?: number;
};

type State = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const Component: React.FC<Props> = (props) => {
  const [authId, setAuthId] = React.useState<number | null>(null);
  const [adminId, setAdminId] = React.useState<number | null>(null);
  const [classError, setClassError] = React.useState<string>("");
  const classes = useStyles();
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  watch();

  const setValues = async (id: number) => {
    watch();
    const admin = await services.admins.findById(id);
    setAdminId(admin.id);
    setAuthId(admin.authId);
    setValue("name", admin.name);
    setValue("email", admin.email);
  };

  React.useEffect(() => {
    if (props.id) {
      setValues(props.id);
    }
  }, []);

  const onSubmit = () => {
    setError(null);
    setLoading(true);
    setClassError("");

    const values = getValues();
    const requests = [];
    if (props.id) {
      const dataAuth: any = {
        id: authId,
        email: values.email,
      };

      if (values.password) {
        dataAuth.password = values.password;
      }
      requests.push(services.auth.update(dataAuth));

      requests.push(
        services.admins.update({
          id: adminId,
          name: values.name,
        })
      );
    } else {
      requests.push(
        services.admins.create({
          name: values.name,
          email: values.email,
          password: values.password,
        })
      );
    }

    Promise.all(requests)
      .then(() => {
        if (props.onSuccess) {
          props.onSuccess();
        } else {
          setError(null);
          setValue("password", "");
          setValue("confirmPassword", "");
        }
      })
      .catch((e: any) => {
        setClassError("animate__shakeX");
        setError(e as any);
        if (props.onError) props.onError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  const values = getValues();
  const onChange = (e: any): void => {
    const name = e.currentTarget.name as keyof State;
    const value = e.currentTarget.value;
    setValue(name, value);
  };

  return (
    <div className={classes.container}>
      <Paper elevation={4} className={classes.paper}>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className={classes.form}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label="Nome"
            name="name"
            autoComplete="new-password"
            onChange={onChange}
            error={hasInputError(error, errors, "name")}
            helperText={getInputError(error, errors, "name")}
            value={values.name}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="new-password"
            onChange={onChange}
            error={hasInputError(error, errors, "email")}
            helperText={getInputError(error, errors, "email")}
            value={values.email}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Senha"
            type="password"
            id="password"
            name="password"
            autoComplete="new-password"
            onChange={onChange}
            error={hasInputError(error, errors, "password")}
            helperText={getInputError(error, errors, "password")}
            value={values.password}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Repetir senha"
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            autoComplete="new-password"
            onChange={onChange}
            error={errors.confirmPassword ? true : false}
            helperText={getInputError(error, errors, "confirmPassword")}
            value={values.confirmPassword}
          />

          <ShowErrors.Field error={error} field="server" />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={`${classes.submit} animate__animated ${classError}`}
            disabled={loading}
          >
            {loading ? "SALVANDO..." : "SALVAR"}
          </Button>
        </form>
      </Paper>
    </div>
  );
};

Component.displayName = "Components_Admn_Forms";

export default Component;
