import * as React from "reactn";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles, styled } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
	Chip,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import * as Specialty from "_components/Specialty";
import * as Quiz from "_components/Quiz";
import config from "_config";
import RefusedMessage from "./RefusedMessage";
import * as Doctor from "_components/Doctor";
import { Alert } from "@material-ui/lab";
import getYoutubeId from "_helpers/getYoutubeId";
import * as Media from "_components/Media";

const Input = styled("input")({
	display: "none",
});

const useStyles = makeStyles((theme) => ({
	container: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: theme.spacing(1.5, 5),
	},
	center: {
		textAlign: "center",
	},
	error: {
		margin: theme.spacing(2, 0),
	},
	address: {
		margin: theme.spacing(2, 0),
	},
	noSpacing: {
		marginTop: theme.spacing(0) + " !important",
		marginBottom: theme.spacing(0) + " !important",
		paddingTop: theme.spacing(0) + " !important",
		paddingBottom: theme.spacing(0) + " !important",
	},
	group: {
		marginBottom: theme.spacing(3),
	},
	groupTitle: {
		marginTop: theme.spacing(0) + " !important",
		marginBottom: theme.spacing(0) + " !important",
		fontSize: "12px",
		textTransform: "uppercase",
	},
	imageContaner: {
		width: "100%",
		height: "100%",
	},

	previewContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: 20,
		"& > img": {
			width: "100%",
			height: "auto",
		},
	},
	thumbPreview: {},
	previewRemove: {
		zIndex: 1,
		position: "absolute",
		top: 0,
		right: 0,
		background: "#FFF",
	},
	fileContainer: {
		width: "100%",
		padding: 15,
		border: "3px dashed #DDD",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "center",
		position: "relative",
		display: "flex",
		flexDirection: "column",
	},

	videoPreviewContainer: {
		display: "flex",
	},
	videoPreview: {
		width: "100%",
		height: "auto",
	},

	inputYoutubeContainer: {
		marginLeft: 15,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	inputYoutube: {},

	messageYoutube: {
		width: "100%",
	},

	inputError: {
		borderColor: theme.palette.error.main,
	},
	inputErrorMessage: {
		color: theme.palette.error.main,
		margin: theme.spacing(1, 0, 2, 0),
	},
	avatar: {},
}));

const schema = yup.object().shape({
	title: yup.string().required("Campo obrigatório"),
	type: yup.string().required("Campo obrigatório"),
	category: yup.string().required("Campo obrigatório"),
	thumbnailUpload: yup.mixed().test(async (value?: string, context?: any) => {
		if (!value && !context.parent.thumbnail) {
			return context.createError({
				message: "Imagem obrigatória",
			});
		}
		return true;
	}),
	doctor: yup.mixed().test(async (value?: string, context?: any) => {
		if (!value && context.parent.category !== "SUGGESTIONS") {
			return context.createError({
				message: "Selecione o profissional",
			});
		}
		return true;
	}),
	specialties: yup
		.mixed()
		.test(async (value?: Array<number>, context?: any) => {
			if (value?.length === 0 && context.parent.category !== "SUGGESTIONS") {
				return context.createError({
					message: "Selecione ao menos uma especialidade",
				});
			}
			return true;
		}),

	// externalLink: yup.mixed().test(async (value?: string, context?: any) => {
	//   if (!value && context.parent.category == "SUGGESTIONS") {
	//     return context.createError({
	//       message: "insira o link de apoio",
	//     });
	//   }
	//
	//   if (value) {
	//     const ex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
	//     var regex = new RegExp(ex);
	//     if (!value.match(regex)) {
	//       return context.createError({
	//         message: "Link inválido",
	//       });
	//     }
	//   }
	//
	//   return true;
	// }),

	// mediaUpload: yup.mixed().test(async (value?: string, context?: any) => {
	// 	if (
	// 		!value &&
	// 		!context.parent.media &&
	// 		context.parent.type !== "VIDEO" &&
	// 		context.parent.category !== "SUGGESTIONS" // MUP INDICA NÃO OBRIGA ENVIO DE ARQUIVO SOMENTE LINK EXTERNO
	// 	) {
	// 		return context.createError({
	// 			message: "Selecione o arquivo",
	// 		});
	// 	}
	// 	return true;
	// }),

	youtubeUrl: yup.string().test(function (value: any, context: any) {
		if (this.parent.type === "VIDEO") {
			if (value === "") {
				// if (value === "" && this.parent.isUpdate === false) {
				return context.createError({
					message: "Insira o endereço do vídeo youtube",
				});
			}
			return true;
		}

		return true;
	}),
});

type Props = {
	onSuccess?: () => void;
	onError?: () => void;
	id?: number;
};

const Component: React.FC<Props> = (props) => {
	const [classError, setClassError] = React.useState<string>("");
	const classes = useStyles();
	const [showRefusedMessageDialog, setShowRefusedMessageDialog] =
		React.useState<boolean>(false);
	const [prevStatus, setPrevStatus] = React.useState<string | null>(null);
	const [error, setError] = React.useState<AppError | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const {
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			isUpdate: false,
			mediaId: null,
			media: null,
			thumbnailId: null,
			thumbnail: null,
			doctor: null,
			type: "PDF",
			category: "",
			title: "",
			description: "",
			externalLink: "",
			status: "PUBLISHED",
			specialties: [],
			quizzes: [],
			mediaUpload: null,
			mediaUploadPreview: "",
			thumbnailUpload: null,
			thumbnailUploadPreview: "",
			refusedMessage: "",
			youtubeUrl: "",
			// youtube: "",
		} as any,
	});

	watch();

	const setValues = async (id: number) => {
		watch();
		setValue("isUpdate", id ? true : false);
		const content = await services.contents.findById(id);
		setValue("doctor", content.doctor);
		setValue("title", content.title);
		setValue("description", content.description);
		setValue("type", content.type);
		setValue("category", content.category || "");
		setValue("externalLink", content.externalLink);
		setValue("status", content.status);
		setValue("specialties", content.specialties || []);
		setValue("quizzes", content.quizzesWithStage || []);
		setValue("mediaId", content.mediaId || null);
		setValue("media", content.media || null);
		setValue("thumbnailId", content.thumbnailId || null);
		setValue("thumbnail", content.thumbnail || null);
		setValue("youtubeUrl", content.youtubeUrl || "");
		setValue("refusedMessage", content.refusedMessage || null);
		setLoaded(true);
	};

	React.useEffect(() => {
		if (props.id) {
			setValues(props.id);
		}
	}, []);

	React.useEffect(() => {
		const newStatus = getValues().status;
		if (
			newStatus === "REFUSED" &&
			prevStatus !== null &&
			prevStatus != newStatus
		) {
			setShowRefusedMessageDialog(true);
		} else {
			// setShowRefusedMessageDialog(false);
		}

		if (props.id && loaded) {
			if (prevStatus === null || prevStatus !== newStatus) {
				setPrevStatus(newStatus);
			}
		}
	}, [props.id, getValues()]);

	const onError = () => {
		setClassError("");
		setTimeout(() => {
			setClassError("animate__shakeX");
		}, 0);
		if (props.onError) props.onError();
	};

	const showPDF = async (e: any) => {
		e.preventDefault();
		const href = `${config.CNDEndpoint}/${values.media.src}`;
		// const href = values.media.src;
		// const href = await services.doctors.downloadFile(props.id as number, src);
		const a = document.createElement("a");
		a.setAttribute("href", href);
		a.setAttribute("download", "true");
		document.body.append(a);
		a.click();
		a.remove();
	};

	const onSubmit = async () => {
		setError(null);
		setLoading(true);
		setClassError("");

		const values = getValues();

		const contentData: any = {
			title: values.title,
			description: values.description || "",
			type: values.type,
			category: values.category || "",
			status: values.status,
			specialties: values.specialties.map((s: any) => s.id),
			quizzes: values.quizzes,
			refusedMessage: values.refusedMessage || "",
		};

		if (values.doctor) {
			contentData.doctorId = values.doctor.id;
		}

		if (values.youtubeUrl) {
			contentData.youtubeUrl = values.youtubeUrl;
		}

		if (values.externalLink) {
			let link = values.externalLink;
			if (link.includes("http") === false) {
				link = "http://" + link;
			}
			contentData.externalLink = link;
		}

		try {
			if (props.id) {
				contentData.id = props.id;
			}

			const content = await services.contents.save(contentData);
			const contentId = content.id;

			// TODO: upload media
			if (values.mediaUpload) {
				const mediaForm = new FormData();
				mediaForm.append("file", values.mediaUpload.file);
				mediaForm.append("type", values.mediaUpload.type);
				mediaForm.append("filename", values.mediaUpload.file.name);
				mediaForm.append("contentId", contentId);
				await services.contents.saveFile(contentId, mediaForm);
			}

			if (values.thumbnailUpload) {
				const mediaForm = new FormData();
				mediaForm.append("file", values.thumbnailUpload);
				mediaForm.append("type", "THUMBNAIL");
				mediaForm.append("filename", values.thumbnailUpload.name);
				mediaForm.append("contentId", contentId);
				await services.contents.saveFile(contentId, mediaForm);
			}

			if (props.onSuccess) {
				props.onSuccess();
			}
		} catch (e) {
			setClassError("animate__shakeX");
			setError(e as any);
			if (props.onError) props.onError();
		} finally {
			setLoading(false);
		}
	};

	const onSelectMedia = (e: any, type: "PDF" | "IMAGE") => {
		const file = e.target.files[0];

		if (!file) return;

		setValue("mediaUploadPreview", "");

		if (type === "IMAGE") {
			var reader = new FileReader();
			reader.onload = function (e: any) {
				setValue("mediaUploadPreview", e.target.result);
			};

			reader.onerror = function (e: any) {
				console.error(e);
			};

			reader.readAsDataURL(file);
		}

		setValue("mediaUpload", {
			file,
			_type: type,
		});
	};

	const onSelectThumbnail = (form: any) => {
		form.append("filename", "THUMBNAIL");
		form.append("tag", "THUMBNAIL");

		//@ts-ignore
		setValue("thumbnailUpload", form.get("file"));

		// setValue("thumbnailUploadPreview", form.);

		// setValue("thumbnailUpload", file);

		// var reader = new FileReader();
		// reader.onload = function (e: any) {
		//   setValue("thumbnailUploadPreview", e.target.result);
		// };

		// reader.onerror = function (e: any) {
		//   console.error(e);
		// };

		// reader.readAsDataURL(file);
	};

	// const saveThumbnail = async (doctorId: number, form: FormData) => {
	//   if (values.media?.AVATAR) {
	//     await services.doctors.removeFile(
	//       props.id || 0,
	//       values.media?.AVATAR?.id
	//     );
	//   }

	//   try {
	//     const doctor = await services.doctors.saveFile(doctorId as number, form);
	//     setValue("media", doctor.media);
	//   } catch (e) {
	//     console.error("ERRO AO SALVAR A IMAGEM", e);
	//   }
	// };

	const removeThumbnail = async () => {
		try {
			if (props.id) {
				await services.contents.removeFile(
					props.id || 0,
					getValues().thumbnail.id,
					"THUMBNAIL"
				);
				setValue("thumbnail", null);
			}
		} catch (e) {}
	};

	const removeThumbnailPreview = async () => {
		setValue("thumbnailUploadPreview", null);
		setValue("thumbnail", null);
	};

	const removeFile = async () => {
		try {
			if (props.id) {
				await services.contents.removeFile(
					props.id || 0,
					getValues().media.id,
					getValues().type
				);
				setValue("media", null);
			}
		} catch (e) {}
	};

	const values = getValues();

	const youtubeId = getYoutubeId(values.youtubeUrl || "");

	return (
		<div className={classes.container}>
			<form
				onSubmit={handleSubmit(onSubmit, onError)}
				className={classes.form}
				noValidate
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4} lg={3}>
									<FormControl
										variant="outlined"
										fullWidth
										className={classes.address}
									>
										<InputLabel id="type-id">Categoria</InputLabel>
										<Select
											labelId="type-id"
											value={values.category}
											error={hasInputError(error, errors, "category")}
											onChange={(e: any) =>
												setValue("category", e.target.value)
											}
											name="category"
											label="Categoria"
										>
											<MenuItem value="SUGGESTIONS">MUP INDICA</MenuItem>
											<MenuItem value="DAILY">MUP DIÁRIO</MenuItem>
											<MenuItem value="RECOMMENDED">
												CONTEÚDO RECOMENDADO
											</MenuItem>
										</Select>
										{hasInputError(error, errors, "category") && (
											<p className={classes.inputErrorMessage}>
												{getInputError(error, errors, "category")}
											</p>
										)}
									</FormControl>
								</Grid>

								<Grid item xs={12} md={4} lg={3}>
									<FormControl
										variant="outlined"
										fullWidth
										className={classes.address}
									>
										<InputLabel id="type-id">Formato do conteúdo</InputLabel>
										<Select
											labelId="type-id"
											value={values.type}
											error={hasInputError(error, errors, "type")}
											onChange={(e: any) => setValue("type", e.target.value)}
											name="type"
											label="Formato do conteúdo"
										>
											<MenuItem value="PDF">PDF</MenuItem>
											<MenuItem value="VIDEO">VÍDEO</MenuItem>
											<MenuItem value="IMAGE">IMAGEM</MenuItem>
										</Select>
									</FormControl>
								</Grid>

								{values.category !== "SUGGESTIONS" && (
									<Grid item xs={12} md={4} lg={3} style={{ marginTop: 15 }}>
										<Doctor.Select
											doctor={values.doctor}
											onChange={(newState: any) => {
												setValue("doctor", newState);
											}}
										/>

										{hasInputError(error, errors, "doctor") && (
											<p className={classes.inputErrorMessage}>
												{getInputError(error, errors, "doctor")}
											</p>
										)}
									</Grid>
								)}
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} md={7} lg={8}>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="title"
										label="Título"
										name="title"
										onChange={(e: any) => setValue("title", e.target.value)}
										error={hasInputError(error, errors, "title")}
										helperText={getInputError(error, errors, "title")}
										value={values.title}
										autoComplete="new-password"
									/>
								</Grid>

								<Grid item xs={12} md={12} lg={12}>
									<TextField
										multiline={true}
										rows={4}
										variant="outlined"
										margin="normal"
										fullWidth
										id="description"
										label="Descrição"
										name="description"
										onChange={(e: any) =>
											setValue("description", e.target.value)
										}
										error={hasInputError(error, errors, "description")}
										helperText={getInputError(error, errors, "description")}
										value={values.description}
										autoComplete="new-password"
									/>
								</Grid>

								<Grid item xs={12} md={12} lg={12}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="externalLink"
										label="Link para material complementar"
										placeholder="Digite um link de site, blog ou evento relacionado a este conteúdo."
										name="externalLink"
										onChange={(e: any) =>
											setValue("externalLink", e.target.value)
										}
										error={hasInputError(error, errors, "externalLink")}
										helperText={getInputError(error, errors, "externalLink")}
										value={values.externalLink}
										autoComplete="new-password"
									/>
								</Grid>

								{values.category !== "SUGGESTIONS" && (
									<Grid item xs={12}>
										<Specialty.SelectMany
											specialties={values.specialties}
											onChange={(newState: any) => {
												setValue("specialties", newState);
											}}
										/>
										{hasInputError(error, errors, "specialties") && (
											<p className={classes.inputErrorMessage}>
												{getInputError(error, errors, "specialties")}
											</p>
										)}
									</Grid>
								)}
								{values.category !== "SUGGESTIONS" && (
									<Grid item xs={12}>
										<Quiz.SelectManyWithStages
											quizzes={values.quizzes}
											onChange={(newState: any) => {
												setValue("quizzes", newState);
											}}
										/>
									</Grid>
								)}
							</Grid>
						</Paper>
					</Grid>

					<Grid item xs={12} md={5} lg={4}>
						<Paper elevation={2} className={classes.paper}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<div className={classes.avatar}>
										<Media.Avatar
											onCancel={removeThumbnail}
											onNewFile={onSelectThumbnail}
											currentFile={
												values?.thumbnail?.src
													? `${config.CNDEndpoint}/${values.thumbnail.src}`
													: undefined
											}
											width={300}
											height={160}
										/>
									</div>

									<ShowErrors.Field
										error={errors.thumbnailUpload || null}
										field="thumbnailUpload"
									/>

									<ShowErrors.Field error={error} field="thumbnailUpload" />
								</Grid>

								<Grid item xs={12}>
									{values.type !== "VIDEO" && (
										<div
											className={classes.fileContainer}
											style={
												values.type === "PDF"
													? {
															flexDirection: "column",
													  }
													: {}
											}
										>
											{values.type === "PDF" && (
												<div
													style={{
														marginBottom: 10,
													}}
												>
													{values.mediaUpload && (
														<Chip
															label={values.mediaUpload?.file?.name as any}
															onDelete={() => setValue("mediaUpload", null)}
														/>
													)}

													{!values.mediaUpload && values.media && (
														<Chip
															label={values.media?.name as any}
															onDelete={removeFile}
														/>
													)}
												</div>
											)}

											{values.type === "IMAGE" && (
												<>
													{values.mediaUploadPreview && (
														<div className={classes.imageContaner}>
															<div className={classes.previewContainer}>
																<img src={values.mediaUploadPreview} />
															</div>
														</div>
													)}

													{values.media &&
														values.media.src.indexOf(".pdf") === -1 &&
														!values.mediaUploadPreview && (
															<>
																<div className={classes.imageContaner}>
																	<div className={classes.previewContainer}>
																		<img
																			src={`${config.CNDEndpoint}/${values.media.src}`}
																		/>
																	</div>
																</div>

																<IconButton
																	color="primary"
																	aria-label="upload picture"
																	component="span"
																	className={classes.previewRemove}
																	onClick={removeFile}
																>
																	<DeleteIcon />
																</IconButton>
															</>
														)}
												</>
											)}

											<label htmlFor="media-file">
												<Input
													accept={
														values.type === "IMAGE"
															? "image/*"
															: "application/pdf"
													}
													id="media-file"
													type="file"
													onChange={(e) => onSelectMedia(e, values.type)}
												/>
												<Button
													variant="contained"
													component="span"
													color="default"
												>
													{values.mediaUpload || values.media
														? "ALTERAR ARQUIVO"
														: "SELECIONAR ARQUIVO"}
												</Button>
											</label>

											{values.media && values.type == "PDF" && (
												<Button
													variant="contained"
													component="span"
													color="default"
													onClick={showPDF}
													style={{
														marginTop: 10,
													}}
												>
													BAIXAR ARQUIVO
												</Button>
											)}
										</div>
									)}

									{values.type === "VIDEO" && (
										<Grid item xs={12}>
											<Grid container spacing={3}>
												{youtubeId !== "" && (
													<Grid item xs={12}>
														<iframe
															width="100%"
															height="250px"
															src={`https://www.youtube.com/embed/${youtubeId}`}
															title="YouTube video player"
															frameBorder="0"
															allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
															allowFullScreen
														></iframe>
														{/*
                            <video className={classes.videoPreview} controls>
                              <source
                                src={`${config.CNDEndpoint}/${values.media.src}`}
                                type="video/mp4"
                              />
                            </video>
                            */}
													</Grid>
												)}

												<Grid item xs={12}>
													<TextField
														variant="outlined"
														margin="normal"
														fullWidth
														id="youtubeUrl"
														label="Insira o link do vídeo publicado no YouTube"
														placeholder="URL"
														name="youtubeUrl"
														onChange={(e: any) =>
															setValue("youtubeUrl", e.target.value)
														}
														error={hasInputError(error, errors, "youtubeUrl")}
														helperText={getInputError(
															error,
															errors,
															"youtubeUrl"
														)}
														value={values.youtubeUrl}
														autoComplete="new-password"
													/>
												</Grid>
											</Grid>
										</Grid>
									)}

									<Grid item xs={12}>
										<ShowErrors.Field
											error={errors.mediaUpload || null}
											field="thumbnailUpload"
										/>

										<ShowErrors.Field
											error={error || null}
											field="mediaUpload"
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								{values.status === "UPLOADING" ? (
									<p>Enviando vídeo</p>
								) : (
									<FormControl
										variant="outlined"
										fullWidth
										className={classes.address}
									>
										<InputLabel id="type-id">Status</InputLabel>
										<Select
											labelId="type-id"
											value={values.status}
											error={hasInputError(error, errors, "status")}
											onChange={(e: any) => setValue("status", e.target.value)}
											name="status"
											label="Status"
										>
											<MenuItem value="PENDING">Pendente</MenuItem>
											<MenuItem value="REFUSED">Recusado</MenuItem>
											<MenuItem value="PUBLISHED">Publicado</MenuItem>
											<MenuItem value="DISABLED">Desabilitado</MenuItem>
										</Select>
									</FormControl>
								)}

								{values.refusedMessage && values.status === "REFUSED" && (
									<Grid item xs={12}>
										<Paper
											elevation={1}
											style={{ padding: 15, backgroundColor: "#ff00291f" }}
										>
											<strong>Mensagem sobre conteúdo recusado:</strong>
											<p>{values.refusedMessage}</p>
											<Button
												type="button"
												variant="contained"
												color="primary"
												onClick={() => setShowRefusedMessageDialog(true)}
											>
												ALTERAR
											</Button>
										</Paper>
									</Grid>
								)}

								<Grid item xs={12}>
									<ShowErrors.Field error={error} field="server" />
									<Button
										type="submit"
										variant="contained"
										color="primary"
										fullWidth
										className={`${classes.submit} animate__animated ${classError}`}
										disabled={loading}
									>
										{loading ? "SALVANDO..." : "SALVAR"}
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</form>

			<RefusedMessage
				open={showRefusedMessageDialog}
				message={values.refusedMessage}
				onSuccess={(value: string) => {
					setValue("refusedMessage", value);
					setShowRefusedMessageDialog(false);
				}}
				onClose={() => {
					setShowRefusedMessageDialog(false);

					setValue("status", prevStatus);
					// setValue("status", "PENDING");
				}}
			/>
		</div>
	);
};

Component.displayName = "Components_Admn_Forms";

export default Component;
