import React from "react";
import { RouteProps } from "react-router-dom";
import { PublicPage } from "_components/Layout";
import Loading from "_UI/Loading";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import "react-pdf/dist/esm/Page/TextLayer.css";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import qs from "qs";
import getYoutubeId from "_helpers/getYoutubeId";

const options = {
  // cMapUrl: "cmaps/",
  // cMapPacked: true,
  // standardFontDataUrl: "standard_fonts/",
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100%",
  },
  document: {
    zIndex: 1,
  },
  error: {
    display: "flex",
    flex: 1,
    height: "100vh",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    textAlign: "center",
  },
}));

const Component: React.FC<RouteProps> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {}, []);

  const query = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const onError = () => {
    setLoading(false);
  };

  const url = query.url || "";
  const youtubeId = getYoutubeId(url as string);

  return (
    <PublicPage>
      <div className={classes.container}>
        {loading && <Loading show={true} />}
        {!youtubeId ? (
          <div className={classes.error}>
            <p>Conteúdo indisponível</p>
          </div>
        ) : (
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${youtubeId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </PublicPage>
  );
};

Component.displayName = "screens_Pdf";

export default Component;
