import React from "reactn";
import mup from "./theme/mup";
import { ThemeProvider } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Routes from "_routes";
import { auth as authStorage } from "_helpers/localStorage";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "./components/UI/Alert";
import { getMe as getMeReducer } from "_state/auth";

const useStyles = makeStyles(() => ({
  loading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "80vh",
  },
}));

export default () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const getMeDispatch = React.useDispatch<any>(getMeReducer);
  const classes = useStyles();

  const getMe = async () => {
    try {
      await getMeDispatch();
      setLoading(false);
    } catch (e) {
      refreshToken();
    }
  };

  const refreshToken = async () => {
    try {
      await getMeDispatch();
      setLoading(false);
    } catch (e) {
      // await dispatch.logout()
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const token = authStorage.get("access_token");
    if (token) {
      getMe();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <ThemeProvider theme={mup}>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <Alert />
          <Routes />
        </>
      )}
    </ThemeProvider>
  );
};
