import React from "reactn";
import * as Table from "_components/Table";
import PaginateDatasource from "_services/PaginateDatasource";
import * as services from "_services";
// import Filter from "./Filter";
import moment from "moment";
import { Link } from "react-router-dom";

const datasource = new PaginateDatasource<{
  id: number;
  name: string;
}>(services.evaluations);

const colors = {
  ORIENTACAO: "#8dba32",
  OTIMO: "#f9ca21",
  BOM: "#ee9229",
  COMPROMETIDO: "#ea592a",
  CRITICO: "#e73b2a",
  INDEFINIDO: "#e73b2a",
} as any;

const cols = [
  {
    id: "id",
    label: "ID",
    sort: false,
  },
  {
    id: "pacient.name",
    label: "Cliente",
    sort: false,
    render: (item: any) => {
      return (
        <Link to={`/pacientes/${item.pacient?.id}`}>{item.pacient?.name}</Link>
      );
    },
  },
  {
    id: "quiz.name",
    label: "Mapeamento",
    sort: false,
    render: (item: any) => {
      return (
        <Link to={`/mapeamentos/editar/${item.quiz?.id}`}>
          {item.quiz?.name}
        </Link>
      );
    },
  },
  {
    id: "quiz.score",
    label: "Estado",
    sort: false,
    render: (item: any) => {
      const color: any = colors[item.quizScore?.title || ""] || "#ccc";
      return (
        <div
          style={{
            background: color,
            padding: "3px 10px",
            borderRadius: "100px",
            display: "inline-block",
            fontSize: "12px",
            color: "#FFF",
          }}
        >
          {item.quizScore?.description || "Indefinido"}({item.score})
        </div>
      );
    },
  },
  {
    id: "date",
    label: "Data",
    sort: false,
    render: (item: any) => {
      return (
        <>
          {moment(item.date).format("DD-MM-YYYY HH:MM")}
          <br />
          {moment(item.date).fromNow()}
        </>
      );
    },
  },
];

type Props = {};

const Component: React.FC<Props> = () => {
  return (
    <>
      <Table.Service
        // filter={(props: any) => <Filter {...props} />}
        cols={cols}
        path="/prognosticos"
        datasource={datasource}
        hideView={false}
        hideUpdate={true}
        onRemovedText="Prognóstico deletado com sucesso"
        onRemovedErrorText="Erro ao deletar o prognóstico"
        queryDefault={{
          order: "DESC",
          orderBy: "date",
        }}
      />
    </>
  );
};

Component.displayName = "Components_Evaluation_List";

export default Component;
