import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import { IQuery } from "./PaginateDatasource/types";

export type Specialty = {
  id?: number | null;
  specialtyId?: number | null;
  specialty?: Specialty | null;
  name: string;
  description: string | null;
};

/**
 * findALl
 */
export async function findAll(query?: Partial<IQuery> & { search?: string }) {
  const queryString = qs.stringify(query || {}, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/types/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * findById
 */
export async function findById(id: number) {
  try {
    const response = await axios.get(`/types/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * save
 */
export async function save(body: any) {
  try {
    let response = null;

    if (body.id) {
      response = await axios.put(`/types/${body.id}`, body, {
        useToken: true,
      });
    } else {
      response = await axios.post(`/types`, body, {
        useToken: true,
      });
    }

    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(id: number) {
  try {
    const response = await axios.delete(`/types/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
