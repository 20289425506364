import React from "reactn";
import {
  Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Select,
} from "@material-ui/core";
import * as Doctor from "_components/Doctor";
import * as services from "_services";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    button: {
      backgroundColor: "#FFF",
      marginLeft: 15,
      marginTop: 15,
      paddingLeft: 30,
      paddingRight: 30,
    },
    categoryButton: {
      margin: theme.spacing(2, 0, 0, 3),
    },
    inputs: {
      padding: theme.spacing(1, 0),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: 1,
    },
    status: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: 15,
    },
    category: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: 15,
    },
  })
);

type FilterProps = {
  applyFilter: (query: any) => void;
  query: any;
};

const Filter: React.FC<FilterProps> = (props) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("ALL");
  const [status, setStatus] = React.useState<string>("ALL");
  const [doctor, setDoctor] = React.useState<any>(null);
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    if (props.query?.category) {
      setCategory(props.query.category);
    }
    if (props.query?.search) {
      setSearch(props.query.search);
    }
    if (props.query?.status) {
      setStatus(props.query.status);
    }
    if (props.query?.doctorId) {
      services.doctors.findById(props.query?.doctorId).then((_doctor: any) => {
        setDoctor(_doctor);
      });
    }
  }, [props.query]);

  const onSubmit = (e?: React.FormEvent) => {
    if (e) e.preventDefault();

    const data: any = {
      search,
    };

    if (status && status !== "ALL") {
      data.status = status;
    }

    if (category && category !== "ALL") {
      data.category = category;
    }

    if (doctor) {
      data.doctorId = doctor.id;
    }

    props.applyFilter(data);
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <div className={classes.inputs}>
        <TextField
          fullWidth
          variant="standard"
          inputMode="search"
          label="Título do contéudo"
          placeholder="Título"
          onChange={onSearch}
          value={search}
          autoComplete="new-password"
        />

        <FormControl variant="outlined" className={classes.category} fullWidth>
          <InputLabel id="category-id"></InputLabel>
          <Select
            fullWidth
            variant="standard"
            labelId="category-id"
            value={category}
            onChange={(e: any) => setCategory(e.target.value)}
            name="category"
            label="Todas categorias"
          >
            <MenuItem value="ALL">Todas categorias</MenuItem>
            <MenuItem value="SUGGESTIONS">Mup Indica</MenuItem>
            <MenuItem value="DAILY">Mup Diário</MenuItem>
            <MenuItem value="RECOMMENDED">Conteúdo Recomendado</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" className={classes.status} fullWidth>
          <InputLabel id="status-id"></InputLabel>
          <Select
            fullWidth
            variant="standard"
            labelId="status-id"
            value={status}
            onChange={(e: any) => setStatus(e.target.value)}
            name="status"
            label="Status"
          >
            <MenuItem value="ALL">Todos os status</MenuItem>
            <MenuItem value="PENDING">Status Pendente</MenuItem>
            <MenuItem value="REFUSED">Status Recusado</MenuItem>
            <MenuItem value="PUBLISHED">Status Publicado</MenuItem>
            <MenuItem value="DISABLED">Status Desabilitado</MenuItem>
          </Select>
        </FormControl>

        <div style={{ width: "100%", marginTop: "8px", marginLeft: 15 }}>
          <Doctor.Select
            doctor={doctor}
            variant="standard"
            onChange={(newState: any) => {
              setDoctor(newState);
            }}
          />
        </div>
      </div>
      <Button
        type="submit"
        variant="contained"
        color="default"
        className={classes.button}
      >
        Aplicar filtro
      </Button>
    </form>
  );
};

export default Filter;
