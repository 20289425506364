import React from "reactn";
import * as Table from "_components/Table";
import PaginateDatasource from "_services/PaginateDatasource";
import * as services from "_services";
import Filter from "./Filter";
import { IRow } from "src/components/Table/Service/types";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  TableCell,
  Theme,
} from "@material-ui/core";

type IStatus = {
  color: string;
  label: string;
};

type IAllStatus = { [key: string]: IStatus };

const STATUS: IAllStatus = {
  PUBLISHED: {
    color: "#008262",
    label: "Publicado",
  },
  PENDING: {
    color: "#FCCE45",
    label: "Pendente",
  },
  REFUSED: {
    color: "#F1262D",
    label: "Recusado",
  },
  DISABLED: {
    color: "#ccc",
    label: "Desabilitado",
  },
  UPLOADING: {
    color: "#ccc",
    label: "Enviando vídeo",
  },
};

const TYPE: any = {
  PDF: {
    color: "#4A0B61",
    label: "PDF",
  },
  VIDEO: {
    color: "#4A0B61",
    label: "Vídeo",
  },
  IMAGE: {
    color: "#4A0B61",
    label: "Imagem",
  },
};

const CATEGORY: any = {
  SUGGESTIONS: "Mup Indica",
  DAILY: "Mup Diário",
  RECOMMENDED: "Recomendado",
};

/**
 *  CHECK CONTENT UPDATE
 */
const useCheckStatusUpdate = (content: { id: number; status: string }) => {
  const [status, setStatus] = React.useState<string>(content.status);

  React.useEffect(() => {
    if (content.status === "UPLOADING") {
      let t: any = null;
      t = setInterval(() => {
        checkUpdate().then((_content: any) => {
          if (_content.status !== "UPLOADING") {
            setStatus(_content.status);
            clearInterval(t);
          }
        });
      }, 1000 * 5);

      return () => {
        clearInterval(t);
      };
    }
  }, []);

  const checkUpdate = async () => {
    try {
      const response = await services.contents.findById(content.id);
      return response;
    } catch (e) {}
  };

  return [status];
};

/**
 *  UPLOADING
 */
type UploadingProps = {
  row: any;
};

const Uploading: React.FC<UploadingProps> = (props) => {
  const classes = useStyles();
  const [status] = useCheckStatusUpdate(props.row);
  if (status !== "UPLOADING") return null;
  return (
    <div className={classes.uploading}>
      <span>
        <CircularProgress size={16} />
      </span>
    </div>
  );
};

/**
 *  STATUS
 */
type StatusProps = {
  content: any;
};

const Status: React.FC<StatusProps> = (props) => {
  const [nextStatus] = useCheckStatusUpdate(props.content);
  const status = STATUS[nextStatus || ""] as any;

  if (!status) return null;

  return (
    <div
      style={{
        background: status.color,
        padding: "3px 10px",
        borderRadius: "100px",
        display: "inline-block",
        fontSize: "12px",
        color: "#FFF",
      }}
    >
      {status.label}
    </div>
  );
};

/**
 *  TABLE
 */
const datasource = new PaginateDatasource<{
  id: number;
  name: string;
}>(services.contents);

const cols = [
  {
    id: "id",
    label: "ID",
    sort: true,
  },
  {
    id: "doctorId",
    label: "Autor",

    render: (content: any) => {
      const author: any = content.doctor?.name || "MUP";
      return <span>{author}</span>;
    },
  },
  {
    id: "title",
    label: "Título",
    sort: true,
  },
  {
    id: "category",
    label: "Categoria",
    sort: true,
    sortBy: "category",
    render: (content: any) => {
      const category: any = CATEGORY[content.category || ""] as any;
      if (!category) return null;
      return <div>{category}</div>;
    },
  },
  {
    id: "type",
    label: "Tipo",
    sort: true,
    sortBy: "status",
    render: (content: any) => {
      const type: any = TYPE[content.type || ""] as any;
      if (!type) return null;
      return (
        <div
          style={{
            background: type.color,
            padding: "3px 10px",
            borderRadius: "100px",
            display: "inline-block",
            fontSize: "12px",
            color: "#FFF",
          }}
        >
          {type.label}
        </div>
      );
    },
  },
  {
    id: "status",
    label: "Status",
    sort: true,
    sortBy: "status",
    render: (content: any) => {
      return <Status content={content} />;
    },
  },
];

type Props = {};

const Component: React.FC<Props> = () => {
  return (
    <>
      <Table.Service
        filter={(props: any) => <Filter {...props} />}
        cols={cols}
        path="/conteudos"
        datasource={datasource}
        hideView
        beforeRenderRow={(row: IRow) => <Uploading row={row} />}
        onRemovedText="Post deletado com sucesso"
        onRemovedErrorText="Erro ao deletar o post"
        queryDefault={{
          order: "DESC",
          orderBy: "createdAt",
        }}
      />
    </>
  );
};

Component.displayName = "Components_Content_List";

export default Component;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploading: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "#FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: 0,
      left: 0,
      zIndex: 99,
      fontWeight: "bold",
    },
  })
);
