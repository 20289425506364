import React from "react";
import { RouteProps } from "react-router-dom";
import { PublicPage } from "_components/Layout";
import Loading from "_UI/Loading";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import "react-pdf/dist/esm/Page/TextLayer.css";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import qs from "qs";

const options = {
  // cMapUrl: "cmaps/",
  // cMapPacked: true,
  // standardFontDataUrl: "standard_fonts/",
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100%",
  },
  document: {
    zIndex: 1,
  },
  error: {
    display: "flex",
    flex: 1,
    height: "100vh",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    textAlign: "center",
  },
}));

const Component: React.FC<RouteProps> = () => {
  const [numPages, setNumPages] = React.useState<number | null>(null);
  // const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(true);
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => { }, []);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function onPageLoaded(doc: any) {
    const target = document.querySelector(".react-pdf__Document") as Node;
    const observer = new MutationObserver(function (mutations: any) {
      mutations.forEach(function (mutation: any) {
        if (mutation.type === "childList") {
          setLoading(false);
        }
      });
    });
    if (target) {
      observer.observe(target as Node, { childList: true });
      // observer.disconnect();
    }
  }

  const query = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const onError = () => {
    setLoading(false);
  };

  const url = query.url || "";

  return (
    <PublicPage>
      <div className={classes.container}>
        {/* {loading && <Loading show={true} />} */}
        <Document
          className={classes.document}
          file={url}
          onLoadError={onError}
          onSourceError={onError}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          renderMode={"canvas"}
          loading={() => <></>}
          error={() => {
            return (
              <div className={classes.error}>
                <p>Conteúdo indisponível</p>
              </div>
            );
          }}
        >
          {Array.from(new Array(numPages), (el: any, index: number) => (
            <Page
              width={window.innerWidth}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              onLoadSuccess={onPageLoaded}
              loading={() => <></>}
            />
          ))}
        </Document>
      </div>
    </PublicPage>
  );
};

Component.displayName = "screens_Pdf";

export default Component;
