import * as React from "reactn";
import * as yup from "yup";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, IconButton } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import { QuizQuestion } from "src/services/quizzes";
import Option from "../Option";
import SelectGroup from "./Group";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  form: {
    width: "100%",
  },
  error: {
    margin: theme.spacing(2, 0),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    "& > button": {
      marginLeft: theme.spacing(1),
      // padding: theme.spacing(2, 4),
    },
  },
  inputs: {
    display: "flex",
  },
  confirmButton: {
    background: theme.palette.primary.main,
    color: "#FFF",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "#FFF",
    },
  },
  cancelButton: {
    background: theme.palette.grey[300],
  },
  groupContainer: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const schema = yup.object().shape({
  title: yup.string().required("Campo obrigatório"),
});

type Props = {
  onSuccess?: () => void;
  onClose?: () => void;
  onError?: (e: AppError | null) => void;
  question?: QuizQuestion;
  quizId: number;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [classError, setClassError] = React.useState<string>("");
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const ref = React.useRef<any>(null);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<Partial<QuizQuestion>>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      description: "",
      groupId: null,
    },
  });

  watch();

  React.useEffect(() => {
    if (props.question) {
      setValues(props.question);
    } else {
      if (ref?.current) {
        ref.current.focus();
      }
    }
  }, [props.question]);

  const setValues = async (question: QuizQuestion) => {
    watch();
    setValue("title", question.title);
    setValue("description", question.description);

    if (question.group) {
      setValue("groupId", question.group?.id);
    }
  };

  const clearState = () => {
    setError(null);
    setValue("title", "");
    setValue("description", "");
    setValue("groupId", null);
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  const onChange = (e: any): void => {
    setValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onConfirm = (): void => {
    // props.onClose()
  };

  const onCancel = async (): Promise<void> => {
    if (props.question !== undefined) {
      await services.questions.remove(props.quizId, props.question?.id);
      if (props.onSuccess) props.onSuccess();
    }
    if (props.onClose) props.onClose();
  };

  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    setClassError("");

    const values = getValues();

    if (
      props.question?.title === values.title &&
      props.question?.description === values.description &&
      props.question?.groupId === values.groupId
    ) {
      return;
    }

    let body: any = {
      title: values.title,
      description: values.description,
      groupId: values.groupId,
    };

    if (props.question?.id) {
      body.id = props.question?.id || 0;
    }

    try {
      await services.questions.save(props.quizId, body);
      clearState();
      if (props.onSuccess) props.onSuccess();
    } catch (e:any) {
      setClassError("animate__shakeX");
      setError(e as any);
      if (props.onError) props.onError(e);
    } finally {
      setLoading(false);
    }
  };

  const values = getValues();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.inputs}>
        <form
          className={`${classes.form} animate__animated ${classError}`}
          noValidate
          onBlur={props.question ? handleSubmit(onSubmit, onError) : () => {}}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <Grid item xs={12} className={classes.inputs}>
            <TextField
              inputRef={ref}
              variant="outlined"
              margin="normal"
              fullWidth
              id="title"
              label="Pergunta"
              name="title"
              onChange={onChange}
              error={hasInputError(error, errors, "title")}
              helperText={getInputError(error, errors, "title")}
              value={values.title}
              autoComplete="off"
            />

            <div className={classes.groupContainer}>
              <SelectGroup
                selected={values.groupId || null}
                onChange={(nextState: number) => {
                  setValue("groupId", nextState);
                }}
              />
            </div>

            {props.question === undefined && (
              <div className={classes.actions}>
                <IconButton
                  type="submit"
                  aria-label="ordenar"
                  className={classes.confirmButton}
                  onClick={onConfirm}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  aria-label="ordenar"
                  className={classes.cancelButton}
                  onClick={onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </Grid>

          {error !== null && (
            <Grid item xs={12}>
              <ShowErrors.Field error={error} field="server" />
            </Grid>
          )}
        </form>
      </Grid>

      {props.question && (
        <>
          <Grid item xs={12}>
            <Option
              quizId={props.quizId}
              questionId={props.question.id}
              options={props?.question?.options || []}
              onSuccess={() => {
                if (props.onSuccess) props.onSuccess();
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

Component.displayName = "Components_Quiz_Forms_Question_CreateOrUpdates";

export default Component;
