import AppError from "src/errors/AppError";

// type IInputError = {
//   [key: string]: {
//     message: string;
//   };
// };

export default (
  appError: AppError | null,
  inputError: any,
  field: string
): string | boolean => {
  if (inputError[field]) {
    return inputError[field]?.message;
  }

  const fromThrowError = appError ? appError.getErrorByField(field) : null;
  if (fromThrowError) {
    return fromThrowError.errors[0] || "";
  }

  return "";
};

export const hasInputError = (
  appError: AppError | null,
  inputError: any,
  field: string
): boolean => {
  if (inputError[field]) {
    return true;
  }

  const fromThrowError = appError ? appError.getErrorByField(field) : null;
  if (fromThrowError) {
    return true;
  }

  return false;
};
