import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import { IQuery } from "./PaginateDatasource/types";

export type IEvaluation = any;

/**
 * findALl
 */
export async function findAll(query: IQuery) {
  const queryString = qs.stringify(query, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/evaluations/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * findById
 */
export async function findById(id: number) {
  try {
    const response = await axios.get(`/evaluations/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * create
 */
export async function create(body: any) {
  try {
    const response = await axios.post(`/evaluations/`, body, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * update
 */
export async function update(body: any) {
  try {
    const response = await axios.put(`/evaluations/${body.id}`, body, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(id: number) {
  try {
    const response = await axios.delete(`/evaluations/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * findByPacientId
 */
export async function findAllByPacientId(id: number) {
  try {
    const response = await axios.get(`/evaluations/pacient/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
