import masker from "vanilla-masker";
import moment from "moment";
import replaceAll from "./replaceAll";

export function cellphone(phone: string): string {
  return masker.toPattern(phone, "(99) 99999 9999");
}

export function cpf(cpf: string): string {
  return masker.toPattern(cpf, "999.999.999-99");
}

export function cnpj(value: string): string {
  return masker.toPattern(value, "99.999.999/9999-99");
}

export function cpfToNumber(cpf: string): string {
  let rs = replaceAll(cpf, ".", "");
  return replaceAll(rs, "-", "");
}

export function birthdate(birthdate: string): string {
  return masker.toPattern(birthdate, "99.99.9999");
}

export function birthdateToDate(birthdate: string): any {
  if (!moment(birthdate, "DD.MM.YYYY").isValid()) {
    return "";
  }

  const date = moment(birthdate, "DD.MM.YYYY").format("YYYY-MM-DD");
  if (date == "Data inválida") {
    return "";
  }

  return date;
}

export function dateToBirthdate(birthdate: string): any {
  if (!moment(birthdate, "YYYY-MM-DD").isValid()) {
    return "";
  }

  const date = moment(birthdate, "YYYY-MM-DD").format("DD.MM.YYYY");
  if (date == "Data inválida") {
    return "";
  }

  return date;
}

export function toMoney(
  value: number | string,
  min?: number,
  zeroCents?: boolean
): string {
  if (min && Number(value) < min) {
    value = 1;
  }

  value = value.toString();
  if (zeroCents) {
    const s = value.split(".");
    if (s.length > 1) {
      if (s[s.length - 1].length == 1) {
        zeroCents = false;
        value += "0";
      } else if (s[s.length - 1].length == 2) {
        zeroCents = false;
      }
    }
  }

  value = masker.toMoney(value, {
    // Decimal precision -> "90"
    precision: 2,
    // Decimal separator -> ",90"
    separator: ",",
    // Number delimiter -> "12.345.678"
    delimiter: ".",
    // Money unit -> "R$ 12.345.678,90"
    // unit: "R$",
    // Money unit -> "12.345.678,90 R$"
    // suffixUnit: "R$",
    // Force type only number instead decimal,
    // masking decimals with ",00"
    // Zero cents -> "R$ 1.234.567.890,00"
    zeroCents,
  });

  return value;
}

export function toNumber(value: string): number {
  return Number(masker.toNumber(value));
}

export function moneyToNumber(value: string): string {
  value = replaceAll(value, ".", "");
  return replaceAll(value, ",", ".");
}
