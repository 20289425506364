import React from "reactn";
import * as Table from "_components/Table";
import PaginateDatasource from "_services/PaginateDatasource";
import * as services from "_services";
import Filter from "./Filter";

const datasource = new PaginateDatasource<{
    id: number;
    name: string;
}>(services.doctors);

const STATUS: any = {
    ENABLED: {
        color: "#8dba32",
        label: "Aprovado",
    },
    PENDING: {
        color: "#ee9229",
        label: "Pendente",
    },
    DISABLED: {
        color: "#e73b2a",
        label: "Recusado",
    },
    MISSING_DOCUMENTS: {
        color: "#e73b2a",
        label: "Faltando Documentos",
    },
};

const PLAN_STATUS: any = {
    VIP: {
        color: "#4A0B61",
        label: "Vip",
    },
    TRIAL: {
        color: "#4A0B61",
        label: "Trial",
    },
    ENABLED: {
        color: "#4A0B61",
        label: "Ativo",
    },
    DISABLED: {
        color: "#8dba32",
        label: "Desabilitado",
    },
    SUSPENDED: {
        color: "#ee9229",
        label: "Suspenso",
    },
    PAYMENT_FAILED: {
        color: "#e73b2a",
        label: "Falha no pagamento",
    },
};

const cols = [
    {
        id: "id",
        label: "ID",
        sort: true,
    },
    {
        id: "name",
        label: "Nome",
        sort: true,
    },
    {
        id: "email",
        label: "E-mail",
        sort: true,
        sortby: "auth.email",
    },
    {
        id: "status",
        label: "Status do perfil",
        sort: true,
        sortby: "status",
        render: (doctor: any) => {
            let status: any = STATUS[doctor.status || ""] as any;
            if (
                !doctor.media ||
                !doctor.media.EDUCATION ||
                !doctor.media.EDUCATION.length
            ) {
                status = STATUS.MISSING_DOCUMENTS;
            }
            if (!status) return null;
            return (
                <div
                    style={{
                        background: status.color,
                        padding: "3px 10px",
                        borderRadius: "100px",
                        display: "inline-block",
                        fontSize: "12px",
                        color: "#FFF",
                    }}
                >
                    {status.label}
                </div>
            );
        },
    },
    {
        id: "plan",
        label: "Plano",
        render: (doctor: any) => {
            let plan = "--";
            let planStatus = "";

            if (doctor.planStatus === "VIP") {
                plan = "VIP";
            } else if (doctor.plan?.name) {
                plan = doctor.plan?.name + " " + doctor.plan?.period;
            }

            if (doctor.planStatus !== "VIP") {
                const status: any = PLAN_STATUS[doctor.planStatus || ""] as any;
                if (status) {
                    planStatus = status.label;
                }
            }

            return (
                <p>
                    <strong>{plan}</strong> <br />
                    {planStatus && (
                        <small
                            style={{
                                fontSize: "12px",
                            }}
                        >
                            {planStatus}
                        </small>
                    )}
                </p>
            );
        },
    },
];

type Props = {};

const Component: React.FC<Props> = () => {
    return (
        <Table.Service
            filter={(props: any) => {
                return <Filter {...props} />;
            }}
            cols={cols}
            path="/profissionais"
            datasource={datasource}
            hideView={true}
            onRemovedText="Profissional deletado com sucesso"
            onRemovedErrorText="Erro ao deletar o profissional"
            queryDefault={{
                order: "ASC",
                orderBy: "name",
            }}
        />
    );
};

Component.displayName = "Components_Doctor_List";

export default Component;
