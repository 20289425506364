import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import config from "_config";

/**
 * findALl
 */
export async function find(address: string) {
  try {
    const response = await axios.get(`/address/?address=${address}`);
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * get address
 */
export async function get(placeId: string) {
  try {
    const response = await axios.get(`/address/${placeId}`);
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
