import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

import getCroppedImg from "./cropImage";
import { useStyles } from "./styles";

type Props = {
  image: string | null;
  onCropped: (value: any) => void;
  onCancel: () => void;
  width: number,
  height: number,
  // onSuccess?: () => void;
  // onError?: () => void;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const image = props.image || "";

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCroppedImage = useCallback(async () => {
    try {
      const _croppedImage = await getCroppedImg(image, croppedAreaPixels);
      props.onCropped(_croppedImage);
    } catch (e: any) {
      console.error(e);
    }
  }, [croppedAreaPixels, props.onCropped]);

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <div className={classes.cropContainer}>
      <div className={classes.cropContent}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          cropSize={{
            width: props.width,
            height: props.height,
          }}
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e: any, zoom: any) => setZoom(zoom)}
          />
        </div>

        <div className={classes.actions}>
          <Button
            size="small"
            variant="contained"
            startIcon={<CloseIcon />}
            className={classes.cancelBtn}
            onClick={onCancel}
          ></Button>

          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<DoneIcon />}
            className={classes.confirmBtn}
            onClick={onCroppedImage}
          ></Button>
        </div>
      </div>
    </div>
  );
};

Component.displayName = "Component_Media_Avatar_Crop";

export default Component;
