import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import { IQuery } from "./PaginateDatasource/types";

export type IPacientProfile = {
  pacient: {
    id: number;
    name: string;
    birthdate: Date;
    gender: string;
  };
  evaluation: {
    id: number;
    message: string;
    score: number;
    date: Date;
    specialties: Array<{
      id: number;
      name: string;
    }>;
  };
  quiz: {
    id: number;
    name: string;
    slug: string;
    description: string;
  };
};

export type IPacient = {
  id: number;
  authId: number;
  birthdate: Date;
  cellphone: string | null;
  city: string;
  cep: string | null;
  country: string;
  email: string;
  name: string;
  gender: string;
  lat: string;
  lng: string;
  state: string;
  status: string;
  createdAt: Date;
  updatedAt: Date | null;
  profile: { [key: string]: IPacientProfile | null };
};

/**
 * findALl
 */
export async function findAll(query: IQuery) {
  const queryString = qs.stringify(query, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/pacients/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * findById
 */
export async function findById(id: number) {
  try {
    const response = await axios.get(`/pacients/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * create
 */
export async function create(body: any) {
  try {
    const response = await axios.post(`/pacients/`, body, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * update
 */
export async function update(body: any) {
  try {
    const response = await axios.put(`/pacients/${body.id}`, body, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(id: number) {
  try {
    const response = await axios.delete(`/pacients/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * export
 */
export async function exportData() {
  try {
    const response = await axios.get(`/pacients/export`, {
      useToken: true,
      responseType: "blob",
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
