import React from "react";
import { Link, RouteProps, useParams } from "react-router-dom";
import { Button, Chip, CircularProgress, Grid, Paper } from "@material-ui/core";
import AppBar from "_components/Layout/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import { PrivatePage } from "_components/Layout";
import * as services from "_services";
import { IEvaluation } from "_services/evaluations";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  loading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "80vh",
  },
  paper: {
    padding: theme.spacing(3),
  },
  score: {
    fontSize: 42,
    textAlign: "center",
    margin: 0,
  },
  title: {
    marginTop: 0,
  },
  tag: {
    padding: "4px 8px",
    borderRadius: "100px",
    color: "#FFF",
  },
  scoreContainer: {
    display: "flex",
  },
  scoreData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    textAlign: "center",
  },
  button: {
    fontSize: 12,
  },
  block: {
    margin: "20px 0",
  },
  specialty: {
    margin: "4px  4px",
  },
  result: {
    marginLeft: "20px",
  },
}));

const gender = {
  HOMEM_TRANSGENERO: "Homem transgênero",
  HOMEM_CISGENERO: "Homem cisgênero",
  MULHER_TRANSGENERO: "Mulher transgênero",
  MULHER_CISGENERO: "Mulher cisgênero",
  GENERO_NAO_BINARIO: "Gênero não binário",
} as any;

const colors = {
  ORIENTACAO: "#8dba32",
  OTIMO: "#f9ca21",
  BOM: "#ee9229",
  COMPROMETIDO: "#ea592a",
  CRITICO: "#e73b2a",
} as any;

const Component: React.FC<RouteProps> = () => {
  const [evaluation, setEvaluation] = React.useState<IEvaluation | null>(null);
  const classes = useStyles();
  const params = useParams<{ id: string }>();

  React.useEffect(() => {
    services.evaluations.findById(Number(params.id)).then((res) => {
      setEvaluation(res);
    });
  }, []);

  if (evaluation === null) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "PROGNÓSTICOS",
                to: "/prognosticos",
              },
              {
                label: "DETALHES",
              },
            ]}
          />
        }
      ></AppBar>

      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={1} className={classes.paper}>
            <Grid container>
              {evaluation.quiz.slug !== "HABITOS" && (
                <Grid
                  className={`${classes.block} ${classes.scoreContainer}`}
                  item
                  xs={12}
                  md={3}
                  lg={2}
                >
                  <div className={classes.scoreData}>
                    <h3 className={`${classes.title} ${classes.score}`}>
                      {evaluation.score}
                    </h3>
                    <span
                      className={classes.tag}
                      style={{ background: colors[evaluation.tag] }}
                    >
                      {evaluation.tagDescription}
                    </span>
                  </div>
                </Grid>
              )}

              <Grid item xs={12} md={3} lg={2} className={classes.block}>
                <h3 className={classes.title}>{evaluation.pacient.name}</h3>
                {gender[evaluation.pacient.gender]}
                {evaluation?.pacient?.birthdate ? (
                  <>
                    <br />
                    {moment().diff(evaluation.pacient.birthdate, "years")} anos
                  </>
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={12} md={3} lg={3} className={classes.block}>
                <h3 className={classes.title}>{evaluation.quiz.name}</h3>
                {moment(evaluation.date).format("DD-MM-YYYY")}
                <br />
                {moment(evaluation.date).fromNow()}
              </Grid>

              <Grid item xs={12} md={3} lg={5} className={classes.block}>
                <div className={classes.buttonContainer}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={`/pacientes/${evaluation.pacient.id}`}
                  >
                    VER HISTÓRICO <br />
                    COMPLETO DO CLIENTE
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={7} style={{ marginTop: 30 }}>
          <Paper elevation={1} className={classes.paper}>
            <h3 className={classes.title}>Respostas</h3>
            <Grid container>
              {evaluation.answers.map((answer: any) => (
                <Grid item xs={12} style={{ marginTop: 15 }}>
                  <strong>{answer.question.title}</strong>
                  <br />
                  <span>
                    {answer.description} ({answer.strong})
                  </span>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
        {evaluation.quiz.slug !== "HABITOS" && (
          <Grid item xs={12} sm={5} style={{ marginTop: 30 }}>
            <Paper
              elevation={1}
              className={`${classes.paper} ${classes.result}`}
            >
              <h3 className={classes.title}>Feedback de Resultado</h3>
              <p>{evaluation.message}</p>
              <div>
                {evaluation.specialties?.map((specialty: any) => (
                  <Chip label={specialty.name} className={classes.specialty} />
                ))}
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    </PrivatePage>
  );
};

Component.displayName = "screens_Evaluation_Detail";

export default Component;
