import axios from "axios";
import { HandlerError } from "_errors";
import config from "_config";

/**
 * login
 * @param {Object}
 */
export async function login(data: any) {
  try {
    const response = await axios.post("/auth/login", data);
    return response.data;
  } catch (e:any) {
    if (e.response?.data) {
      if (e.response.data.statusCode === 401) {
        e.response.data.errors[0]["errors"][0] = "Dados incorretos";
      }
    }
    throw new HandlerError(e);
  }
}

/**
 * getMe
 * @param {Object}
 */
export async function getMe() {
  try {
    const response = await axios.get("/auth/me", {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * refreshToken
 * @param {Object}

export async function refreshToken(token) {
  try {
    const response = await axios.post<ILoginResponse>("/auth/token", {
      grant_type: "refresh_token",
      refresh_token: token,
    });
    return response.data;
  } catch (e:any) {
    throw e;
    // throw new HandlerError(e)
  }
}
*/

/**
 * forgotPassword
 * @param {String} email
 */
export async function forgotPassword(email: string) {
  try {
    const response = await axios.post(`/auth/forgot-password`, {
      email,
      redirectUrl: config.forgotPasswordRedirectUrl,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * validateEmail
 * @param {String} email
 */
export async function validateEmail(token: string) {
  try {
    const response = await axios.post(`/auth/validate-email`, {
      token,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * updatePassword
 * @param {String} email
 */
export async function updatePassword({ token, newPassword }: any) {
  try {
    const response = await axios.post(`/auth/update-password`, {
      token,
      newPassword,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * update
 */
export async function update({ id, email, password, status }: any) {
  try {
    const response = await axios.put(
      `/auth/${id}`,
      {
        email,
        password,
        status,
      },
      {
        useToken: true,
      }
    );
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * removeAccount
 * @param {String} email
 */
export async function removeAccount({ token, password }: any) {
  try {
    const response = await axios.post(`/auth/remove-account`, {
      token,
      password,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
