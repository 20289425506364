import React from "react";
import { RouteProps, Link } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import moment from "moment";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Doctor from "_components/Doctor";
import { PrivatePage } from "_components/Layout";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FileDownload from "@material-ui/icons/GetApp";
import * as services from "_services";

const Component: React.FC<RouteProps> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const onExport = async () => {
    setLoading(true);
    try {
      const res = await services.doctors.exportData();
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `terapeutas_${moment().format("DD-MM-YYYY_hh-mm")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e:any) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "PROFISSIONAIS",
              },
            ]}
          />
        }
        Right={
          <>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<FileDownload />}
              onClick={onExport}
              disabled={loading}
            >
              EXPORTAR
            </Button>

            <Button
              style={{ marginLeft: 10 }}
              size="small"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              component={Link}
              to="/profissionais/cadastrar"
            >
              CADASTRAR
            </Button>
          </>
        }
      ></AppBar>
      <Doctor.List />
    </PrivatePage>
  );
};

Component.displayName = "screens_Doctor_List";

export default Component;
