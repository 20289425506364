import React from "reactn";
import { RouteProps } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import * as Auth from "_components/Auth";
import { PublicPage } from "_components/Layout";
import { success as successReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const params = useParams<{ token: string }>();
  const success = React.useDispatch<any>(successReducer);

  const onSuccess = () => {
    success("Dados atualizados com sucesso");
    history.push("/dashboard");
  };

  return (
    <PublicPage>
      <Auth.UpdatePassword token={params.token} onSuccess={onSuccess} />
    </PublicPage>
  );
};

Component.displayName = "screens_Auth_UpdatePassword";

export default Component;
