import ReactDOM from "react-dom";
import React, { useEffect } from "reactn";
const modal = document.getElementById("alert");

const el = document.createElement("div");

const ModalPortal = (props: any) => {
  useEffect(() => {
    if (modal) {
      modal.appendChild(el);
    }
    return () => {
      if (modal) {
        modal.removeChild(el);
      }
    };
  }, []);

  return ReactDOM.createPortal(props.children, el);
};

export default ModalPortal;
