import React from "react";
import moment from "moment";
import { RouteProps, Link } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import { PrivatePage } from "_components/Layout";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FileDownload from "@material-ui/icons/GetApp";
import * as Term from "_components/Term";
import * as services from "_services";

const Component: React.FC<RouteProps> = () => {
  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "DOCUMENTOS",
              },
            ]}
          />
        }
        Right={
          <>
            <Button
              style={{ marginLeft: 10 }}
              size="small"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              component={Link}
              to="/documentos/cadastrar"
            >
              CADASTRAR
            </Button>
          </>
        }
      ></AppBar>
      <Term.List />
    </PrivatePage>
  );
};

Component.displayName = "screens_Term_List";

export default Component;
