import React from "reactn";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {},
    button: {
      backgroundColor: "#FFF",
    },
    inputs: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1, 0),
    },
  })
);

type FilterProps = {
  applyFilter: (query: any) => void;
  query: any;
};

const Filter: React.FC<FilterProps> = (props) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState<string>("");
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    if (props.query?.search) {
      setSearch(props.query.search);
    }
  }, [props.query]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.applyFilter({
      search,
    });
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <div className={classes.inputs}>
        <TextField
          variant="standard"
          margin="none"
          inputMode="search"
          label="Pesquisar"
          placeholder="Nome"
          onChange={onSearch}
          value={search}
          autoComplete="new-password"
        />
        <Button
          type="submit"
          variant="contained"
          color="default"
          className={classes.button}
        >
          Aplicar filtro
        </Button>
      </div>
    </form>
  );
};

export default Filter;
