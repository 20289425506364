import * as React from "reactn";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import { Quiz } from "src/services/quizzes";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  error: {
    margin: theme.spacing(2, 0),
  },
}));

const schema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
});

type Props = {
  onSuccess?: () => void;
  onError?: (e: AppError | null) => void;
  quiz: Quiz | null;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [classError, setClassError] = React.useState<string>("");
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<Partial<Quiz>>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      slug: "",
      description: "",
    },
  });

  watch();

  React.useEffect(() => {
    if (props.quiz) {
      setValues(props.quiz);
    }
  }, [props.quiz]);

  const setValues = async (quiz: Quiz) => {
    watch();
    if (quiz) {
      setValue("name", quiz.name);
      setValue("description", quiz.description);
    }
  };

  const clearState = () => {
    setError(null);
    setValue("name", "");
    setValue("description", "");
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  const onChange = (e: any): void => {
    setValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    setClassError("");

    const values = getValues();

    if (
      props.quiz?.name === values.name &&
      props.quiz?.description === values.description
    ) {
      return;
    }

    let body: any = {
      id: props.quiz?.id || 0,
      name: values.name,
      description: values.description,
    };

    try {
      await services.quizzes.save(body);
      clearState();
      if (props.onSuccess) props.onSuccess();
    } catch (e:any) {
      setClassError("animate__shakeX");
      setError(e as any);
      if (props.onError) props.onError(e);
    } finally {
      setLoading(false);
    }
  };

  const values = getValues();

  return (
    <form
      className={`${classes.form} animate__animated ${classError}`}
      noValidate
      onBlur={handleSubmit(onSubmit, onError)}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label="Título"
            name="name"
            onChange={onChange}
            error={hasInputError(error, errors, "name")}
            helperText={getInputError(error, errors, "name")}
            value={values.name}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            label="Subtítulo"
            name="description"
            onChange={onChange}
            error={hasInputError(error, errors, "description")}
            helperText={getInputError(error, errors, "description")}
            value={values.description}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12}>
          <ShowErrors.Field error={error} field="server" />
        </Grid>
      </Grid>
    </form>
  );
};

Component.displayName = "Components_Admn_Forms";

export default Component;
