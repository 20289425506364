import React from "reactn";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import * as services from "_services";
import { Quiz } from "src/services/quizzes";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    quiz: {
      padding: theme.spacing(1),
    },
    quizName: {
      textTransform: "uppercase",
      fontSize: 14,
      textDecoration: "none",
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
    quizDescription: {
      flex: 1,
      height: 80,
      margin: theme.spacing(2, 0),
      overflow: "hidden",
    },
    quizbtn: {
      fontSize: 10,
    },
  })
);

type Props = {};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [quizzes, setQuizzes] = React.useState<Array<Quiz>>([]);

  React.useEffect(() => {
    services.quizzes.findAll().then((response: Array<Quiz>) => {
      setQuizzes(response);
    });
  }, []);

  return (
    <Grid container xs={12} spacing={3}>
      {quizzes.map((quiz: Quiz) => {
        return (
          <Grid item xs={12} sm={4} md={3} key={quiz.id}>
            <Paper className={classes.quiz} elevation={1}>
              <Link
                className={classes.quizName}
                to={`/mapeamentos/editar/${quiz.id}`}
              >
                {quiz.name}
              </Link>
              <p className={classes.quizDescription}>{quiz.description}</p>
              <Button
                className={classes.quizbtn}
                to={`/mapeamentos/editar/${quiz.id}`}
                component={Link}
                variant="outlined"
                color={"primary"}
                size="small"
              >
                EDITAR
              </Button>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

Component.displayName = "Components_Specialty_List";

export default Component;
