import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as LinkRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  font: {
    fontSize: 12,
  },
}));

type IBreadcrumb = {
  onClick?: (e: React.MouseEventHandler<HTMLAnchorElement>) => void;
  to?: string;
  label: string;
};

type Props = {
  breadcrumbs: Array<IBreadcrumb>;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.breadcrumbs.map((breadcrumb, index) => {
        if (breadcrumb.to) {
          return (
            <Link
              className={classes.font}
              color="primary"
              to={breadcrumb.to}
              component={LinkRouter}
              key={index}
              onClick={(e: any) => {
                if (breadcrumb.onClick) {
                  breadcrumb.onClick(e);
                }
              }}
            >
              {breadcrumb.label}
            </Link>
          );
        } else {
          return (
            <Typography
              className={classes.font}
              color="textPrimary"
              key={index}
              onClick={(e: any) => {
                if (breadcrumb.onClick) {
                  breadcrumb.onClick(e);
                }
              }}
            >
              {breadcrumb.label}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
};

Component.displayName = "components_Layout_AppBar_Breadcrumbs";

export default Component;
