import React from "reactn";
import { useHistory, Link as LinkRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import links from "./links";
import logo from "_assets/logo.svg";
import { logout as logoutReducer } from "_state/auth";
// import { version } from "../../../package.json";

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    toolbar: {
        ...theme.mixins.toolbar,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#FFF",
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        width: "100%",
        minHeight: "100vh",
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(10),
        },
    },
    closeMenuButton: {
        marginRight: "auto",
        marginLeft: 0,
    },
    icon: {
        marginRight: 10,
        position: "relative",
        top: 3,
    },
    links: {
        "& a:hover": {
            transition: "background .2s",
        },
    },
    link: {
        textTransform: "uppercase",
        "&  span": {
            fontSize: 12,
        },
    },
    logo: {
        width: "130px",
        float: "right",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            width: 130,
            margin: theme.spacing(2),
        },
        "&>img": {
            width: "100%",
        },
    },
    active: {
        background: theme.palette.primary.main,
        color: "#FFF",
        "&:hover": {
            background: theme.palette.primary.main,
            color: "#FFF",
        },
    },
}));

const Component = (props: any) => {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const logout = React.useDispatch(logoutReducer);
    const history = useHistory();

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    }

    const onLogout = async () => {
        await logout();
        history.push("/");
    };

    const drawer = (
        <div>
            <List className={classes.links}>
                {links.map((link: any, index: number) => (
                    <ListItem
                        component={NavLink}
                        button
                        key={index}
                        to={link.to || `${index}`}
                        activeClassName={classes.active}
                        onClick={(e: any) => {
                            if (link.logout) {
                                e.preventDefault();
                                onLogout();
                            } else if (link.onClick) {
                                e.preventDefault();
                                link.onClick(e);
                            }
                        }}
                    >
                        <div className={classes.icon}>{link.icon}</div>
                        <ListItemText
                            primary={link.label}
                            className={classes.link}
                        />
                    </ListItem>
                ))}
                <hr />
                <div style={{ paddingLeft: 16 }}>
                    <small>Versão: {"1.1.59"}</small>{" "}
                </div>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Hidden smUp implementation="css">
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            color="primary"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <LinkRouter to="/" className={classes.logo}>
                            <img src={logo} />
                        </LinkRouter>
                    </Toolbar>
                </AppBar>
            </Hidden>

            <nav className={classes.drawer}>
                <Hidden smDown implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <IconButton
                            onClick={handleDrawerToggle}
                            className={classes.closeMenuButton}
                        >
                            <CloseIcon />
                        </IconButton>
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <LinkRouter to="/" className={classes.logo}>
                            <img src={logo} />
                        </LinkRouter>
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <div className={classes.content}>{props.children}</div>
        </div>
    );
};
Component.displayName = "components_Layout_PrivatePage";

export default Component;
