import { IQuery, IService, IResponse } from "./types";

export default class PaginateDatasource<T> {
  service: IService<T>;
  constructor(service: IService<T>) {
    this.service = service;
  }

  get(query: IQuery): Promise<IResponse<T>> {
    return this.service.findAll(query);
  }

  remove(id: number): Promise<T> {
    return this.service.remove(id);
  }
}
