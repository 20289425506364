import React from "reactn";
import OriginalReact, { ChangeEvent, MutableRefObject, useRef } from "react";
import { Button, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { error as errorReducer, success as successReducer } from "_state/alert";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.grey[100],
    borderRadius: 4,
    margin: theme.spacing(2, 0),
  },
  input: {
    display: "none",
  },
}));
export type INewStateOnSelectedFile = { preview: string; file: File };
type Props = {
  onChange: (newState: INewStateOnSelectedFile) => void;
  // onError?: () => void;
};
const Component: OriginalReact.FC<Props> = (props) => {
  const classes = useStyles();
  const error = React.useDispatch<any>(errorReducer);
  const inputFileRef = useRef<any>(null);
  
  const onError = (txt: string) => {
    error("Formato de imagem inválido.");
  };

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const files = e.target.files || [];

    if (files.length > 0) {
      const file = files[0];
      if (/\.(jpe?g|png)$/i.test(file.name)) {
        const reader = new FileReader();
        reader.onloadend = function () {
          props.onChange({
            preview: reader.result as string,
            file,
          });
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      } else {        
        onError("Formato de imagem inválido.")
        inputFileRef.current.value = ''
      }
    }
  };
  return (
    <div className={classes.root}>
      <input
        accept=".jpg, .jpeg, .png"        
        className={classes.input}
        id="icon-button-file"
        type="file"
        onChange={onSelectFile}
        ref={inputFileRef}
      />
      <label htmlFor="icon-button-file">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <PhotoCamera />
        </IconButton>
        <span>SELECIONAR</span>
      </label>
    </div>
  );
};
Component.displayName = "Component_Media_Avatar_SelectFile";
export default Component;