interface IError {
  field: string;
  errors: Array<string>;
}

interface IErrors {
  statusCode: number;
  errors: Array<IError>;
}

export default class ApiError extends Error {
  private _statusCode: number = 0;
  private _errors: Array<IError> = [];

  constructor() {
    super();
  }

  setStatusCode(statusCode: number): void {
    this._statusCode = statusCode;
  }

  setErrors(errors: any) {
    let apiErrors: any = errors;
    if (!errors) {
      apiErrors = "Erro crítico";
    }

    apiErrors = Array.isArray(errors) ? errors : [apiErrors];
    this._errors = apiErrors.map((error: any) => {
      if (typeof error === "string") {
        return {
          field: "server",
          errors: [error],
        };
      } else {
        return {
          field: error.field,
          errors: error.errors,
        };
      }
    });
  }

  getErrorByField(property: string): IError | null {
    return this._errors.find((e: IError) => e.field === property) || null;
  }

  getFields(): Array<string> {
    return this._errors.map((e: IError) => e.field);
  }

  toJSON(): Array<{
    key: string;
  }> {
    return this._errors.reduce((acc: any, e: IError) => {
      acc[e.field] = e.errors;
      return acc;
    }, {});
  }

  toArray(): Array<string> {
    return this._errors.reduce((acc: Array<string>, cur: IError): Array<
      string
    > => {
      // @ts-ignore
      return [...acc, ...cur.errors];
    }, []);
  }

  getErrors(): Array<IError> {
    return this._errors;
  }

  getStatusCode(): number {
    return this._statusCode;
  }
}
