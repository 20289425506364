import React from "reactn";
import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Paper } from "@material-ui/core";
import logo from "_assets/logo.svg";
import * as ShowErrors from "_UI/ShowErrors";
import { AppError } from "_errors";
import getInputError from "_helpers/getInputError";
import * as service from "_services";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#4A0B61",
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "80vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    maxWidth: 280,
  },
  logo: {
    margin: theme.spacing(1),
    width: 190,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  center: {
    textAlign: "center",
  },
}));

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .test(function (value: any, context?: any) {
      if (value && value.length < 6) {
        return context.createError({
          message: "Deve ter no mínimo 6 caracteres",
        });
      }
      return true;
    })
    .required("Campo obrigatório"),

  repeatPassword: yup
    .string()
    .required("Campo obrigatório")
    .oneOf([yup.ref("newPassword"), null], "As senhas estão diferentes"),
});

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
  token: string;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [classError, setClassError] = React.useState<string>("");
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    setError(null);
    setLoading(true);
    setClassError("");
    service.auth
      .updatePassword({
        ...data,
        token: props.token,
      })
      .then(() => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      })
      .catch((e: any) => {
        setError(e as any);
        setClassError("animate__shakeX");
        if (props.onError) {
          props.onError();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  return (
    <div className={classes.wrapper}>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Paper elevation={4} className={classes.paper}>
          <img src={logo} className={classes.logo} />
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className={classes.form}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Nova senha"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.hasOwnProperty("newPassword") || false}
              helperText={getInputError(error, errors, "newPassword")}
              {...register("newPassword")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Repetir senha"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.hasOwnProperty("repeatPassword") || false}
              helperText={getInputError(error, errors, "repeatPassword")}
              {...register("repeatPassword")}
            />

            <ShowErrors.Field error={error} field="server" />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={`${classes.submit} animate__animated ${classError}`}
              disabled={loading}
            >
              {loading ? "ATUALIZANDO..." : "ATUALIZAR"}
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

Component.displayName = "Components_Auth_UpdatePassword";

export default Component;
