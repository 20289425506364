import * as React from "reactn";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import Address from "_components/Address";
import * as mask from "_helpers/masks";
import moment from "moment";
import { Link } from "react-router-dom";
import Phone from "_UI/Phone";

const useStyles = makeStyles((theme) => ({
	container: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: theme.spacing(1.5, 5),
	},
	center: {
		textAlign: "center",
	},
	error: {
		margin: theme.spacing(2, 0),
	},
	address: {
		margin: theme.spacing(2, 0),
	},
	noSpacing: {
		marginTop: theme.spacing(0) + " !important",
		marginBottom: theme.spacing(0) + " !important",
		paddingTop: theme.spacing(0) + " !important",
		paddingBottom: theme.spacing(0) + " !important",
	},
	group: {
		marginBottom: theme.spacing(3),
	},
	groupTitle: {
		marginTop: theme.spacing(0) + " !important",
		marginBottom: theme.spacing(0) + " !important",
		fontSize: "12px",
		textTransform: "uppercase",
	},
}));

const schema = yup.object().shape({
	name: yup.string().required("Campo obrigatório"),
	email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
	cep: yup.string().required("Campo obrigatório"),
	city: yup.string().required("Campo obrigatório"),
	password: yup
		.string()
		.test("password", "Campo obrigatório", function (value: any) {
			if (!value && !this.parent.isUpdate) {
				return false;
			}
			return true;
		}),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "As senhas estão diferentes"),
	fullAddress: yup.string().required("Campo obrigatório"),
	birthdate: yup
		.string()
		.test("birthdate", "Data inválida", function (value: any) {
			if (!value) {
				return true;
			}

			if (!value || value?.length < 10) {
				return false;
			}

			const diff = moment(value, "DD.MM.YYYY").diff(new Date(), "years");
			if (diff > -1 || diff < -120 || !moment(value, "DD.MM.YYYY").isValid()) {
				return false;
			}

			const years = moment().diff(value, "years", false);
			if (years < 18) {
				return false;
			}

			return true;
		}),
});

type Props = {
	onSuccess?: () => void;
	onError?: () => void;
	id?: number;
};

const Component: React.FC<Props> = (props) => {
	const [authId, setAuthId] = React.useState<number | null>(null);
	const [pacientId, setAdminId] = React.useState<number | null>(null);
	const [classError, setClassError] = React.useState<string>("");
	const classes = useStyles();
	const [error, setError] = React.useState<AppError | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [numberPhoneChange, setNumberPhoneChange] = React.useState<string>("");
	const [DDIPhoneChange, setDDIPhoneChange] = React.useState<string>("");

	const {
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			isUpdate: false,
			name: "",
			email: "",
			status: "",
			password: "",
			confirmPassword: "",
			cellphone: "",
			gender: "",
			birthdate: "",
			lat: "",
			lng: "",
			city: "",
			cep: "",
			state: "",
			country: "",
			fullAddress: "",
		},
	});

	watch();

	const values = getValues();

	React.useEffect(() => {
		if (!values.cellphone) {
			return;
		}

		if (values.cellphone.length >= 5) {
			setDDIPhoneChange(
				`${values.cellphone.replace(/[^0-9]/g, "").slice(0, 2)}`
			);
		}
		if (values.cellphone.length >= 3) {
			setNumberPhoneChange(
				values.cellphone
					.replace(/[^0-9]/g, "")
					.slice(2, values.cellphone.length)
			);
		}
	}, [values.cellphone]);

	const setValues = async (id: number) => {
		watch();
		setValue("isUpdate", id ? true : false);
		const pacient = await services.pacients.findById(id);
		setAdminId(pacient.id);
		setAuthId(pacient.authId);
		setValue("name", pacient.name);
		setValue("email", pacient.email);
		setValue("status", pacient.status);
		if (pacient.birthdate) {
			setValue("birthdate", mask.dateToBirthdate(pacient.birthdate));
		}
		setValue("cellphone", pacient.cellphone);
		setValue("gender", pacient.gender);
		setValue("city", pacient.city);
		setValue("cep", pacient.cep);
		setValue("state", pacient.state);
		setValue("country", pacient.country);
		setValue("lat", pacient.lat);
		setValue("lng", pacient.lng);
		if (pacient.city && pacient.state && pacient.country) {
			setValue(
				"fullAddress",
				`${pacient.city}, ${pacient.state}, ${pacient.country}`
			);
		}
	};

	const clearState = () => {
		setError(null);
		setValue("email", "");
		setValue("password", "");
		setValue("confirmPassword", "");
		setValue("name", "");
		setValue("birthdate", "");
		setValue("cellphone", "");
		setValue("lat", "");
		setValue("lng", "");
		setValue("city", "");
		setValue("cep", "");
		setValue("state", "");
		setValue("country", "");
		setValue("fullAddress", "");
		setValue("status", "");
	};

	React.useEffect(() => {
		if (props.id) {
			setValues(props.id);
		}
	}, []);

	const onError = () => {
		setClassError("");
		setTimeout(() => {
			setClassError("animate__shakeX");
		}, 0);
	};

	const onChange = (e: any): void => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		if (name == "birthdate") {
			setValue(name, mask.birthdate(value));
		} else {
			setValue(name, value);
		}
	};

	const onChangeGender = (e: React.ChangeEvent<{ value: unknown }>) => {
		const value = e.target.value as string;
		setValue("gender", value);
	};

	const setAddress = (newState: any) => {
		if (newState) {
			setValue("lat", newState.location.lat);
			setValue("lng", newState.location.lng);
			setValue("city", newState.city);
			setValue("state", newState.state);
			setValue("country", newState.country);
			setValue("fullAddress", newState.fullAddress);
		} else {
			setValue("lat", "");
			setValue("lng", "");
			setValue("city", "");
			setValue("state", "");
			setValue("country", "");
			setValue("fullAddress", "");
		}
	};

	const onSubmit = () => {
		setError(null);
		setLoading(true);
		setClassError("");

		const values = getValues();
		const requests = [];
		let pacientData: any = {
			name: values.name,
			birthdate: mask.birthdateToDate(values.birthdate),
			gender: values.gender,
			cellphone: `${DDIPhoneChange}${numberPhoneChange}`,
			lat: values.lat.toString(),
			lng: values.lng.toString(),
			city: values.city,
			cep: values.cep,
			state: values.state,
			country: values.country,
		};

		pacientData = Object.keys(pacientData).reduce((acc: any, prop: any) => {
			if (pacientData[prop]) {
				acc[prop] = pacientData[prop];
			}
			return acc;
		}, {});

		if (props.id) {
			const dataAuth: any = {
				id: authId,
				email: values.email,
				status: values.status,
			};

			if (values.password) {
				dataAuth.password = values.password;
			}
			requests.push(services.auth.update(dataAuth));

			requests.push(
				services.pacients.update({
					id: pacientId,
					...pacientData,
				})
			);
		} else {
			requests.push(
				services.pacients.create({
					email: values.email,
					password: values.password,
					...pacientData,
				})
			);
		}

		Promise.all(requests)
			.then(() => {
				if (props.onSuccess) {
					props.onSuccess();
				} else {
					clearState();
				}
			})
			.catch((e) => {
				setClassError("animate__shakeX");
				setError(e as any);
				if (props.onError) props.onError();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className={classes.container}>
			<Paper elevation={4} className={classes.paper}>
				<form
					onSubmit={handleSubmit(onSubmit, onError)}
					className={classes.form}
					noValidate
				>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12} lg={12} xl={8}>
							<Grid container spacing={3} className={classes.group}>
								<Grid item xs={12} className={classes.noSpacing}>
									<h2 className={classes.groupTitle}>Segurança</h2>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="email"
										label="E-mail"
										name="email"
										onChange={onChange}
										error={hasInputError(error, errors, "email")}
										helperText={getInputError(error, errors, "email")}
										value={values.email}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										label="Senha"
										type="password"
										id="password"
										name="password"
										onChange={onChange}
										error={hasInputError(error, errors, "password")}
										helperText={getInputError(error, errors, "password")}
										value={values.password}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										label="Repetir senha"
										type="password"
										id="confirmPassword"
										name="confirmPassword"
										onChange={onChange}
										error={errors.confirmPassword ? true : false}
										helperText={getInputError(error, errors, "confirmPassword")}
										value={values.confirmPassword}
										autoComplete="new-password"
									/>
								</Grid>
								{values.isUpdate && (
									<Grid item xs={12} md={4} lg={3}>
										<FormControl
											variant="outlined"
											fullWidth
											style={{ marginTop: 3 }}
										>
											<InputLabel id="authStatus-id">Status</InputLabel>
											<Select
												labelId="authStatus-id"
												value={values.status}
												error={hasInputError(error, errors, "status")}
												onChange={(
													e: React.ChangeEvent<{ value: unknown }>
												) => {
													const value = e.target.value as string;
													if (value !== "UNVERIFIED") {
														setValue("status", value);
													}
												}}
												name="status"
												label="Status"
												disabled={values.status === "UNVERIFIED"}
											>
												<MenuItem value="UNVERIFIED">
													E-mail não verificado
												</MenuItem>
												<MenuItem value="ENABLED">Habilitado</MenuItem>
												<MenuItem value="DISABLED">Desabilitado</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							lg={12}
							xl={8}
							className={classes.noSpacing}
						>
							<Grid container spacing={3} className={classes.group}>
								<Grid item xs={12} className={classes.noSpacing}>
									<h2 className={classes.groupTitle}>Perfil</h2>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="name"
										label="Nome"
										name="name"
										onChange={onChange}
										error={hasInputError(error, errors, "name")}
										helperText={getInputError(error, errors, "name")}
										value={values.name}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<Phone
										label="Celular"
										onChange={(newState: string) => {
											setValue("cellphone", newState);
										}}
										error={hasInputError(error, errors, "cellphone")}
										helperText={getInputError(error, errors, "cellphone")}
										value={values.cellphone}
										DDIChange={DDIPhoneChange}
										setDDIChange={setDDIPhoneChange}
										numberChange={numberPhoneChange}
										setNumberChange={setNumberPhoneChange}
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="birthdate"
										label="Data de nascimento (+18)"
										name="birthdate"
										onChange={onChange}
										error={hasInputError(error, errors, "birthdate")}
										helperText={getInputError(error, errors, "birthdate")}
										value={values.birthdate}
										autoComplete="new-password"
									/>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<FormControl
										variant="outlined"
										fullWidth
										className={classes.address}
									>
										<InputLabel id="gender-id">Me identifico como</InputLabel>
										<Select
											labelId="gender-id"
											value={values.gender}
											error={hasInputError(error, errors, "gender")}
											onChange={onChangeGender}
											name="gender"
											label="Me identifico como"
										>
											<MenuItem value="">
												<em>Prefiro não informar</em>
											</MenuItem>
											<MenuItem value="MULHER_CISGENERO">
												Mulher Cisgênero
											</MenuItem>
											<MenuItem value="HOMEM_CISGENERO">
												Homem Cisgênero
											</MenuItem>
											<MenuItem value="MULHER_TRANSGENERO">
												Mulher Transgênero
											</MenuItem>
											<MenuItem value="HOMEM_TRANSGENERO">
												Homem Transgênero
											</MenuItem>
											<MenuItem value="GENERO_NAO_BINARIO">
												Gênero Não-Binário
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<div className={classes.address}>
										<Address
											onChange={setAddress}
											defaultValue={values.fullAddress}
											error={errors.fullAddress?.message || ""}
										/>
									</div>
								</Grid>
								<Grid item xs={12} md={4} lg={3} className={classes.noSpacing}>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="cep"
										label="Cep"
										name="cep"
										onChange={onChange}
										error={hasInputError(error, errors, "cep")}
										helperText={getInputError(error, errors, "cep")}
										value={values.cep}
										autoComplete="cep"
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<ShowErrors.Field error={error} field="server" />

							<Button
								type="submit"
								variant="contained"
								color="primary"
								className={`${classes.submit} animate__animated ${classError}`}
								disabled={loading}
							>
								{loading ? "SALVANDO..." : "SALVAR"}
							</Button>

							{props.id && (
								<Button
									type="button"
									component={Link}
									to={`/pacientes/${props.id}`}
									style={{ marginLeft: 40 }}
								>
									VER PROGNÓSTICOS
								</Button>
							)}
						</Grid>
					</Grid>
				</form>
			</Paper>
		</div>
	);
};

Component.displayName = "Components_Admn_Forms";

export default Component;
