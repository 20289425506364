import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as services from "_services";
import { PrivatePage } from "_components/Layout";
import * as Plan from "_components/Plan";
import { Discount } from "_services/plans";
import { error as errorReducer, success as successReducer } from "_state/alert";

const useStyles = makeStyles((theme) => ({}));

type Props = {};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [allDiscounts, setAllDiscounts] = React.useState<Array<Discount>>([]);
  const success = React.useDispatch<any>(successReducer);
  const error = React.useDispatch<any>(errorReducer);

  React.useEffect(() => {
    services.plans.findAllDiscounts().then((response: Array<Discount>) => {
      setAllDiscounts(response);
    });
  }, []);

  const onUpdateDiscount = (discount: Discount) => {
    setAllDiscounts(
      allDiscounts.map((r: Discount) => {
        if (r.id === discount.id) {
          return discount;
        }
        return r;
      })
    );
    success("Desconto salvo com sucesso");
  };

  const onUpdateDiscountError = () => {
    error("Ocorreu um erro ao salvar o desconto");
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "PLANOS",
                to: "/planos",
              },
              {
                label: "DESCONTOS",
              },
            ]}
          />
        }
        Right={<></>}
      ></AppBar>

      {allDiscounts.map((discount: Discount) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {discount.name}
          </AccordionSummary>
          <AccordionDetails>
            <Plan.Discounts
              discount={discount}
              onError={onUpdateDiscountError}
              onSuccess={onUpdateDiscount}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </PrivatePage>
  );
};

Component.displayName = "pages_PLans_Discounts";

export default Component;
