import React from "reactn";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PowerInputIcon from "@material-ui/icons/PowerInput";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SecurityIcon from "@material-ui/icons/Security";
import PersonIcon from "@material-ui/icons/Person";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import PaidIcon from "@material-ui/icons/MonetizationOn";
import GavelIcon from "@material-ui/icons/Gavel";

// https://mui.com/components/material-icons/
export default [
  // {
  //   label: "Visão Geral",
  //   icon: <DashboardIcon fontSize="small" />,
  //   to: "/dashboard",
  // },
  {
    label: "Profissionais",
    icon: <LocalHospitalIcon fontSize="small" />,
    to: "/profissionais",
  },
  {
    label: "Clientes",
    icon: <AccountBoxIcon fontSize="small" />,
    to: "/pacientes",
  },
  {
    label: "Áreas de atuação",
    icon: <BookmarkBorderIcon fontSize="small" />,
    to: "/areas-de-atuacao",
  },
  {
    label: "Mapeamentos",
    icon: <PowerInputIcon fontSize="small" />,
    to: "/mapeamentos",
  },
  {
    label: "Prognósticos",
    icon: <ReceiptIcon fontSize="small" />,
    to: "/prognosticos",
  },
  {
    label: "Conteúdos",
    icon: <ChromeReaderModeIcon fontSize="small" />,
    to: "/conteudos",
  },
  // {
  //   label: "QUIZ",
  //   icon: <LiveHelpIcon fontSize="small" />,
  //   to: "/quiz",
  // },
  // {
  //   label: "Planner",
  //   icon: <AccessAlarmIcon fontSize="small" />,
  //   to: "/planner",
  // },
  {
    label: "Planos",
    icon: <PaidIcon fontSize="small" />,
    to: "/planos",
  },
  {
    label: "Documentos",
    icon: <GavelIcon fontSize="small" />,
    to: "/documentos",
  },
  {
    label: "Administradores",
    icon: <SecurityIcon fontSize="small" />,
    to: "/administradores",
  },
  {
    label: "Minha conta",
    icon: <PersonIcon fontSize="small" />,
    to: "/minha-conta",
  },
  {
    label: "Sair",
    icon: <ExitToAppIcon fontSize="small" />,
    to: "",
    logout: true,
  },
];
