import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import * as yup from "yup";
import AppError from "src/errors/AppError";
import * as services from "_services";
import { QuizOption } from "src/services/quizzes";
import Loading from "_UI/Loading";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  form: {
    width: "100%",
    position: "relative",
  },
  saving: {
    background: "#f5f5f5",
    position: "absolute",
    width: "100%",
    zIndex: 1,
    padding: 13,
  },
  inputs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    border: "none",
    display: "flex",
    flex: 1,
    padding: theme.spacing(1),
    background: "none",
  },
  inputStrong: {
    width: 50,
    flex: "initial",
    textAlign: "center",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    "& > button": {
      marginLeft: theme.spacing(1),
      padding: 0,
      width: 20,
      height: 20,
      "& svg": {
        width: 14,
      },
      // padding: theme.spacing(2, 4),
    },
  },
  confirmButton: {
    background: theme.palette.primary.main,
    color: "#FFF",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "#FFF",
    },
  },
  cancelButton: {
    background: theme.palette.grey[300],
  },
  error: {
    margin: theme.spacing(2, 0),
  },
}));

const schema = yup.object().shape({
  // description: yup.string().required("Campo obrigatório"),
});

type Props = {
  onCancel?: () => void;
  onSuccess?: () => void;
  onError?: (e: AppError | null) => void;
  option?: QuizOption;
  questionId: number;
  quizId: number;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [classError, setClassError] = React.useState<string>("");
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [remove, setRemove] = React.useState<boolean>(false);
  const ref = React.useRef<any>(null);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<Partial<QuizOption>>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: "",
      strong: 1,
      showDescriptionInApp: false,
    },
  });

  watch();

  React.useEffect(() => {
    if (props.option) {
      setValues(props.option);
    } else {
      if (ref?.current) {
        ref.current.focus();
      }
    }
  }, [props.option]);

  const setValues = async (option: QuizOption) => {
    watch();
    setValue("description", option.description);
    setValue("strong", option.strong);
    setValue("showDescriptionInApp", option.showDescriptionInApp);
  };

  const clearState = () => {
    setError(null);
    setValue("description", "");
    setValue("strong", 0);
    setValue("showDescriptionInApp", false);
  };

  const onError = (e: any) => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  const onChange = (e: any): void => {
    setValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onConfirm = (): void => {
    // props.onClose()
  };

  const onCancel = async (): Promise<void> => {
    if (props.option !== undefined) {
      setRemove(true);
    } else if (props.onCancel) {
      props.onCancel();
    }
  };

  const onRemove = async (): Promise<void> => {
    if (props.option !== undefined) {
      await services.options.remove(
        props.quizId,
        props.questionId,
        props.option?.id
      );
      if (props.onSuccess) props.onSuccess();
    }
    if (props.onCancel) props.onCancel();
  };

  const onSubmit = async () => {
    setError(null);
    setClassError("");

    const values = getValues();

    if (
      props.option?.description === values.description &&
      props.option?.strong === values.strong &&
      props.option?.showDescriptionInApp === values.showDescriptionInApp
    ) {
      return;
    }

    let body: any = {
      description: values.description,
      strong: Number(values.strong),
      showDescriptionInApp: values.showDescriptionInApp,
    };

    if (props.option?.id) {
      body.id = props.option?.id || 0;
    }

    setLoading(true);

    try {
      await services.options.save(props.quizId, props.questionId, body);
      clearState();
      if (props.onSuccess) props.onSuccess();
    } catch (e:any) {
      setClassError("animate__shakeX");
      setError(e as any);
      if (props.onError) props.onError(e);
    } finally {
      setLoading(false);
    }
  };

  const values = getValues();

  return (
    <>
      <Loading show={loading} />

      <form
        className={`${classes.form} animate__animated ${classError}`}
        noValidate
        onBlur={props.option ? handleSubmit(onSubmit, onError) : () => {}}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {loading && (
          <div className={classes.saving}>
            <LinearProgress />
          </div>
        )}

        <div className={classes.inputs}>
          <input
            ref={ref}
            type="text"
            className={classes.input}
            name="description"
            onChange={onChange}
            value={values.description || ""}
          />
          <Tooltip title="Pontuação">
            <input
              type="text"
              className={`${classes.input} ${classes.inputStrong}`}
              name="strong"
              onChange={onChange}
              value={values.strong}
            />
          </Tooltip>
          <Tooltip title="Exibir descrição no aplicativo?">
            <Checkbox
              onChange={(e: React.ChangeEvent<{ checked: unknown }>) => {
                const value = e.target.checked as boolean;
                setValue("showDescriptionInApp", value);
                setLoading(true);
                setTimeout(() => {
                  onSubmit();
                }, 500);
              }}
              checked={values.showDescriptionInApp}
              inputProps={{ "aria-label": "Checkbox demo" }}
            />
          </Tooltip>
          <div className={classes.actions}>
            {props.option === undefined && (
              <IconButton
                type="submit"
                aria-label="salvar"
                className={classes.confirmButton}
                onClick={onConfirm}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            )}

            <IconButton
              aria-label="remover"
              className={classes.cancelButton}
              onClick={onCancel}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </form>
      <Dialog open={remove} onClose={() => setRemove(false)}>
        <DialogContent>
          <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja remover a opção e resposta?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRemove(false)}>Cancelar</Button>
            <Button onClick={onRemove} autoFocus disabled={loading}>
              {loading ? "REMOVENDO..." : "SIM"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

Component.displayName = "Components_Quiz_Forms_Option_CreateOrUpdates";

export default Component;
