import React from "react";
import {
	Chip,
	IconButton,
	makeStyles,
	Paper,
	TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Close, CloudUpload, Check } from "@material-ui/icons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
	container: {},
	listContainer: {
		display: "flex",
		margin: theme.spacing(-1),
		padding: 0,
		flexWrap: "wrap",
	},
	file: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		margin: theme.spacing(1),
		justifyContent: "space-between",
		width: "100%",
		maxWidth: 400,
		borderRadius: 6,
	},
	newFile: {
		background: "#e2ffe2",
		padding: theme.spacing(0, 2),
	},
	inputFilename: {
		flex: 1,
	},
	remove: {
		marginLeft: theme.spacing(2),
	},
	selectFileContainer: {
		marginBottom: theme.spacing(4),
	},
	input: {
		display: "none",
	},
	actions: {
		width: "100%",
		marginTop: theme.spacing(3),
	},

	saveChangesButton: {
		margin: theme.spacing(1),
	},
	loading: {
		marginRight: theme.spacing(1),
	},
}));

type FileData = {
	id: number;
	src?: string;
	filename: string;
	file: File;
	isNewer?: boolean;
	tag: string;
};

type PropsFile = {
	file: FileData;
};

type Props = {
	files: Array<FileData>;
	removeDocument: (fileId: number) => void;
	donwloadFile: (src: string) => void;
	createDocuments: (fileId: Array<FileData>) => void;
	saving?: boolean;
	requiredField?: boolean;
	tag: string;
};

const Component: React.FC<Props> = (props) => {
	const classes = useStyles();
	const [saving, setSaving] = React.useState<boolean>(false);
	const [files, setFiles] = React.useState<Array<FileData>>([]);
	const [hasChanges, setHasChanges] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (props.saving == false && saving === true && !hasChanges) {
			setFiles([]);
		}
		setSaving(props.saving ? true : false);
	}, [props.saving]);

	const onSelectFiles = (e: any) => {
		if (props.saving) return;
		let newState = [...files];
		for (let file of e.target.files) {
			newState.push({
				file,
				id: Math.floor(Math.random() * Date.now()),
				filename: file.name,
				isNewer: true,
				tag: props.tag,
			});
		}

		setFiles(newState);
		setHasChanges(true);
	};

	const onChangeName = (fileId: number, value: string) => {
		if (props.saving) return;

		setFiles(
			files.map((file: FileData) => {
				return {
					...file,
					filename: file.id == fileId ? value : file.filename,
				};
			})
		);
		setHasChanges(true);
	};

	const onRemove = async (file: FileData) => {
		if (props.saving) return;

		let nextState = [...files];

		if (file.isNewer == false) {
			props.removeDocument(file.id);
		}

		nextState = files.filter((cFile: FileData) => {
			return cFile.id != file.id;
		});

		setFiles(nextState);
		setHasChanges(nextState.some((file: any) => file.isNewer));
	};

	const onSaveChanges = (e: any) => {
		e.preventDefault();
		props.createDocuments(files.filter((f: any) => f.isNewer));
		setHasChanges(false);
	};

	const onDownload = (file: FileData) => {
		if (file.src) {
			props.donwloadFile(file.src);
		}
	};

	const filesFromServer = props.files.map((f: any) => ({
		...f,
		filename: f.filename || f.media?.name || "",
		src: f.media?.src || undefined,
		isNewer: false,
		tag: f.tag,
	}));

	return (
		<div className={classes.container}>
			<div className={classes.selectFileContainer}>
				<input
					accept="application/pdf"
					className={classes.input}
					id={`contained-button-file-${props.tag}`}
					multiple
					type="file"
					onChange={onSelectFiles}
					required={props.requiredField}
				/>
				<label htmlFor={`contained-button-file-${props.tag}`}>
					<Button
						variant="contained"
						color="primary"
						component="span"
						startIcon={<CloudUpload />}
					>
						SELECIONAR ARQUIVOS
					</Button>
				</label>
			</div>

			<Paper component="ul" className={classes.listContainer}>
				{filesFromServer
					.filter((file: FileData) => file.tag === props.tag)
					.map((file: FileData) => {
						return (
							<li
								key={file.id}
								className={`${classes.file} ${
									!file.src ? classes.newFile : ""
								}`}
							>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									value={file.filename}
									className={classes.inputFilename}
									disabled={true}
								/>
								<div className={classes.remove}>
									<IconButton
										color="primary"
										component="span"
										onClick={() => onDownload(file)}
									>
										<CloudDownloadIcon />
									</IconButton>
								</div>
								<div className={classes.remove}>
									<IconButton
										color="primary"
										component="span"
										onClick={() => onRemove(file)}
									>
										<Close />
									</IconButton>
								</div>
							</li>
						);
					})}

				{files.map((file: FileData) => {
					return (
						<li
							key={file.id}
							className={`${classes.file} ${!file.src ? classes.newFile : ""}`}
						>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								onChange={(e: any) => onChangeName(file.id, e.target.value)}
								value={file.filename}
								autoComplete="new-password"
								className={classes.inputFilename}
							/>
							<div className={classes.remove}>
								<IconButton
									color="primary"
									component="span"
									onClick={() => onRemove(file)}
								>
									<Close />
								</IconButton>
							</div>
						</li>
					);
				})}

				{hasChanges && (
					<div className={classes.actions}>
						<Button
							type="button"
							variant="contained"
							color="primary"
							disabled={props.saving}
							className={`${classes.saveChangesButton} ${
								props.saving
									? ""
									: "animate__slow animate__animated animate__infinite animate__pulse"
							}`}
							startIcon={props.saving ? null : <Check />}
							onClick={onSaveChanges}
						>
							{props.saving ? (
								<>
									<CircularProgress size={20} className={classes.loading} />
									<span>SALVANDO</span>
								</>
							) : (
								<span>SALVAR ALTERAÇÕES</span>
							)}
						</Button>
					</div>
				)}
			</Paper>
		</div>
	);
};

Component.displayName = "Component_Media_Documents";

export default Component;
