import React from "react";
import qs from "qs";
import { Link, RouteProps, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PublicPage } from "_components/Layout";
import * as services from "_services";
import { ITerm } from "_services/terms";
import Loading from "_components/UI/Loading";
import { ITermQuery } from "../../../services/terms";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.main,
    color: "#FFF",
    fontSize: 18,
    padding: "40px 20px",
  },
  content: {
    padding: "40px 20px",
    lineHeight: "24px",
    whiteSpace: "pre-wrap",
  },
  langs: {
    fontSize: 13,
    marginTop: 10,
    color: "#FFF",
    "& > small": {
      margin: "0 10px",
    },
  },
  lang: {
    color: "#FFF",
  },
  print: {
    color: theme.palette.error.main,
    background: "none",
    border: "none",
    fontWeight: "bold",
    paddingLeft: 0,
    marginTop: 10,
    cursor: "pointer",
  },
}));

const Component: React.FC<RouteProps> = () => {
  const [content, setContent] = React.useState<ITerm | null>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const classes = useStyles();
  const { search } = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    setLoading(true);
    const query: ITermQuery = getQuery(search);

    if (query.print) {
      window.print();
    }

    services.terms
      .get(query)
      .then((rs) => {
        setContent(rs);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [search]);

  const getQuery = (search: string): ITermQuery => {
    let s = search;
    if (search.includes("?")) {
      s = s.slice(1);
    }

    const res: any = qs.parse(s);
    return res;
  };

  if (loading) {
    return <Loading show={true} />;
  }

  const getTitle = () => {
    const query: ITermQuery = getQuery(search);
    if (query.lang === "eng") {
      return content?.titleEng;
    }
    return content?.titlePt;
  };

  const getContent = () => {
    const query: ITermQuery = getQuery(search);
    if (query.lang === "eng") {
      return content?.contentEng;
    }
    return content?.contentPt;
  };

  const changeLang = (to: "eng" | "pt") => {
    let query: ITermQuery = getQuery(search);
    query = {
      ...query,
      lang: to,
    };
    history.push({
      pathname: history.location.pathname,
      search: qs.stringify(query, {
        arrayFormat: "brackets",
        encode: true,
        addQueryPrefix: true,
      }),
    });
  };

  const print = () => {
    if (window?.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ key: "PRINT" }));
    } else {
      window.print();
    }
  };

  if (loading || !content) {
    return <Loading show={true} />;
  }

  return (
    <PublicPage>
      <div className={classes.header}>
        <h1>{getTitle()}</h1>
        <p>
          <small>Versão {content?.version}</small> <br />{" "}
          <small>
            Publicado no dia {moment(content?.publishedAt).format("DD-MM-YYYY")}
          </small>
        </p>
        <div className="remove-print-document">
          {/*
          <p className={classes.langs}>
            <Link
              onClick={(e) => {
                e.preventDefault();
                changeLang("pt");
              }}
              className={classes.lang}
              to=""
            >
              PT
            </Link>
            <small>|</small>
            <Link
              onClick={(e) => {
                e.preventDefault();
                changeLang("eng");
              }}
              className={classes.lang}
              to=""
            >
              ENG
            </Link>
          </p>
          */}
          <button className={classes.print} onClick={print}>
            Imprimir
          </button>
        </div>
      </div>
      <div className={classes.content}>{getContent()}</div>
    </PublicPage>
  );
};

Component.displayName = "screens_Term_Detail";

export default Component;
