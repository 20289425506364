import { createTheme } from "@material-ui/core/styles";
// import purple from "@material-ui/core/colors/purple";
// https://material-ui.com/pt/customization/theming/#theme-configuration-variables
// https://material-ui.com/pt/customization/default-theme/?expand-path=$.palette
// https://material-ui.com/pt/customization/palette/

const theme = createTheme({
  palette: {
    primary: {
      // light: será calculada com base em palette.primary.main,
      main: "#4A0B61",
      // main: "#E73E5E",
      // dark: será calculada com base em palette.primary.main,
      // contrastText: será calculada para contrastar com palette.primary.main
    },
    secondary: {
      light: "#4A0B61",
      main: "#4A0B61",
      // dark: será calculada com base palette.secondary.main,
      // contrastText: será calculada para contrastar com palette.secondary.main
    },
    error: {
      // main: "#e2173e",
      main: "#E73E5E",
    },
    // Usado por `getContrastText()` para maximizar o contraste entre
    // o plano de fundo e o texto.
    contrastThreshold: 3,
    // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
    // dois índices dentro de sua paleta tonal.
    // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme;
