import AppError from "./AppError";

interface IError {
  field: string;
  errors: Array<IError>;
}

interface IErrors {
  statusCode: number;
  errors: Array<IError>;
}

export default class ApiError extends AppError {
  constructor(e: IErrors) {
    super();
    this.setStatusCode(e.statusCode);
    this.setErrors(this._formatErrors(e.errors));
  }

  private _formatErrors(errors: any) {
    let apiErrors: any = errors;
    if (!errors) {
      apiErrors = "Erro crítico";
    }

    apiErrors = Array.isArray(errors) ? errors : [apiErrors];
    return apiErrors.map((error: any) => {
      if (typeof error === "string") {
        return {
          field: "server",
          errors: [error],
        };
      } else {
        return {
          field: error.field,
          errors: error.errors,
        };
      }
    });
  }
}
