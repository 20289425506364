import React from "reactn";
import * as Table from "_components/Table";
import PaginateDatasource from "_services/PaginateDatasource";
import * as services from "_services";
import Filter from "./Filter";

const datasource = new PaginateDatasource<{
  id: number;
  name: string;
}>(services.pacients);

const cols = [
  {
    id: "id",
    label: "ID",
    sort: true,
  },
  {
    id: "name",
    label: "Nome",
    sort: true,
  },
  {
    id: "email",
    label: "E-mail",
    sort: true,
    sortby: "auth.email",
  },
];

type Props = {};

const Component: React.FC<Props> = () => {
  return (
    <>
      <Table.Service
        filter={(props: any) => <Filter {...props} />}
        cols={cols}
        path="/pacientes"
        datasource={datasource}
        onRemovedText="Cliente deletado com sucesso"
        onRemovedErrorText="Erro ao deletar o cliente"
        queryDefault={{
          order: "ASC",
          orderBy: "name",
        }}
      />
    </>
  );
};

Component.displayName = "Components_Pacient_List";

export default Component;
