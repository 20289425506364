import { Button } from "@material-ui/core";
import React from "react";
import { RouteProps, Link } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Admin from "_components/Admin";
import { PrivatePage } from "_components/Layout";
import AddIcon from "@material-ui/icons/Add";

const Component: React.FC<RouteProps> = () => {
  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "ADMINISTRADORES",
              },
            ]}
          />
        }
        Right={
          <>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              component={Link}
              to="/administradores/cadastrar"
            >
              CADASTRAR
            </Button>
          </>
        }
      ></AppBar>
      <Admin.List />
    </PrivatePage>
  );
};

Component.displayName = "screens_Admin_Login";

export default Component;
