import React from "react";
import { RouteProps } from "react-router-dom";
import { PublicPage } from "_components/Layout";

const Component: React.FC<RouteProps> = () => {
  return (
    <PublicPage>
      <p>
        A ESSENCIA DO SER LTDA, pessoa jurídica de direito privado inscrita no
        CNPJ sob o nº 27.106.666\0001-85, com sede na Avenida Oswaldo Rodrigues
        Cabral, 1570 sala 207, CEP 88015710, Florianópolis, Santa Catarina,
        comprometida em resguardar a privacidade e proteger os dados pessoais de
        seus clientes e disponibilizar o contato com o encarregado de
        privacidade e proteção de dados, sendo este o intermediador entre a
        empresa e os titulares dos dados. Entende como extremamente relevantes
        os registros eletrônicos e dados pessoais deixados por você –Titular, na
        utilização dos diversos sites e serviços, da MUP aplicativo, servindo o
        presente como Aviso de Privacidade para regularizar, e esclarecer de
        forma transparente, objetiva e simples, como os seus dados pessoais são
        coletados, utilizados e protegidos.
      </p>
      <p>O presente documento é voltado aos beneficiários da MUP.</p>
      <p>Este aviso de privacidade dispõe, entre outros assuntos, sobre:</p>
      <p>
        Quais dados são coletados sobre você; O que são cookies; Como utilizamos
        os seus dados pessoais; Com quem compartilhamos os seus dados pessoais;
        Como mantemos os seus dados pessoais seguros; Por quanto tempo os seus
        dados pessoais serão armazenados; Seus direitos como Titular de dados
        pessoais e a forma de exercê-los.
      </p>
      <p>
        Em caso de dúvidas adicionais ou requisições, por favor, entre em
        contato com nosso Encarregado pela Proteção de Dados por meio do
        endereço de e-mail: mupterapiasholisticas@gmail.com
      </p>
      <p>Quem é Agente de tratamento de dados? MUP</p>
      <p>
        Qual seu papel no tratamento dos dados? Predominantemente controladora.
      </p>
      <p>
        Qual a natureza dos dados tratados? Dados pessoais fornecidos pelo
        Titular e/ou coletados automaticamente, de natureza cadastral,
        financeira e sensível (artigo 5, II da Lei 13.709/2018).
      </p>
      <p>
        Principais finalidades do tratamento? A ESSENCIA DO SER LTDA, poderá
        utilizar dados pessoais para fornecer os seus produtos e serviços,
        efetuar avaliações online, emitir alertas sobre agendamentos de
        consultas e exames, cuidados preventivos, elaborar planos de prevenção
        continuada e progressiva.
      </p>
      <p>
        Com quem meus dados são compartilhados? Operadores e fornecedores de
        serviços essenciais para nossas atividades; profissionais da área
        terapêutica, empresas parceiras; ANS-Agência Nacional de Saúde
        Suplementar, autoridades/órgãos governamentais por decorrência de
        obrigações legais ou regulatórias.
      </p>
      <p>
        O que a MUP faz para garantir a proteção de dados? Medidas de segurança,
        técnicas e administrativas adequadas.
      </p>
      <p>
        Quais são os seus direitos? Confirmação da existência de tratamento,
        acesso, correção etc.
      </p>
      <p>
        Este Aviso poderá ser atualizado, a qualquer tempo, pela ESSENCIA DO SER
        LTDA, mediante aviso no site e/ou por e-mail, caso o Titular tenha
        optado por receber comunicações da ESSENCIA DO SER LTDA.{" "}
      </p>
      <p>QUAIS DADOS SÃO COLETADOS SOBRE VOCÊ</p>
      <p>
        Para que possamos prestar os nossos serviços, torna-se imprescindível a
        coleta de algumas informações que dizem respeito sobre você.
      </p>
      <p>Quais são estes dados? </p>
      <p>
        Dados pessoais fornecidos por terceiros: ESSENCIA DO SER LTDA, recebe
        dados pessoais de terceiros, sejam parceiros e prestadores de serviços,
        relacionados a você. Os dados recebidos de tais fontes, possibilitam que
        a ESSENCIA DO SER LTDA preste os seus serviços a você.
      </p>
      <p>
        Dados pessoais fornecidos diretamente por você: ESSENCIA DO SER Ltda
        coletará todos os dados pessoais inseridos ou encaminhados ativamente
        pelo usuário ao se cadastrar em nosso portal e canal do cliente. Os
        dados coletados variam de acordo com o tipo de serviço em que você está
        cadastrado. Abaixo apresentamos uma lista, simples e objetiva, dos
        serviços prestados pela ESSENCIA DO SER LTDA:{" "}
      </p>
      <p>COLETA DE DADOS POR SERVIÇOS:</p>
      <p>
        Agendamento de consultas: Nome; CPF; Telefone; endereço; e-mail;
        WhatsApp; Dados do Profissional; Dados clínicos;
      </p>
      <p>
        Contratação: Nome; RG; CPF; Telefone; Endereço; declaração pessoal de
        saúde com dados de saúde como doenças pré-existentes.
      </p>
      <p>
        Cadastro para atendimento presencial: Nome; endereço; idade; telefone;
        sexo; CPF; RG;; diagnostico; data de nascimento; nome da mãe; avaliações
        realizadas; histórico clínico; peso e altura.
      </p>
      <p>Pesquisas: Nome; CPF; Dados do prontuário.</p>
      <p>
        Dados coletados automaticamente: ESSENCIA DO SER Ltda também coleta uma
        série de informações de modo automático, tais como: características do
        dispositivo de acesso, wi-fi, do navegador, IP (com data e hora), origem
        do IP, informações sobre cliques, páginas acessadas, termos de procura
        digitado em nossos portais, dentre outros. Tal coleta é realizada por
        meio do uso de algumas tecnologias padrões, como cookies.
      </p>
      <p>O QUE SÃO COOKIES</p>
      <p>
        Cookies são arquivos ou informações que podem ser armazenadas em seus
        dispositivos quando você visita o site ou utiliza os serviços on-line da
        ESSENCIA DO SER LTDA.
      </p>
      <p>TIPOS DE COOKIES E O QUE ELES FAZEM:</p>
      <p>
        Necessários: Os cookies são essenciais para que os websites da ESSENCIA
        DO SER LTDA carreguem adequadamente e permitam que você navegue
        corretamente.
      </p>
      <p>
        Desempenho: Os cookies nos ajudam a entender como os visitantes
        interagem com as páginas da ESSENCIA DO SER LTDA, fornecendo informações
        sobre as áreas visitadas, o tempo de visita ao site e quaisquer
        problemas encontrados, como mensagens de erro.{" "}
      </p>
      <p>
        Funcionais: Os cookies permitem que as páginas da ESSENCIA DO SER LTDA
        se lembrem de suas escolhas, para proporcionar uma experiência
        personalizada.
      </p>
      <p>
        Marketing: Os cookies são utilizados para fornecer mais conteúdo
        relevante a você. Podem ser utilizados para apresentar publicidade e
        também permitem a medição da eficácia de uma campanha publicitária
        lançada.
      </p>
      <p>
        Ao acessar, pela primeira vez, o Portal da ESSENCIA DO SER LTDA, você
        nos informou as suas preferências para a utilização destas ferramentas.
      </p>
      <p>
        Caso queira revisitar a autorização concedida, entre em contato via
        e-mail mupterapiasholisticas@gmail.com
      </p>
      <p>
        Para toda a coleta de dados pessoais, a ESSENCIA DO SER LTDA sempre
        seguirá as seguintes regras:{" "}
      </p>
      <p>
        Apenas serão coletadas informações imprescindíveis para a prestação dos
        serviços oferecidos.
      </p>
      <p>
        Caso seja necessária à coleta de demais dados pessoais, tomaremos o
        devido cuidado de coletar a sua
      </p>
      <p>
        Qualquer autorização que coletarmos de você será acompanhada do devido
        grau de informação e tratamento adequado.
      </p>
      <p>
        Os dados pessoais coletados somente serão utilizados para cumprir com as
        finalidades informadas a você;
      </p>
      <p>COMO UTILIZAMOS OS SEUS DADOS PESSOAIS</p>
      <p>
        Dados pessoais são tratados pela ESSENCIA DO SER LTDA para diversas
        finalidades.
      </p>
      <p>
        Abaixo apresentamos as principais hipóteses em que trataremos as suas
        informações:
      </p>
      <p>Para a execução de contratos ou diligências preliminares, como:</p>
      <p>
        Gestão, administração, prestação, ampliação e melhoramento dos Serviços
        oferecidos ao Titular;{" "}
      </p>
      <p>
        Para o relacionamento e oferecimento de informações relacionadas aos
        produtos e serviços contratados pelos clientes da ESSENCIA DO SER LTDA;
      </p>
      <p>
        Para avaliar se é possível o oferecimento de determinados produtos ou
        serviços e sob quais condições.
      </p>
      <p>
        Caso a ESSENCIA DO SER LTDA tenha que cumprir alguma obrigação legal ou
        regulatória, como:
      </p>
      <p>
        Guarda de prontuário médico pelo período de 20 (vinte anos) a partir do
        último registro, conforme artigo 6º da Lei nº 13.787/2018;
      </p>
      <p>
        Notificação compulsória em caso de suspeita de maus tratos de crianças,
        conforme artigo 13 do Estatuto da Criança e Adolescente;
      </p>
      <p>
        Compartilhamento de dados com o Ministério da Saúde para formação do
        conjunto mínimo de dados, consoante artigo 47 da Lei nº 8.080/1990 e
        artigo 4º do Decreto 29/2017;{" "}
      </p>
      <p>
        Para a tutela da saúde, em procedimentos que, pelas suas
        particularidades, dispensam a coleta de consentimento:
      </p>
      <p>
        Como por exemplo, nos casos de consultas médicas em que são coletados
        dados como altura, peso, hábitos alimentares, pressão arterial entre
        outras informações.
      </p>
      <p>
        Na persecução do interesse legítimo da ESSENCIA DO SER LTDA, sempre no
        limite de sua expectativa, e nunca em prejuízo de seus interesses,
        direitos e liberdades fundamentais.
      </p>
      <p>
        Para a condução de atividades que visam prevenir a ocorrência de
        fraudes, como:
      </p>
      <p>
        A condução de investigações internas, quando houver o envolvimento de
        dados pessoais sensíveis.
      </p>
      <p>Por meio de autorização concedida por você.</p>
      <p>Para defesa dos direitos da EMPRESA.</p>
      <p>COM QUEM COMPARTILHAREMOS OS SEUS DADOS PESSOAIS</p>
      <p>
        A ESSENCIA DO SER LTDA por vezes, precisará compartilhar os seus dados
        pessoais com terceiros. As situações que envolverão o compartilhamento
        de suas informações serão:
      </p>
      <p>
        Com empresas parceiras e fornecedores, no desenvolvimento e prestação de
        serviços disponibilizados a você, sobretudo a Rede Credenciada como
        clinicas terapêuticas, consultórios, profissionais das terapias
        complementares.{" "}
      </p>
      <p>
        Com autoridades, Agência Nacional de Saúde Suplementar, entidades
        governamentais ou outros terceiros, para a proteção dos interesses da
        ESSENCIA DO SER LTDA em qualquer tipo de conflito, incluindo ações
        judiciais e processos administrativos;
      </p>
      <p>
        No caso de transações e alterações societárias envolvendo a ESSENCIA DO
        SER LTDA, hipótese em que a transferência das informações será
        necessária para a continuidade dos serviços; ou,
      </p>
      <p>
        Mediante ordem judicial ou pelo requerimento de autoridades
        administrativas que detenham competência legal para a sua requisição.{" "}
      </p>
      <p>
        Tendo em vista que a Lei Geral de Proteção de Dados nº13.709/18 assegura
        aos titulares de dados pessoais diversos direitos, inclusive o de saber
        com quem a empresa compartilhou os respectivos dados pessoais,
        disponibilizamos o contato do Encarregado de Dados e-mail:
        mupterapiasholisticas@gmail.com
      </p>
      <p>COMO MANTEMOS OS SEUS DADOS PESSOAIS SEGUROS</p>
      <p>
        Qualquer dado pessoal em posse da ESSENCIA DO SER LTDA será armazenado
        de acordo com os mais rígidos padrões de segurança, o que inclui a
        adoção de medidas como:
      </p>
      <p>Proteção contra acesso não autorizado a seus sistemas; </p>
      <p>
        Acesso restrito de pessoas específicas ao local onde são armazenadas as
        informações pessoais; e
      </p>
      <p>
        Garantia, treinamento, termo de ajuste e comunicado para que agentes,
        funcionários internos ou parceiros externos que realizarem o tratamento
        de dados pessoais e que deverão se comprometer a manter o sigilo
        absoluto das informações, adotando as melhores práticas para manuseio
        destes dados, conforme determinado nas políticas e procedimentos
        corporativos;{" "}
      </p>
      <p>
        Além dos esforços técnicos, a ESSENCIA DO SER LTDA também adota medidas
        institucionais visando a proteção de dados pessoais, de modo que mantém
        programa de governança em privacidade aplicado às suas atividades e
        estrutura de governança, constantemente atualizado e gerido pelo
        Encarregado pela Proteção de Dados Pessoais.
      </p>
      <p>
        A ESSENCIA DO SER LTDA adota os melhores esforços no sentido de
        preservar a privacidade e proteger os dados pessoais dos Titulares, mas
        nenhuma transmissão de informações é totalmente segura, estando sempre
        suscetíveis a ocorrência de falhas técnicas, vírus ou ações similares.
      </p>
      <p>
        De qualquer forma, em caso de incidência de episódios desta natureza, a
        ESSENCIA DO SER LTDA garante o pleno e legítimo esforço para remediar as
        consequências do evento, sempre garantindo a devida transparência a
        você.
      </p>
      <p>POR QUANTO TEMPO OS SEUS DADOS PESSOAIS SERÃO ARMAZENADOS</p>
      <p>
        Os dados pessoais tratados pela ESSENCIA DO SER LTDA serão eliminados
        quando deixarem de ser úteis para os fins para os quais foram coletados,
        ou quando você solicitar a sua eliminação, exceto na hipótese de
        necessidade de cumprimento de obrigação legal ou regulatória (a exemplo
        da Lei do Prontuário Eletrônico (artigo 6º da Lei 13.787/2018) que
        determina a guarda do respectivo documento por, no mínimo, 20 (vinte)
        anos, a partir do último registro), transferência a terceiro – desde que
        respeitados os requisitos de tratamento de dados – e uso exclusivo da
        ESSENCIA DO SER LTDA, inclusive para o exercício de seus direitos em
        processos judiciais ou administrativos.{" "}
      </p>
      <p>SEUS DIREITOS ENQUANTO TITULAR DOS DADOS</p>
      <p>
        Em cumprimento à regulamentação, no que diz respeito ao tratamento de
        dados pessoais, a ESSENCIA DO SER LTDA, enquanto Controladora de seus
        dados pessoais, respeita e garante ao Titular, a possibilidade de
        apresentação de solicitações baseadas nos seguintes direitos:
      </p>
      <p>Confirmação da existência de tratamento;</p>
      <p>Acesso aos dados;</p>
      <p>Correção de dados incompletos, inexatos ou desatualizados;</p>
      <p>
        Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos
        ou tratados em desconformidade;
      </p>
      <p>
        Portabilidade de seus dados a outro fornecedor de serviço ou produto,
        mediante requisição e ou autorização expressa pelo Titular;{" "}
      </p>
      <p>Eliminação dos dados tratados com consentimento do Titular;</p>
      <p>
        Obtenção de informações sobre as entidades públicas ou privadas com as
        quais a ESSENCIA DO SER LTDA compartilhou seus dados;
      </p>
      <p>
        Informação sobre a possibilidade de não fornecer o seu consentimento,
        bem como de ser informado sobre as consequências, em caso de negativa;
      </p>
      <p>Revogação do consentimento. </p>
      <p>Neste ponto, todas as requisições serão:</p>
      <p>Oportunizadas de forma gratuita;</p>
      <p>
        Submetidas a uma forma de validação de sua identidade a fim de que a
        ESSENCIA DO SER LTDA direcione o atendimento de requisições,
        exclusivamente ao titular dos dados
      </p>
      <p>
        Para exercer os seus direitos como titular de dados pessoais, entre em
        contato conosco através do e-mail:mupterapiasholisticas@gmail.com
      </p>
      <p>
        A ESSENCIA DO SER LTDA ressalta a possibilidade da sua requisição ser
        legalmente rejeitada, seja por motivos formais (a exemplo de
        impossibilidade de comprovação da sua identidade) ou legais (a exemplo
        do pedido de exclusão de dados cuja manutenção é livre exercício de
        direito pela ESSENCIA DO SER LTDA sendo certo que, na hipótese de
        impossibilidade de atendimento destas requisições, a ESSENCIA DO SER
        LTDA apresentará à você, as justificativas cabíveis.
      </p>
      <p>LEGISLAÇÃO APLICÁVEL E ALTERAÇÕES</p>
      <p>
        Este documento foi elaborado com base na Lei Federal nº 13.709/2018 –
        Lei Geral de Proteção de Dados LGPD.{" "}
      </p>
      <p>
        A ESSENCIA DO SER LTDA reserva o direito, a seu exclusivo critério, de
        modificar, alterar, acrescentar ou remover partes deste documento a
        qualquer momento.
      </p>
    </PublicPage>
  );
};

Component.displayName = "screens_Terms_GTDP";

export default Component;
