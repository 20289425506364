import React from "reactn";
import { useHistory, RouteProps } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Pacient from "_components/Pacient";
import { PrivatePage } from "_components/Layout";
import { success as successReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const success = React.useDispatch<any>(successReducer);

  const onSuccess = () => {
    success("Cliente cadastrado com sucesso");
    history.push("/pacientes");
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "CLIENTES",
                to: "/pacientes",
              },
              {
                label: "CADASTRAR",
              },
            ]}
          />
        }
      ></AppBar>
      <Pacient.Form onSuccess={onSuccess} />
    </PrivatePage>
  );
};

Component.displayName = "screens_Pacient_Create";

export default Component;
