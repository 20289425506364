import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60vh",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(3),
  },
  desc: {
    marginBottom: theme.spacing(4),
    fontSize: 18,
  },
  date: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[800],
  },
  dateIcon: {
    fontSize: 42,
    marginRight: 10,
    marginLeft: -5,
    color: theme.palette.grey[800],
  },
  dateContent: {
    display: "flex",
    flexDirection: "column",
    "& > strong": {
      lineHeight: "28px",
      fontSize: 22,
      fontWeight: "bolder",
    },
    "& > small": {
      fontSize: 11,
    },
  },
}));

type Props = {
  message?: string;
  date?: string;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const daysOf = props.date
    ? moment(props.date, "DD-MM-YYYY").endOf("day").fromNow()
    : "";

  return (
    <div className={classes.container}>
      <Paper
        elevation={4}
        className={`${classes.paper} animate__animated animate__flipInY`}
      >
        {props.date ? (
          <>
            <p className={classes.desc}>RECURSO EM DESENVOLVIMENTO</p>
            <p>
              <small>PREVISÃO DE ENTREGA:</small>
            </p>
            <div className={classes.date}>
              <EventAvailableIcon className={classes.dateIcon} />
              <div className={classes.dateContent}>
                <strong>{props.date}</strong>
                <small>{daysOf}</small>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className={classes.desc}>RECURSO NÃO CONTRATADO</p>
            <p>
              <small>AGUARDANDO CONFIRMAÇÃO.</small>
            </p>
          </>
        )}
      </Paper>
    </div>
  );
};

Component.displayName = "Components_UI_DevMessage";

export default Component;
