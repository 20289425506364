import AppError from "./AppError";

export default class InternalError extends AppError {
  constructor() {
    super();
    this.setStatusCode(0);
    this.setErrors([
      {
        field: "internal",
        errors: [
          "Ocorreu um erro interno. Nossa equipe tecnica foi notificada e logo o problema será corrigido.",
        ],
      },
    ]);
  }
}
