import axios from "axios";
import { HandlerError } from "_errors";

export type GroupQuestion = {
  id?: number | null;
  name: string;
};

/**
 * findALl
 */
export async function findAll() {
  try {
    const response = await axios.get(`/group-question/`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * save
 */
export async function save(name: string) {
  try {
    const response = await axios.post(
      `/group-question`,
      { name },
      {
        useToken: true,
      }
    );
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(id: number) {
  try {
    const response = await axios.delete(`/group-question/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
