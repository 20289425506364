import React from "react";
import { RouteProps } from "react-router-dom";
import { PublicPage } from "_components/Layout";

const Component: React.FC<RouteProps> = () => {
  return (
    <PublicPage>
      <p>
        MUP protege sua privacidade, temos um compromisso de transparência e
        simplicidade com você. Nós não utilizaremos seus dados para qualquer
        propósito sem o seu consentimento. Para fornecer produtos e serviços
        mais relevantes com base no seu perfil e te atualizar sobre sua saúde a
        MUP utiliza seus dados pessoais.
      </p>
      <p>Ao consentir com este termo você autoriza:</p>
      <p>
        Receber indicações, informativos e promoções, através de: notificações,
        plush SMS, E-mail e ou Whatzapp.
      </p>
      <p>
        Compartilhar dados obtidos nas suas avaliações, apenas com empresas
        parceiras e profissionais credenciados a MUP.
      </p>
      <p>PARCEIROS QUE COMPARTILHAMOS DADOS</p>
      <p>
        Os dados pessoais poderão ser compartilhados com parceiros,
        profissionais da área e prestadores de serviços, e esses podem ser
        tratados em nosso nome. Tais serviços são contratualmente restritos ou
        ocorrem para atender obrigações legais.
      </p>
      <p>
        Além das entidades que fazem parte do Sistema MUP, podemos compartilhar
        dados pessoais com os seguintes tipos de organizações de terceiros:
      </p>
      <p>PRESTADORES DE SERVIÇOS</p>
      <p>
        incluem empresas externas que auxiliam o sistema MUP a torná-lo mais
        atraente e convidativo e assim operar nosso negócio. Provedores de
        serviços e seus colaboradores selecionados só estão autorizados a
        acessar os dados pessoais em nome do sistema MUP para tarefas
        específicas, que forem requisitadas a eles com base em nossas instruções
        diretas.
      </p>
      <p>TERCEIROS QUE USAM SEUS DADOS PESSOAIS POR MOTIVOS LEGAIS OU DEVIDO</p>
      <p>
        Divulgaremos seus dados pessoais a terceiros por motivos legais, ou no
        contexto de ampliação e inovação do sistema.
      </p>
      <p>PRIVACIDADE DE DADOS</p>
      <p>Dados de necessidades contratuais</p>
      <p>
        Hoje precisamos de alguns dados para que seu cadastro seja efetuado, por
        normas e regulações do PROGRAMA DE PROTEÇÃO AOS DADOS PESSOAIS. Os dados
        utilizados para regulamentação, e normativas da manutenção de seu
        cadastro são:
      </p>
      <p>
        Nome completo, CNH, RG, CNPJ, CRC, CTPS, PIS, RG, Título eleitoral,
        assinatura, estado civil, nacionalidade, naturalidade, data de
        nascimento, endereço, endereço de e-mail, telefone, escolaridade,
        sindicato e NIF.{" "}
      </p>
      <p>
        Na MUP temos o compromisso de promover um ambiente diverso, inclusivo e
        seguro para todas as pessoas em todos os momentos, inclusive no processo
        de avaliação.
      </p>
      <p>
        Para que possamos potencializar nossos esforços nesse sentido, pedimos
        que você compartilhe algumas informações sobre marcadores de
        diversidade. Esses dados serão tratados com confidencialidade e usados
        apenas para métricas e melhorias internas de processo
      </p>
      <p>
        Todas as pessoas cadastradas que preencherem as avaliações serão
        consideradas no processo do prognóstico/diagnóstico. Se você não se
        sentir confortável em compartilhar alguma das informações abaixo isso
        não afetará em nada seu cadastro.{" "}
      </p>
      <p>
        Sinalize com qual raça/cor você se identifica (Amarela, Branca,
        Indígena, Parda, Preta e Prefiro não informar)
      </p>
      <p>Sinalize se você se identifica parte da comunidade LGBTQIA+</p>
      <p>
        Lésbica, gay, bissexual, transsexual, queer, intersexo, assexual,
        panssexual, outra (Sim ou Não)
      </p>
      <p>Você possui algum tipo de deficiência? (Sim ou Não)</p>
    </PublicPage>
  );
};

Component.displayName = "screens_Terms_GTDP";

export default Component;
