import axios from "axios";
import { HandlerError } from "_errors";

/**
 * getMe
 */
export async function getMe() {
  try {
    const response = await axios.get("/admins/me", {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * update
 */
export async function update({ id, name }: any) {
  try {
    const response = await axios.put(
      `/admins/${id}`,
      {
        name,
      },
      {
        useToken: true,
      }
    );
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
