import * as React from "reactn";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import * as services from "_services";
import { Specialty } from "src/services/specialties";

const useStyles = makeStyles((theme) => ({
  container: {},
  chips: {
    margin: theme.spacing(1, -0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

type Props = {
  specialties: Array<Specialty>;
  onChange: (newState: Array<Specialty>) => void;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<Specialty | null>(null);
  const [search, setSearch] = React.useState<string>("");
  const [specialties, setSpecialties] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    let t: any = null;
    if (t) clearTimeout(t);

    t = setTimeout(() => {
      services.specialties
        .findAll({
          search,
          limit: 100,
        })
        .then(({ items }) => {
          setSpecialties(items);
        });
    }, 400);

    return () => {
      if (t) clearTimeout(t);
    };
  }, [search]);

  const onAdd = (specialty: Specialty) => {
    if (props.specialties.some((s: Specialty) => s.id == specialty.id)) {
      return;
    }
    props.onChange([...props.specialties, specialty]);
  };

  const onDelete = (specialty: Specialty) => {
    props.onChange(
      props.specialties.filter((s: Specialty) => s.id !== specialty.id)
    );
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        value={selected}
        onChange={(event: any, newValue: Specialty | null) => {
          onAdd(newValue as Specialty);
          setSelected(null);
          setSearch("");
        }}
        options={specialties as Specialty[]}
        getOptionLabel={(option: Specialty) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Especialidades" variant="outlined" />
        )}
        noOptionsText="Não encontrado"
      />
      <div className={classes.chips}>
        {props.specialties.map((specialty: Specialty, key: number) => {
          return (
            <Chip
              key={key.toString()}
              label={specialty.name}
              onDelete={() => onDelete(specialty)}
              className={classes.chip}
              variant="outlined"
              color="primary"
            />
          );
        })}
      </div>
    </div>
  );
};

Component.displayName = "Components_Specialty_SelectMany";

export default Component;
