import React from "reactn";
import { useHistory, useParams, RouteProps, Link } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Doctor from "_components/Doctor";
import { PrivatePage } from "_components/Layout";
import { error as errorReducer, success as successReducer } from "_state/alert";
import FileDownload from "@material-ui/icons/GetApp";
import { Button } from "@material-ui/core";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const success = React.useDispatch<any>(successReducer);
  const error = React.useDispatch<any>(errorReducer);

  const onSuccess = () => {
    success("Dados atualizados com sucesso");
    history.push("/profissionais");
  };

  const onError = () => {
    error(
      "Ocorreu um erro ao atualizar os dados. Verifique os campos e tente novamente."
    );
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "PROFISSIONAIS",
                to: "/profissionais",
              },
              {
                label: "EDITAR",
              },
            ]}
          />
        }
        Right={
          <>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<FileDownload />}
              component={Link}
              to={`/profissionais/exportar/${Number(params.id)}`}
            >
              EXPORTAR
            </Button>
          </>
        }
      ></AppBar>
      <Doctor.Form
        onSuccess={onSuccess}
        onError={onError}
        id={Number(params.id)}
      />
    </PrivatePage>
  );
};

Component.displayName = "screens_Doctor_Update";

export default Component;
