import React from "reactn";
import * as Table from "_components/Table";
import PaginateDatasource from "_services/PaginateDatasource";
import * as services from "_services";
import Filter from "./Filter";

const datasource = new PaginateDatasource<{
  id: number;
  name: string;
}>(services.specialties);

const cols = [
  {
    id: "id",
    label: "ID",
    sort: true,
  },
  {
    id: "name",
    label: "Nome",
    sort: true,
  },
  // {
  //   id: "email",
  //   label: "E-mail",
  //   sort: true,
  //   sortBy: "auth.email",
  // },
];

type Props = {};

const Component: React.FC<Props> = () => {
  return (
    <Table.Service
      filter={(props: any) => {
        return <Filter {...props} />;
      }}
      cols={cols}
      path="/areas-de-atuacao"
      datasource={datasource}
      hideView={true}
      onRemovedText="Área de atuação deletada com sucesso"
      onRemovedErrorText="Erro ao deletar a área de atuação"
      queryDefault={{
        order: "ASC",
        orderBy: "name",
      }}
    />
  );
};

Component.displayName = "Components_specialty_List";

export default Component;
