import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ICol, IRow } from "./types";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: theme.spacing(0, 2),
    position: "relative",
  },
}));

interface Props {
  page: number;
  rowsPerPage: number;
  cols: Array<ICol>;
  rows: Array<IRow>;
  onDelete?: (e: any, row: any) => void;
  onUpdate?: (row: any) => void;
  onView?: (row: any) => void;
  disabled?: () => void;
  hideView?: boolean;
  hideUpdate?: boolean;
  hideDelete?: boolean;
  hideActions?: boolean;
  beforeRenderRow?: (row: IRow) => any;
}

function isFunction(functionToCheck: any) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const colsId = props.cols.map((c) => c.id);
  const getColById = (colId: any) => props.cols.find((c) => c.id == colId);

  // {isFunction(row[colId])
  //   ? row[colId](row, index)
  //   : row[colId] || ""}
  //

  return (
    <TableBody style={{ position: "relative" }}>
      {props.rows.map((row, index: number) => (
        <TableRow key={`table-row-${index}`} style={{ position: "relative" }}>
        {colsId.map((colId) => {
          const col = { ...getColById(colId) };
          delete col.sort;
          delete col.id;
          delete col.label;

          return (
            <TableCell className={classes.tableCell} key={colId} {...col}>
              {col && 'render' in col && col['render']
                ? col['render'](row, col, index)
                : row[colId] || ""}
            </TableCell>
          );
        })}

        {!props.hideActions && (
          <TableCell
            className={classes.tableCell}
            align="center"
            style={{ width: 170 }}
          >
            {props.beforeRenderRow ? props.beforeRenderRow(row) : <></>}

            {!props.hideUpdate && (
              <IconButton
                aria-label="Editar"
                onClick={(e) => {
                  e.preventDefault();
                  if (props.onUpdate) props.onUpdate(row);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}

            {!props.hideView && (
              <IconButton
                aria-label="Ver detalhes"
                onClick={(e) => {
                  e.preventDefault();
                  if (props.onView) props.onView(row);
                }}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            )}

            {!props.hideDelete && (
              <IconButton
                aria-label="Deletar"
                onClick={(e: any) => {
                  if (props.onDelete) {
                    props.onDelete(e, row);
                  }
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
      ))}
    </TableBody>
  );
};

Component.displayName = "Components_Table_TablePagination_Body";

export default Component;
