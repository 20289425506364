import React from "reactn";
import { RouteProps, useParams } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Forms from "_components/Quiz/Forms";
import { PrivatePage } from "_components/Layout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Quiz } from "src/services/quizzes";
import * as services from "_services";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
}));

const Component: React.FC<RouteProps> = () => {
  const classes = useStyles();
  const params = useParams<{
    id: string;
  }>();
  const [quiz, setQuiz] = React.useState<Quiz | null>(null);

  const findAndSetQuiz = async (quizId: number) => {
    const quiz = await services.quizzes.findById(quizId);
    setQuiz(quiz);
  };

  React.useEffect(() => {
    findAndSetQuiz(Number(params.id));
  }, [params]);

  const onSaved = () => {
    findAndSetQuiz(Number(params.id));
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "MAPEAMENTOS",
                to: "/mapeamentos",
              },
              {
                label: "EDITAR",
              },
            ]}
          />
        }
      ></AppBar>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className={classes.title}>{quiz?.name || ""}</span>
            </AccordionSummary>
            <AccordionDetails>
              <Forms.Quiz onSuccess={onSaved} quiz={quiz} />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className={classes.title}>Perguntas e respostas</span>
            </AccordionSummary>
            <AccordionDetails>
              <Forms.Question
                onSuccess={onSaved}
                quizId={quiz?.id || 0}
                questions={quiz?.questions || []}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className={classes.title}>Feedback de resultado</span>
            </AccordionSummary>
            <AccordionDetails>
              <Forms.Score onSuccess={onSaved} quiz={quiz} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </PrivatePage>
  );
};

Component.displayName = "screens_Quiz_Update";

export default Component;
