import * as React from "reactn";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError, { hasInputError } from "_helpers/getInputError";
import * as services from "_services";
import { Specialty } from "src/services/specialties";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5, 5),
  },
  center: {
    textAlign: "center",
  },
  error: {
    margin: theme.spacing(2, 0),
  },
  address: {
    margin: theme.spacing(2, 0),
  },
  noSpacing: {
    marginTop: theme.spacing(0) + " !important",
    marginBottom: theme.spacing(0) + " !important",
    paddingTop: theme.spacing(0) + " !important",
    paddingBottom: theme.spacing(0) + " !important",
  },
  group: {
    marginBottom: theme.spacing(3),
  },
  groupTitle: {
    marginTop: theme.spacing(0) + " !important",
    marginBottom: theme.spacing(0) + " !important",
    fontSize: "12px",
    textTransform: "uppercase",
  },
}));

const schema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
});

type Props = {
  onSuccess?: (specialty: Specialty) => void;
  onError?: () => void;
  id?: number;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [classError, setClassError] = React.useState<string>("");
  const [classUpdateAlert, setClassUpdateAlert] = React.useState<string>("");
  const [error, setError] = React.useState<AppError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [specialties, setSpecialties] = React.useState<Array<any>>([]);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: null,
      name: "",
      specialty: null,
      description: "",
    },
  });

  watch();

  React.useEffect(() => {
    if (props.id) {
      setValues(props.id);
    }
  }, [props.id]);

  React.useEffect(() => {
    let t: any = null;
    if (t) clearTimeout(t);

    t = setTimeout(() => {
      services.specialties
        .findAll({
          search,
          limit: 100,
        })
        .then(({ items }) => {
          setSpecialties(items);
        });
    }, 400);

    return () => {
      if (t) clearTimeout(t);
    };
  }, [search]);

  const setValues = async (id: number) => {
    watch();
    const specialty = await services.specialties.findById(id);
    if (specialty) {
      setValue("id", id);
      setValue("name", specialty.name);
      setValue("specialty", specialty.specialty);
      setValue("description", specialty.description);
    }
  };

  const clearState = () => {
    setError(null);
    setValue("id", null);
    setValue("name", "");
    setValue("specialty", null);
    setValue("description", "");
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  const values = getValues();
  const onChange = (e: any): void => {
    setValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onCancelUpdate = (e: any) => {
    e.preventDefault();
  };

  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    setClassError("");

    const values = getValues();

    let body: any = {
      name: values.name,
      specialtyId: values.specialty?.id || null,
      description: values.description,
    };

    if (values.id) {
      body.id = values.id;
    }

    try {
      const specialty = await services.specialties.save(body);
      clearState();

      if (props.onSuccess) {
        props.onSuccess(specialty);
      }
    } catch (e:any) {
      setClassError("animate__shakeX");
      setError(e as any);
      if (props.onError) props.onError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      className={`${classes.form} animate__animated ${classUpdateAlert}`}
      noValidate
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={8}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label="Nome"
            name="name"
            onChange={onChange}
            error={hasInputError(error, errors, "name")}
            helperText={getInputError(error, errors, "name")}
            value={values.name}
            autoComplete="off"
          />
          <Autocomplete
            id="combo-box-demo"
            value={values.specialty}
            onInputChange={(event, newInputValue) => {
              setSearch(newInputValue);
            }}
            onChange={(event: any, newValue: any) => {
              setValue("specialty", newValue);
            }}
            options={specialties}
            getOptionLabel={(option: { name: string }) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Pertence a" variant="outlined" />
            )}
            noOptionsText="Não encontrado"
          />
          <TextField
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            label="Descrição"
            name="description"
            onChange={onChange}
            error={hasInputError(error, errors, "description")}
            helperText={getInputError(error, errors, "description")}
            value={values.description}
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12}>
          <ShowErrors.Field error={error} field="server" />
        </Grid>

        <Grid
          item
          style={{ display: "flex" }}
          xs={12}
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${classes.submit} animate__animated ${classError}`}
            disabled={loading}
          >
            {loading ? "SALVANDO..." : "SALVAR"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

Component.displayName = "Components_Specialty_Forms";

export default Component;
