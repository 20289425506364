import React from "reactn";
import { RouteProps } from "react-router-dom";
import * as MyAccount from "_components/MyAccount";
import { PrivatePage } from "_components/Layout";
import { success as successReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const success = React.useDispatch<any>(successReducer);

  const onSuccess = () => {
    success("Dados atualizados com sucesso");
  };

  return (
    <PrivatePage>
      <MyAccount.Update onSuccess={onSuccess} />
    </PrivatePage>
  );
};

Component.displayName = "screens_Auth_ForgotPassword";

export default Component;
