import ApiError from "./ApiError";
import InternalError from "./InternalError";

export default class HandlerError extends Error {
  constructor(e: any) {
    super();
    // console.error(e);
    if (e.response) {
      throw new ApiError(e.response.data);
    } else {
      // internal error
      throw new InternalError();
    }
  }
}
