import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cropContainer: {
    position: "relative",
    width: "100%",
    minHeight: 200,
  },
  cropContent: {
    position: "relative",
    width: "100%",
    height: 200,
    background: "#333",
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sliderContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    width: "100%",
  },
  sliderLabel: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: "22px 0px",
    margin: "0 6px",
    flexDirection: "row",
    alignItems: "center",
  },
  confirmBtn: {
    marginLeft: 6,
    padding: "4px 6px",
    width: "auto",
    minWidth: "auto",
    "& span": {
      marginRight: 0 + "!important",
      marginLeft: 0 + "!important",
    },
  },
  cancelBtn: {
    background: "red",
    // background: theme.palette.error[200],
    padding: "4px 6px",
    width: "auto",
    minWidth: "auto",
    "& span": {
      marginRight: 0 + "!important",
      marginLeft: 0 + "!important",
    },
  },
  actions: {
    marginLeft: 10,
  },
}));
