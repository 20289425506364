import React from "react";
import { RouteProps, useHistory } from "react-router-dom";
import * as Auth from "_components/Auth";
import { PublicPage } from "_components/Layout";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const onSuccess = () => {
    history.push("/profissionais");
  };

  return (
    <PublicPage>
      <Auth.Login onSuccess={onSuccess} />
    </PublicPage>
  );
};

Component.displayName = "screens_Auth_Login";

export default Component;
