import { Dispatch, State } from "reactn/default";
import * as service from "_services";
import { IGroupQuestionState, IGroupQuestionsState } from "./types";

export const initialState = [];

export async function findAll(global: State, dispatch: Dispatch) {
  const groups = await service.groupQuestion.findAll();
  return {
    groupQuestion: groups,
  };
}

export async function save(global: State, dispatch: Dispatch, name: string) {
  const group: IGroupQuestionState = await service.groupQuestion.save(name);
  return {
    groupQuestion: [...global.groupQuestion, group],
  };
}

export async function remove(
  global: State,
  dispatch: Dispatch,
  groupQuestionId: number
) {
  await service.groupQuestion.remove(groupQuestionId);
  return {
    groupQuestion: global.groupQuestion.filter(
      (group: IGroupQuestionState) => group.id !== groupQuestionId
    ),
  };
}
