import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Link, RouteProps, useParams } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import AppBar from "_components/Layout/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import { PrivatePage } from "_components/Layout";
import * as services from "_services";
import { IPacient } from "_services/pacients";
import { IEvaluation } from "_services/evaluations";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  loading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "80vh",
  },
  paper: {
    padding: theme.spacing(1, 3),
  },
  title: {
    marginTop: 0,
  },
  scoreContainer: {
    display: "flex",
  },
  pacient: {
    padding: 10,
  },
  profileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  profile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 10,
  },
  profileQuizName: {
    margin: 0,
  },
  profileQuizScore: {
    fontSize: 32,
    textAlign: "center",
    margin: 0,
  },
  profileQuizTag: {
    padding: "2px 14px",
    borderRadius: "100px",
    color: "#FFF",
    fontSize: 12,
  },
  block: {
    margin: "20px 0",
  },
  specialty: {
    margin: "4px  4px",
  },
  result: {
    marginLeft: "20px",
  },
  graph: {
    height: 200,
    position: "relative",
  },
  drawer: {
    "& > .MuiPaper-root": {
      width: "95%",
      padding: theme.spacing(8, 3, 3, 3),
    },
  },
  closeDrawerContainer: {
    position: "absolute",
    left: 8,
    top: 4,
  },
}));

const gender = {
  HOMEM_TRANSGENERO: "Homem transgênero",
  HOMEM_CISGENERO: "Homem cisgênero",
  MULHER_TRANSGENERO: "Mulher transgênero",
  MULHER_CISGENERO: "Mulher cisgênero",
  GENERO_NAO_BINARIO: "Gênero não binário",
} as any;

const colors = {
  ORIENTACAO: "#8dba32",
  OTIMO: "#f9ca21",
  BOM: "#ee9229",
  COMPROMETIDO: "#ea592a",
  CRITICO: "#e73b2a",
} as any;

const quizzesName = {
  EMOCIONAL: "Emocional",
  ESPIRITUAL: "Espiritual",
  FISICO: "Físico",
  MENTAL: "Mental",
  BARRET: "Barret",
  HABITOS: "Hábitos",
} as any;

const graphColor = {
  EMOCIONAL: "#5F437D",
  ESPIRITUAL: "#3E88A3",
  FISICO: "#65AEC1",
  MENTAL: "#ED5A7A",
  BARRET: "#F8485D",
  HABITOS: "#D63648",
} as any;

const Component: React.FC<RouteProps> = () => {
  const [evaluations, setEvaluations] = React.useState<Array<
    IEvaluation
  > | null>(null);
  const [pacient, setPacient] = React.useState<IPacient | null>(null);
  const [detail, setDetail] = React.useState<any | null>(null);
  const classes = useStyles();
  const params = useParams<{ id: string }>();

  React.useEffect(() => {
    services.evaluations.findAllByPacientId(Number(params.id)).then((res) => {
      setEvaluations(res);
    });
    services.pacients.findById(Number(params.id)).then((res) => {
      setPacient(res);
    });
  }, []);

  const profile = Object.keys(quizzesName).map((quizSlug: string) => {
    return {
      quizSlug: quizSlug,
      quizName: quizzesName[quizSlug],
      ...(pacient?.profile[quizSlug] || {}),
    };
  });

  const graph = {
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
  } as any;

  const graphData = ([...(evaluations || [])] || [])
    .sort((a: any, b: any) => {
      const dateA = new Date(a.date) as any;
      const dateB = new Date(b.date) as any;
      // @ts-ignore
      return dateA - dateB;
    })
    .reduce((acc: any, cur: any) => {
      acc[cur.quiz.slug] = acc[cur.quiz.slug] || [];
      acc[cur.quiz.slug].push(Number(cur.score));
      return acc;
    }, {});

  let countLabels = 0;
  for (const c of Object.values(graphData) as Array<any>) {
    if (c.length > countLabels) countLabels = c.length;
  }
  graph.data.labels = [...Array(countLabels)].map(
    (x: any, i: number) => `${i + 1}ª`
  );

  graph.data.datasets = Object.keys(quizzesName).map((quizSlug: string) => {
    return {
      label: quizzesName[quizSlug],
      data: graphData[quizSlug],
      backgroundColor: graphColor[quizSlug],
      stack: quizzesName[quizSlug],
    };
  });

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "CLIENTES",
                to: "/pacientes",
              },
              {
                label: "DETALHES",
              },
            ]}
          />
        }
      ></AppBar>

      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={1} className={classes.paper}>
            <Grid container>
              <Grid item xs={12} md={3} lg={2} className={classes.block}>
                <div className={classes.pacient}>
                  {pacient ? (
                    <h3 className={classes.title}>{pacient?.name}</h3>
                  ) : (
                    <Skeleton variant="text" />
                  )}
                  {pacient ? (
                    <span>{gender[pacient?.gender || ""] || ""}</span>
                  ) : (
                    <Skeleton variant="text" />
                  )}
                  <br />
                  {pacient?.birthdate ? (
                    <span>
                      {moment().diff(pacient?.birthdate, "years")} anos
                    </span>
                  ) : (
                    <Skeleton variant="text" />
                  )}
                  <br />
                  {pacient ? (
                    <Link to={`/pacientes/editar/${pacient?.id}`}>Editar</Link>
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={9}
                lg={10}
                className={`${classes.profileContainer} ${classes.block}`}
              >
                {profile.map((quiz: any) => (
                  <div className={classes.profile}>
                    <h3 className={`${classes.profileQuizName}`}>
                      {quiz?.quizName}
                    </h3>
                    <h4 className={`${classes.profileQuizScore}`}>
                      {quiz.evaluation?.score || "00"}
                    </h4>
                    <span
                      className={classes.profileQuizTag}
                      style={{
                        background: colors[quiz?.evaluation?.tag] || "#ccc",
                      }}
                    >
                      {quiz?.evaluation?.tagDescription || "sem dados"}
                    </span>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Paper elevation={1} className={`${classes.paper} ${classes.graph}`}>
            <Line data={graph.data} options={graph.options} />
          </Paper>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <TableContainer component={Paper} className={`${classes.paper}`}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Mapeamento</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(evaluations || []).map((evaluation: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {evaluation.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/mapeamentos/editar/${evaluation.quiz?.id}`}>
                        {evaluation.quiz?.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          background:
                            (colors[evaluation.tag || ""] as any) || "#ccc",
                          padding: "3px 10px",
                          borderRadius: "100px",
                          display: "inline-block",
                          fontSize: "12px",
                          color: "#FFF",
                        }}
                      >
                        {evaluation.tagDescription}({evaluation.score})
                      </div>
                    </TableCell>
                    <TableCell>
                      <>
                        {moment(evaluation.date)
                          .utc()
                          .format("DD-MM-YYYY HH:MM")}
                        <br />
                        {moment(evaluation.date).utc().endOf("day").fromNow()}
                      </>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setDetail(evaluation)}
                      >
                        RESPOSTAS
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={detail == null ? false : true}
        onClose={() => setDetail(null)}
        transitionDuration={500}
        className={classes.drawer}
      >
        <div className={classes.closeDrawerContainer}>
          <IconButton aria-label="fechar" onClick={() => setDetail(null)}>
            <CloseIcon />
          </IconButton>
        </div>

        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={1} className={classes.paper}>
              <Grid container>
                <Grid
                  className={`${classes.block} ${classes.scoreContainer}`}
                  item
                  xs={12}
                  md={3}
                  lg={2}
                >
                  <div className={classes.profile}>
                    <h3
                      className={`${classes.title} ${classes.profileQuizScore}`}
                    >
                      {detail?.score}
                    </h3>
                    <span
                      className={classes.profileQuizTag}
                      style={{ background: colors[detail?.tag] }}
                    >
                      {detail?.tagDescription}
                    </span>
                  </div>
                </Grid>

                <Grid item xs={12} md={3} lg={3} className={classes.block}>
                  <h3 className={classes.title}>{detail?.quiz.name}</h3>
                  {moment(detail?.date).format("DD-MM-YYYY")}
                  <br />
                  {moment(detail?.date).endOf("day").fromNow()}
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={7} style={{ marginTop: 30 }}>
            <Paper elevation={0} className={classes.paper}>
              <h3 className={classes.title}>Respostas</h3>
              <Grid container>
                {detail?.answers.map((answer: any) => (
                  <Grid item xs={12} style={{ marginTop: 15 }}>
                    <strong>{answer.question.title}</strong>
                    <br />
                    <span>
                      {answer.description} ({answer.strong})
                    </span>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5} style={{ marginTop: 30 }}>
            <Paper
              elevation={0}
              className={`${classes.paper} ${classes.result}`}
            >
              <h3 className={classes.title}>Feedback de Resultado</h3>
              <p>{detail?.message}</p>
              <div>
                {detail?.specialties.map((specialty: any) => (
                  <Chip label={specialty.name} className={classes.specialty} />
                ))}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Drawer>
    </PrivatePage>
  );
};

Component.displayName = "screens_Pacient_Detail";

export default Component;
