import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { TableHead, TableSortLabel } from "@material-ui/core";
import { ICol } from "./types";

interface Props {
  onChange: (newState: { order: string; orderBy: string }) => void;
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  cols: Array<ICol>;
}

const Component: React.FC<Props> = (props) => {
  const createSortHandler = (orderBy: string, order: string) => (): void => {
    props.onChange({
      orderBy,
      order,
    });
  };

  return (
    <TableHead>
      <TableRow>
        {props.cols.map((col: any) => (
          <TableCell
            key={col.id}
            padding={"normal"}
            sortDirection={props.orderBy === col.id ? props.order : false}
          >
            {col.sort ? (
              <TableSortLabel
                active={props.orderBy === (col.sortBy || col.id)}
                direction={
                  props.orderBy === (col.sortBy || col.id) ? props.order : "asc"
                }
                onClick={createSortHandler(
                  col.sortBy || col.id,
                  props.order == "asc" ? "desc" : "asc"
                )}
              >
                {col.label}
              </TableSortLabel>
            ) : (
              <>{col.label}</>
            )}
          </TableCell>
        ))}

        <TableCell align="center" padding={"normal"}></TableCell>
      </TableRow>
    </TableHead>
  );
};

Component.displayName = "Components_Table_TablePagination_Head";

export default Component;
