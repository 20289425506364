import * as React from "reactn";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import * as services from "_services";
import { IDoctor } from "src/services/doctors";

const useStyles = makeStyles((theme) => ({
  container: {},
  chips: {
    margin: theme.spacing(1, -0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

type Props = {
  doctor: IDoctor;
  variant?: "filled" | "outlined" | "standard";
  onChange: (newState: IDoctor | null) => void;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<IDoctor | null>(null);
  const [search, setSearch] = React.useState<string>("");
  const [doctors, setDoctors] = React.useState<Array<any>>([]);
  const variant = props.variant || "outlined";

  React.useEffect(() => {
    let t: any = null;
    if (t) clearTimeout(t);

    t = setTimeout(() => {
      const q: any = {
        search,
        limit: 100,
      };
      services.doctors.findAll(q).then(({ items }) => {
        setDoctors(items);
      });
    }, 400);

    return () => {
      if (t) clearTimeout(t);
    };
  }, [search]);

  const onAdd = (doctor: IDoctor) => {
    props.onChange(doctor);
  };

  const onDelete = (doctor: IDoctor) => {
    props.onChange(null);
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        value={selected}
        onChange={(event: any, newValue: IDoctor | null) => {
          onAdd(newValue as IDoctor);
          setSelected(null);
          setSearch("");
        }}
        options={doctors as IDoctor[]}
        getOptionLabel={(option: IDoctor) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Terapeuta" variant={variant} />
        )}
        noOptionsText="Não encontrado"
      />
      <div className={classes.chips}>
        {props.doctor && (
          <Chip
            label={props.doctor.name}
            onDelete={() => onDelete(props.doctor)}
            className={classes.chip}
            variant="outlined"
            color="primary"
          />
        )}
      </div>
    </div>
  );
};

Component.displayName = "Components_Doctor_SelectMany";

export default Component;
