import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import { IQuery } from "./PaginateDatasource/types";

/**
 * findALl
 */
export async function findAll(query: IQuery) {
  const queryString = qs.stringify(query, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/admins/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * findById
 */
export async function findById(id: number) {
  try {
    const response = await axios.get(`/admins/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * create
 */
export async function create({ name, email, password }: any) {
  try {
    const response = await axios.post(
      `/admins/`,
      {
        name,
        email,
        password,
      },
      {
        useToken: true,
      }
    );
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * update
 */
export async function update({ id, name }: any) {
  try {
    const response = await axios.put(
      `/admins/${id}`,
      {
        name,
      },
      {
        useToken: true,
      }
    );
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(id: number) {
  try {
    const response = await axios.delete(`/admins/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
