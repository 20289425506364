import React from "reactn";
import { useHistory, useParams, RouteProps, Link } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Admin from "_components/Admin";
import { PrivatePage } from "_components/Layout";
import { success as successReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const success = React.useDispatch<any>(successReducer);

  const onSuccess = () => {
    success("Dados atualizados com sucesso");
    history.push("/administradores");
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "ADMINISTRADORES",
                to: "/administradores",
              },
              {
                label: "EDITAR",
              },
            ]}
          />
        }
      ></AppBar>
      <Admin.Form onSuccess={onSuccess} id={Number(params.id)} />
    </PrivatePage>
  );
};

Component.displayName = "screens_Admin_Login";

export default Component;
