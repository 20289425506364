import React from "react";
import { RouteProps } from "react-router-dom";
import * as Auth from "_components/Auth";
import { PublicPage } from "_components/Layout";

const Component: React.FC<RouteProps> = () => {
  return (
    <PublicPage>
      <Auth.ForgotPassword />
    </PublicPage>
  );
};

Component.displayName = "screens_Auth_ForgotPassword";

export default Component;
