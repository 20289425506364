import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import { AppError } from "_errors";

const useStyles = makeStyles((theme) => ({
  errors: {
    padding: 0,
    paddingLeft: 16,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  },
  error: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1, 0),
    color: theme.palette.error.main,
  },
}));

type FieldError = {
  message: string;
};

type Props = {
  error?: any;
  // error?: AppError | FieldError | null;
  field?: string;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  if (props.error) {
    let errors: string | Array<string> = "";

    if (props.error instanceof AppError) {
      errors = props.error.toArray();
      if (props.field) {
        errors = props.error.getErrorByField(props.field)?.errors || [];
      }
    } else if (props.error.message) {
      errors = props.error.message;
    }

    if (Array.isArray(errors)) {
      return (
        <ul className={classes.errors}>
          {errors.map((e: string, index: number) => (
            <li className={classes.error} color="secondary" key={index}>
              {e}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <div className={classes.error} color="secondary">
          {errors}
        </div>
      );
    }
  }

  return <></>;
};

Component.displayName = "Components_UI_ShowErros_Field";

export default Component;
