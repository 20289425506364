import React from "reactn";
import {
  Button,
  createStyles,
  FormControlLabel,
  FormGroup,
  Switch,
  Grid,
  makeStyles,
  Paper,
  Theme,
  TextField,
} from "@material-ui/core";

import { toMoney, toNumber, moneyToNumber } from "_helpers/masks";
import * as services from "_services";
import { Plan, Resource } from "src/services/plans";
import { Link } from "react-router-dom";

type Props = {};

type StatePlans = {
  [key: string]: {
    id: number;
    value: string;
  };
};

type State = {
  plans: StatePlans;
};

let changeTimeout: any = null;
const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState<State | null>(null);
  const [plans, setPlans] = React.useState<Array<Plan>>([]);
  const [allPlans, setAllPlans] = React.useState<Array<Plan>>([]);
  const [allResources, setAllResources] = React.useState<Array<Resource>>([]);

  React.useEffect(() => {
    services.plans.findAllResources().then((response: Array<Resource>) => {
      setAllResources(response);
    });

    services.plans
      .findAll({
        main: true,
      })
      .then((response: Array<Plan>) => {
        setPlans(response);
      });

    services.plans.findAll().then((response: Array<Plan>) => {
      setAllPlans(response);
    });
  }, []);

  React.useEffect(() => {
    const nextState: StatePlans = allPlans.reduce(
      (acc: StatePlans, plan: Plan) => {
        acc[plan.slug] = {
          id: plan.id,
          value: toMoney(Number(plan.value), 1, true),
        };
        return acc;
      },
      {}
    );

    setState({
      ...state,
      plans: nextState,
    });
  }, [allPlans]);

  const handleChangeResource = (e: any, plan: Plan, resource: Resource) => {
    const nextState = !!e.target.checked;

    if (nextState == true) {
      setPlans(
        plans.map((_plan: Plan) => {
          if (_plan.id === plan.id) {
            _plan.resources.push(resource);
          }
          return _plan;
        })
      );
    } else {
      setPlans(
        plans.map((_plan: Plan) => {
          if (_plan.id === plan.id) {
            _plan.resources = _plan.resources.filter(
              (r: Resource) => r.id !== resource.id
            );
          }
          return _plan;
        })
      );
    }

    const relatedPlans = getPlanPartialString(plan.slug);

    for (const plan of relatedPlans) {
      if (nextState === true) {
        services.plans.addResourceToPlan(plan.id, resource.id);
      } else {
        services.plans.removeResourceFromPlan(plan.id, resource.id);
      }
    }
  };

  const getPlanPartialString = (planSlug: string) => {
    const value = planSlug.split("_")[0];
    return allPlans.filter((plan: Plan) => plan.slug.indexOf(value) !== -1);
  };

  const onChange = (
    planSlug: string,
    planProp: string,
    value: string | number
  ) => {
    if (state) {
      setState({
        ...state,
        plans: {
          ...state.plans,
          [planSlug]: {
            ...state.plans[planSlug],
            [planProp]: value,
          },
        },
      });

      if (changeTimeout) clearTimeout(changeTimeout);

      changeTimeout = setTimeout(() => {
        services.plans.update({
          id: state.plans[planSlug]["id"],
          [planProp]: Number(moneyToNumber(value?.toString())),
        });
      }, 600);
    }
  };

  const onSubmit = (e: any) => {};

  if (state === null) return null;

  return (
    <Grid container xs={12} spacing={3}>
      {plans.map((plan: Plan) => {
        return (
          <Grid item xs={12} key={plan.id}>
            <Paper className={classes.plan} elevation={1}>
              <Grid container xs={12} spacing={3}>
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <h1 style={{ margin: 0 }}>{plan.name}</h1>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ul className={classes.resources}>
                    {allResources.map((resource: Resource) => (
                      <li key={resource.id} className={classes.resource}>
                        <FormControlLabel
                          label={`${resource.name};`}
                          control={
                            <Switch
                              checked={
                                plan.resources.some(
                                  (r: Resource) => r.id == resource.id
                                ) as any
                              }
                              onChange={(e) =>
                                handleChangeResource(e, plan, resource)
                              }
                            />
                          }
                        />
                      </li>
                    ))}
                  </ul>
                </Grid>

                <Grid item xs={12} sm={8}>
                  <Grid container xs={12} spacing={2}>
                    {getPlanPartialString(plan.slug).map((plan: Plan) => (
                      <Grid item xs={12} sm={12} md={4} key={plan.id}>
                        <div>
                          <strong className={classes.planValuesPeriod}>
                            {plan.period}
                          </strong>
                          <form
                            onSubmit={onSubmit}
                            className={classes.form}
                            noValidate
                          >
                            <div className={classes.inputs}>
                              <TextField
                                fullWidth
                                className={classes.mb}
                                size="small"
                                variant="outlined"
                                label="Preço total"
                                onChange={(e: any) =>
                                  onChange(
                                    plan.slug,
                                    "value",
                                    toMoney(e.target.value, 1)
                                  )
                                }
                                error={false}
                                helperText={""}
                                value={state.plans[plan.slug]?.value}
                              />
                            </div>
                          </form>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

Component.displayName = "Components_Specialty_List";

export default Component;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    plan: {
      padding: theme.spacing(1),
    },
    planName: {
      textTransform: "uppercase",
      fontSize: 18,
      textDecoration: "none",
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
    planDescription: {
      flex: 1,
      margin: theme.spacing(2, 0),
    },
    resources: {
      padding: 0,
      listStyle: "none",
      "& li": {
        margin: theme.spacing(1, 0),
      },
    },
    planValues: {
      padding: theme.spacing(1),
      background: "#efefef",
      borderRadius: "6px",
    },
    planValuesPeriod: {
      textTransform: "capitalize",
      marginBottom: theme.spacing(2),
      fontSize: 20,
      fontWeight: "normal",
      display: "block",
    },
    form: {
      padding: theme.spacing(2),
      background: "#f6f6f6",
      borderRadius: "4px",

      display: "flex",
      flexDirection: "column",
    },
    label: {
      width: "100%",
      display: "flex",
      flex: 1,
      margin: theme.spacing(2, 0),
      marginTop: theme.spacing(3),
      fontSize: 12,
      fontWeight: "bold",
    },
    inputs: {},
    mb: {
      marginBottom: 20,
    },
    resource: {
      fontSize: 12,
      "& > label.MuiFormControlLabel-root span.MuiFormControlLabel-label": {
        fontSize: 14,
      },
    },
  })
);
