import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import * as React from "react";

type Props = {
  onSuccess: (message: string) => void;
  onClose: () => void;
  open: boolean;
  message: string;
};

const Component: React.FC<Props> = (props) => {
  const [message, setMessage] = React.useState<string>(props.message);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    if (props.message && message === "") {
      setMessage(props.message);
    }
  }, [props.message]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!message) {
      setError("Mensagem obrigatória");
      return;
    }

    props.onSuccess(message);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Informe ao profissional o motivo da reprovação do perfil:
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            multiline={true}
            rows={4}
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            label="Mensagem"
            name="description"
            onChange={(e: any) => setMessage(e.target.value)}
            error={error !== ""}
            helperText={error}
            value={message}
            autoComplete="new-password"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancelar</Button>
        <Button onClick={onSubmit} autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
