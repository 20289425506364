import React from "reactn";
import * as qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import { Button, createStyles, Fade, Popper, Theme } from "@material-ui/core";
import { IQuery, IPaginationMeta } from "_services/PaginateDatasource/types";
import PaginateDatasource from "_services/PaginateDatasource";
import { ICol, IRow } from "./types";
import Head from "./Head";
import Body from "./Body";
import Footer from "./Footer";
import { success as successReducer, error as errorReducer } from "_state/alert";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: "100%",
			overflowX: "auto",
		},
		table: {
			minWidth: 450,
		},
		removeContainer: {
			background: theme.palette.error.main,
			padding: theme.spacing(2),
			textAlign: "center",
			color: "#FFF",
		},
		filterContainer: {
			padding: theme.spacing(0, 2),
			backgroundColor: theme.palette.grey[200],
		},
		margin: {
			margin: theme.spacing(1),
		},
	})
);

type Props = {
	cols: Array<ICol>;
	datasource: PaginateDatasource<IRow>;
	queryDefault: Partial<IQuery>;
	path: string;
	hideActions?: boolean;
	hideView?: boolean;
	hideUpdate?: boolean;
	hideDelete?: boolean;
	onRemovedText?: string;
	onRemovedErrorText?: string;
	filter?: any;
	beforeRenderRow?: (row: IRow) => any;
	hide?: boolean;
};

const DEFAULT_QUERY = {
	page: 1,
	limit: 25,
	hide: true,
};

const Component: React.FC<Props> = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const success = React.useDispatch<any>(successReducer);
	const error = React.useDispatch<any>(errorReducer);
	const [rows, setRows] = React.useState<Array<any>>([]);
	// const [meta, setMeta] = React.useState<IPaginationMeta | null>(null);
	const [pageCount, setPageCount] = React.useState(0);
	const [query, setQuery] = React.useState<IQuery | null>(null);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const [open, setOpen] = React.useState(false);
	const [remove, setRemove] = React.useState<any>(null);

	const get = () => {
		if (query) {
			setRows([]);
			// setMeta(null);

			props.datasource
				.get(query)
				.then((response: any) => {
					setRows(response.items);
					// setMeta(response.meta);
					setPageCount(response?.count || response?.meta?.totalItems);
				})
				.catch((e) => console.error(e));
		}
	};

	React.useEffect(() => {
		applyFilter(qs.parse(location.search, { ignoreQueryPrefix: true }));
	}, []);

	React.useEffect(() => {
		get();
	}, [location.search]);

	React.useEffect(() => {
		history.push({
			pathname: location.pathname,
			search: qs.stringify(query, {
				arrayFormat: "brackets",
				encode: false,
				addQueryPrefix: true,
			}),
		});
	}, [query]);

	const onPageChange = (event: any, newPage: number) => {
		setQuery((prevState) =>
			prevState
				? {
						...prevState,
						page: newPage + 1,
				  }
				: null
		);
	};

	const onRowsPerPageChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setQuery((prevState) =>
			prevState
				? {
						...prevState,
						limit: parseInt(event.target.value, 10),
						// limit: parseInt(event.target.value, 10),
				  }
				: null
		);
	};

	const onSort = (newState: { order: string; orderBy: string }) => {
		setQuery((prevState) =>
			prevState
				? {
						...prevState,
						order: newState.order.toUpperCase(),
						orderBy: newState.orderBy,
				  }
				: null
		);
	};

	const onDelete = (event: React.MouseEvent<HTMLButtonElement>, row: any) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev: any) => (remove && remove.id !== row.id) || !prev);
		setRemove(row);
	};

	const onDeleteCancel = () => {
		setAnchorEl(null);
		setOpen(false);
		setRemove(null);
	};

	const onDeleteConfirm = async () => {
		try {
			await props.datasource.remove(remove.id);
			success(props.onRemovedText || "Item deletado com sucesso");
			onDeleteCancel();
			setTimeout(() => {
				get();
			}, 250);
		} catch (e) {
			error(props.onRemovedText || "Erro ao deletar o item");
		} finally {
		}
	};

	const onUpdate = (row: any) => {
		history.push(`${props.path}/editar/${row.id}`);
	};

	const onView = (row: any) => {
		history.push(`${props.path}/${row.id}`);
	};

	const applyFilter = (query: any) => {
		setQuery({
			...DEFAULT_QUERY,
			...props.queryDefault,
			...query,
		});
	};

	const Filter = props.filter || null;

	return (
		<>
			<Paper className={classes.container}>
				{Filter !== null && (
					<div className={classes.filterContainer}>
						<Filter
							cols={props.cols}
							rows={rows}
							query={query}
							applyFilter={applyFilter}
						/>
					</div>
				)}

				<Table className={classes.table}>
					<Head
						order={query?.order == "ASC" ? "asc" : "desc"}
						orderBy={query?.orderBy || ""}
						onChange={onSort}
						rowCount={query?.limit || 25}
						cols={props.cols}
					/>

					<Body
						rows={rows}
						cols={props.cols}
						page={query?.page || 0}
						rowsPerPage={query?.limit ? Number(query.limit) : 25}
						onDelete={onDelete}
						onView={onView}
						onUpdate={onUpdate}
						hideView={props.hideView}
						hideUpdate={props.hideUpdate}
						beforeRenderRow={props.beforeRenderRow}
						hideActions={props.hideActions}
						hideDelete={props.hideDelete}
					/>

					<Footer
						cols={props.cols}
						rows={rows}
						page={query?.page || 1}
						count={pageCount || 0}
						rowsPerPage={query?.limit ? Number(query.limit) : 25}
						onPageChange={onPageChange}
						onRowsPerPageChange={onRowsPerPageChange}
					/>
				</Table>
			</Paper>
			<Popper open={open} anchorEl={anchorEl} placement={"left"} transition>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper className={classes.removeContainer}>
							<p>
								<strong>DESEJA REMOVER O ITEM?</strong>
							</p>
							<Button
								className={classes.margin}
								size="small"
								onClick={onDeleteCancel}
							>
								Não
							</Button>
							<Button
								className={classes.margin}
								size="small"
								variant="contained"
								onClick={onDeleteConfirm}
							>
								Sim
							</Button>
						</Paper>
					</Fade>
				)}
			</Popper>
		</>
	);
};

Component.displayName = "Components_Table_TablePagination";

export default Component;
