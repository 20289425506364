import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";

export type Discount = {
  id: number;
  alg: string;
  name: string;
  description: string;
  value: number;
  meta: string;
};

export type Resource = {
  id: number;
  name: string;
  slug: number;
  description: string;
};

export type Plan = {
  id: number;
  name: string;
  slug: string;
  description: string | null;
  resources: Array<Resource>;
  period: "mensal" | "anual";
  displayName: string;
  value: number;
};

/**
 * findALl
 */
export async function findAll(query?: { main: boolean }): Promise<Array<Plan>> {
  const queryString = qs.stringify(query || {}, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/plans/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * findAllResources
 */
export async function findAllResources(): Promise<Array<Resource>> {
  try {
    const response = await axios.get(`/plans/resources`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * findAllDiscounts
 */
export async function findAllDiscounts(): Promise<Array<Discount>> {
  try {
    const response = await axios.get(`/plans/discounts`, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * update discount
 */
export async function updateDiscount(body: Discount) {
  try {
    const response = await axios.put(`/plans/discounts/${body.id}`, body, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * update
 */
export async function update(body: any) {
  try {
    const response = await axios.put(`/plans/${body.id}`, body, {
      useToken: true,
    });

    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * update resource to plan
 */
export async function updateResource(body: Resource) {
  try {
    const response = await axios.put(`/plans/resources/${body.id}`, body, {
      useToken: true,
    });
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * add resource to plan
 */
export async function addResourceToPlan(planId: number, resourceId: number) {
  try {
    const response = await axios.post(
      `/plans/${planId}/resources`,
      {
        resourceId,
      },
      {
        useToken: true,
      }
    );

    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * remove resource to plan
 */
export async function removeResourceFromPlan(
  planId: number,
  resourceId: number
) {
  try {
    const response = await axios.delete(
      `/plans/${planId}/resources/${resourceId}`,
      {
        useToken: true,
      }
    );

    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
