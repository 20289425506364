import * as React from "reactn";
import {
	makeStyles,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
} from "@material-ui/core";

import replaceAll from "_helpers/replaceAll";
import { cellphone } from "_helpers/masks";
import Countries, { ICountry } from "./countries";

const useStyles = makeStyles((theme) => ({
	container: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		overflow: "hidden",
	},

	inputDDI: {
		width: 120,
		marginRight: 20,
	},
	spacing: {
		margin: theme.spacing(2, 0),
	},
}));

const normalize = (value: string) => {
	value = replaceAll(value, "(", "");
	value = replaceAll(value, ")", "");
	value = replaceAll(value, " ", "");
	value = replaceAll(value, "-", "");
	return value;
};

export const getPhoneCountryCodeTag = (value: string) => {
	if (value) {
		const code = /\[\w+\]/gm.exec(value);
		if (code && code.length) {
			return code[0];
		}
	}
	return "";
};

export const getPhoneCountryCode = (value: string) => {
	return getPhoneCountryCodeTag(value).replace("[", "").replace("]", "");
};

export const getDDI = (phone: string): string | null => {
	if (phone) {
		const ddi = /\+\d+/gm.exec(phone);
		if (ddi && ddi.length) {
			return ddi[0];
		}
	}
	// let codes: any = phone.match(/\((.*?)\)/gm);
	// if (codes && codes.length > 0) {
	//   const code = codes[0].replace("(", "").replace(")", "");
	//   const finded = countries.find((c: ICountry) => c.dialCode === code);
	//   if (finded) {
	//     return code;
	//   } else {
	//     return null;
	//   }
	// }
	return null;
};

export const getNumber = (phone: string): string | null => {
	let num = phone;
	if (!num) {
		return num;
	}
	const code = getPhoneCountryCodeTag(num);
	if (code && code.length) {
		num = num.replace(code, "").trim();
	}
	const ddi = getDDI(num);
	if (!ddi) {
		return num;
	}
	// return phone.indexOf(")") !== -1 ? phone.slice(phone.indexOf(")") + 1) : null;
	return num.replace(ddi, "").trim();
};

export const getPhoneMask = (_selected: ICountry | null, value: string) => {
	if (_selected && value && value.trim().length) {
		let nextMask = _selected.mask;
		if (_selected.mask.length > 1) {
			// const hasNextMask = _selected.mask.find(
			//   (m: string) => normalize(m).length >= normalize(value).length
			// );
			// if (hasNextMask) {
			//   nextMask = hasNextMask;
			// }
			nextMask = _selected.mask;
		}
		return nextMask;
	}
	return "";
};

export const fullPhoneNumber = (country: ICountry | null, number: string) => {
	if (country) {
		const currentMask = getPhoneMask(country, number);
		return `${country.dialCode} ${cellphone(number)}`;
	}
	return number;
};

export const fullPhoneNumberWithCode = (
	country: ICountry | null,
	number: string
) => {
	if (country) {
		const currentMask = getPhoneMask(country, number);
		return `[${country.code}]${country.dialCode} ${cellphone(number)}`;
	}
	return number;
};

type Props = {
	onChange: (newState: string) => void;
	value: string;
	label: string;
	error?: boolean;
	helperText?: any;
	DDIChange: string;
	setDDIChange: (e: string) => void;
	numberChange: string;
	setNumberChange: (e: string) => void;
};

const Component: React.FC<Props> = (props) => {
	const classes = useStyles();
	const [number, setNumber] = React.useState<string>("");
	let existsDDI = !!Countries.find(
		(c: ICountry) => c.dialCode === `+${props.DDIChange}`
	);
	return (
		<div className={classes.container} style={{ alignItems: "flex-start" }}>
			<FormControl
				variant="outlined"
				fullWidth={true}
				className={`${classes.inputDDI} ${classes.spacing} `}
			>
				<InputLabel id="ddi-id">País</InputLabel>
				<Select
					fullWidth
					id="ddi-id"
					label="Código do País"
					variant="outlined"
					value={props.DDIChange ? `+${props.DDIChange}` : ""}
					onChange={(e: any) => {
						const countryCode = e.target.value;
						props.setDDIChange(countryCode.replace("+", "")); // Remova o sinal de adição
					}}
				>
					{Countries.sort((a, b) => a.country.localeCompare(b.country)).map(
						(country) => (
							<MenuItem key={country.dialCode} value={country.dialCode}>
								{`${country.dialCode} ${country.country}`}
							</MenuItem>
						)
					)}
				</Select>
			</FormControl>

			<TextField
				fullWidth
				variant="outlined"
				margin="normal"
				// type={"number"}
				label={props.label}
				value={props.numberChange}
				onChange={(e: any) =>
					props.setNumberChange(e.target.value.replace(/[^0-9]/g, ""))
				}
				// autoComplete="new-password"
				error={props.error || false}
				helperText={props.helperText || ""}
				// placeholder="DDD+TELEFONE"
			/>
		</div>
	);
};

Component.displayName = "Components_UI_Phone";

export default Component;
