import * as React from "reactn";
import { State } from "reactn/default";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import {
  save as saveAction,
  remove as removeAction,
  findAll as getGroupQuestionAction,
} from "_state/groupQuestion";
import { MenuItem, Select, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  remove: {
    cursor: "pointer",
    padding: 0,
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

type Props = {
  onChange: (selected: number) => void;
  selected: number | null;
};

type IGroup = {
  id: number;
  name: string;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const ref = React.useRef<any>(null);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");
  const [groups] = React.useGlobal<State, "groupQuestion">("groupQuestion");
  const getGroups = React.useDispatch(getGroupQuestionAction);
  const save = React.useDispatch(saveAction);
  const remove = React.useDispatch(removeAction);

  React.useLayoutEffect(() => {
    if (ref?.current) {
      ref?.current?.focus();
    }
  }, []);

  React.useEffect(() => {
    if (groups.length == 0) {
      getGroups();
    }
  }, []);

  const createGroup = async () => {
    const global: any = await save(name);
    const lastIndex = global.groupQuestion.length - 1;
    const group = global.groupQuestion[lastIndex];
    setName("");
    setShowModal(false);
    props.onChange(group.id);
  };

  const removeGroup = async (id: number) => {
    await remove(id);
  };

  return (
    <div className={classes.container}>
      <Select
        label="GRUPO"
        fullWidth
        placeholder="GRUPO"
        labelId="select-group-question"
        id="select-group-question"
        value={props.selected || ""}
        variant="outlined"
        renderValue={(selected: any) =>
          groups.find((g: any) => g.id == selected)?.name || ""
        }
        onChange={(e: any) => {
          props.onChange(e.target.value || null);
        }}
      >
        <MenuItem value="">
          <em>--</em>
        </MenuItem>
        <MenuItem
          value=""
          onClick={(e: any) => {
            e.preventDefault();
            setShowModal(true);
          }}
        >
          <Button type="submit" color="primary" startIcon={<AddIcon />}>
            ADICIONAR GRUPO
          </Button>
        </MenuItem>
        {groups.map((group: IGroup) => (
          <MenuItem value={group.id} className={classes.menuItem}>
            <span>{group.name}</span>
            <IconButton
              aria-label="Remover"
              className={classes.remove}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                removeGroup(group.id);
              }}
            >
              <CloseIcon />
            </IconButton>
          </MenuItem>
        ))}
      </Select>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          <TextField
            inputRef={ref}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Nome"
            onChange={(e: any) => {
              setName(e.target.value);
            }}
            value={name}
            autoComplete="off"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading || name == ""}
            onClick={createGroup}
          >
            {loading ? "SALVANDO..." : "SALVAR"}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Component.displayName = "Components_Quiz_Forms_Question_Groups_Select";

export default Component;
