import React, { useEffect, useGlobal } from "reactn";
import { Router, Route, Switch, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import routes from "./routes";
import { State } from "reactn/default";
import { IRouter } from "./types";
import history from "./history";
import "moment/locale/pt-br";
moment.locale("pt-br");

type MainProps = RouteComponentProps & IRouter & { render: any };

const Main = (props: MainProps) => {
  const [auth] = useGlobal<State, "auth">("auth");
  useEffect(() => {
    if (props.isPrivate && !auth.isAuthenticated) {
      props.history.push("/");
    } else if (auth.isAuthenticated && props.redirectToDashboardIfLogged) {
      props.history.push("/profissionais");
    }
  }, []);

  const Component = props.render;
  return (
    <div className="page">
      <Component {...props} />
    </div>
  );
};

/**
 * Routes
 */
export default () => {
  return (
    <Router history={history}>
      <Route
        render={(p: RouteComponentProps) => {
          return (
            <Switch location={p.location}>
              {routes
                .filter((r) => !r.isModal)
                .map((route: any) => {
                  const Component = route.Component;
                  const exact = route.hasOwnProperty("exact")
                    ? route.exact
                    : true;
                  return (
                    <Route exact={exact} key={route.path} path={route.path}>
                      {(props: RouteComponentProps) => {
                        return (
                          <Main {...props} {...route} render={Component} />
                        );
                      }}
                    </Route>
                  );
                })}
            </Switch>
          );
        }}
      />
    </Router>
  );
};
