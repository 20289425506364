import axios from "axios";
import { HandlerError } from "_errors";

/**
 * save
 */
export async function save(quizId: number, body: any) {
  try {
    let response = null;

    if (body.id) {
      response = await axios.put(
        `/quizzes/${quizId}/scores/${body.id}`,
        body,
        {
          useToken: true,
        }
      );
    } else {
      response = await axios.post(`/quizzes/${quizId}/scores/`, body, {
        useToken: true,
      });
    }

    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(quizId: number, id: number) {
  try {
    const response = await axios.delete(
      `/quizzes/${quizId}/scores/${id}`,
      {
        useToken: true,
      }
    );
    return response.data;
  } catch (e:any) {
    throw new HandlerError(e);
  }
}
