import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import AppError from "src/errors/AppError";
import * as services from "_services";
import { QuizOption } from "src/services/quizzes";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        flexGrow: 1,
    },
    options: {},
    option: {
        "& > .MuiTypography-body1": {
            fontSize: "14px !important",
        },
    },
    actions: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginTop: theme.spacing(3),
    },
    legend: {
        marginBottom: theme.spacing(4),
    },
}));

type Option = {
    description: string;
    strong: number;
};

const OPTIONS = [
    [
        {
            description: "Nunca",
            strong: 1,
            showDescriptionInApp: true,
        },
        {
            description: "Raramente",
            strong: 2,
            showDescriptionInApp: false,
        },
        {
            description: "Às Vezes",
            strong: 3,
            showDescriptionInApp: true,
        },
        {
            description: "Frequentemente",
            strong: 4,
            showDescriptionInApp: false,
        },
        {
            description: "Sempre",
            strong: 5,
            showDescriptionInApp: true,
        },
    ],
    [
        {
            description: "Nunca",
            strong: 5,
            showDescriptionInApp: true,
        },
        {
            description: "Raramente",
            strong: 4,
            showDescriptionInApp: false,
        },
        {
            description: "Às Vezes",
            strong: 3,
            showDescriptionInApp: true,
        },
        {
            description: "Frequentemente",
            strong: 2,
            showDescriptionInApp: false,
        },
        {
            description: "Sempre",
            strong: 1,
            showDescriptionInApp: true,
        },
    ],
    [
        {
            description: "Sim",
            strong: 5,
            showDescriptionInApp: true,
        },
        {
            description: "Frequentemente",
            strong: 4,
            showDescriptionInApp: false,
        },
        {
            description: "Às Vezes",
            strong: 3,
            showDescriptionInApp: true,
        },
        {
            description: "Raramente",
            strong: 2,
            showDescriptionInApp: false,
        },
        {
            description: "Não",
            strong: 1,
            showDescriptionInApp: true,
        },
    ],
    [
        {
            description: "Sim",
            strong: 5,
            showDescriptionInApp: true,
        },
        {
            description: "Não",
            strong: 1,
            showDescriptionInApp: true,
        },
    ],
];

type Props = {
    onCancel?: () => void;
    onSuccess?: () => void;
    onError?: (e: AppError | null) => void;
    option?: QuizOption;
    questionId: number;
    quizId: number;
};

const Component: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [error, setError] = React.useState<AppError | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<number>(1);

    const onCancel = async (): Promise<void> => {
        if (props.onCancel) props.onCancel();
    };

    const onSubmit = async () => {
        setError(null);
        setLoading(true);

        const options = OPTIONS[selected];

        try {
            for (let index = 0; index < options.length; index++) {
                const option: Option = options[index];
                await services.options.save(props.quizId, props.questionId, {
                    description: option.description,
                    strong: option.strong,
                    order: index,
                });
            }

            // await Promise.all(
            //   options.map((option: Option, index: number) => {
            //     return services.options.save(props.quizId, props.questionId, {
            //       description: option.description,
            //       strong: index,
            //       order: index,
            //     });
            //   })
            // );
            if (props.onSuccess) props.onSuccess();
        } catch (e: any) {
            setError(e as any);
            if (props.onError) props.onError(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.options}>
                <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.legend}>
                        Selecione um dos conjutos de opções
                    </FormLabel>
                    <RadioGroup
                        name="template"
                        value={selected.toString()}
                        onChange={(e: any) => setSelected(e.target.value)}
                    >
                        {OPTIONS.map((options: Array<Option>, key: any) => {
                            const value = options
                                .map(
                                    (o: Option) =>
                                        `${o.description || " --  "}(${
                                            o.strong
                                        })`
                                )
                                .join(" / ");
                            return (
                                <FormControlLabel
                                    key={key}
                                    className={classes.option}
                                    value={key.toString()}
                                    control={<Radio />}
                                    label={value}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </div>
            <div className={classes.actions}>
                <Button disabled={loading} onClick={onCancel}>
                    CANCELAR
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={onSubmit}
                >
                    {loading ? "SALVANDO..." : "CONFIRMAR"}
                </Button>
            </div>
        </div>
    );
};

Component.displayName = "Components_Quiz_Forms_Option_CreateOrUpdates";

export default Component;
