import React from "reactn";
import { useHistory, RouteProps } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Content from "_components/Content";
import { PrivatePage } from "_components/Layout";
import { success as successReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const success = React.useDispatch<any>(successReducer);

  const onSuccess = () => {
    success("Post cadastrado com sucesso");
    history.push("/conteudos");
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "CONTEÚDOS",
                to: "/conteudos",
              },
              {
                label: "CADASTRAR",
              },
            ]}
          />
        }
      ></AppBar>
      <Content.Form onSuccess={onSuccess} />
    </PrivatePage>
  );
};

Component.displayName = "screens_Content_Create";

export default Component;
