import React from "reactn";
import { useHistory, RouteProps } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Specialty from "_components/Specialty";
import { PrivatePage } from "_components/Layout";
import { success as successReducer } from "_state/alert";

const Component: React.FC<RouteProps> = () => {
  const history = useHistory();
  const success = React.useDispatch<any>(successReducer);

  const onSuccess = () => {
    success("Área de atuação cadastrada com sucesso");
    history.push("/areas-de-atuacao");
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "ÁREAS DE ATUAÇÃO",
                to: "/areas-de-atuacao",
              },
              {
                label: "CADASTRAR",
              },
            ]}
          />
        }
      ></AppBar>
      <Specialty.Form onSuccess={onSuccess} />
    </PrivatePage>
  );
};

Component.displayName = "screens_Specialty_Create";

export default Component;
