import axios from "axios";
import { HandlerError } from "_errors";
import * as qs from "qs";
import { IQuery } from "./PaginateDatasource/types";

// TODO: ...

type IDoctorPlanStatus = {
  TRIAL: "TRIAL";
  ENABLED: "ENABLED";
  DISABLED: "DISABLED";
  SUSPENDED: "SUSPENDED";
  PAYMENT_FAILED: "PAYMENT_FAILED";
};

type IDoctorStatus = {
  ENABLED: "ENABLED";
  DISABLED: "DISABLED";
  PENDING: "PENDING";
};

type IDoctorMedia = {
  id: number;
  mediaId: number;
  doctorId: number;
  tag: string;
  media: {
    id: number;
    name: string;
    extension: string;
    src: string;
    description: string | null;
    createdAt: Date;
    updatedAt: Date | null;
  };
};

export type IDoctor = {
  id: number;
  authId: number;
  auth?: {
    id: number;
    email: string;
  };
  specialties: Array<{
    id: number;
    name: string;
  }>;
  schedules: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
  genders: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
  types: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
  name: string;
  email: string;
  bio: string;
  description: string;
  education: string;
  crm: string;
  cpf: string;
  phone: string;
  cellphone: string;
  neighborhood: string;
  street: string;
  number: number;
  cep: string;
  complement: string;
  city: string;
  state: string;
  country: string;
  lat: string;
  lng: string;
  birthdate: Date;
  gender: string;
  authStatus: string;
  assistancePacientAgeGroup: string;
  quizAssistance: boolean;
  hide: boolean;
  status: string;
  media: IDoctorMedia;
  pendingFields: any;
  createdAt?: Date;
  updatedAt?: Date;
  financeSubscriptionId: string | null;
  planStatus: string | null;
  plan: {
    id: number;
    interval: number;
    description: string;
    gatewayId: string;
    name: string;
    period: string;
    plan: string;
    slug: string;
    value: string;
  };
};

export type IDoctorProfile = {
  id: number;
  name: string;
  avatar: string | null;
  bio: string;
  neighborhood: string;
  street: string;
  number: number;
  cep: string;
  complement: string;
  city: string;
  state: string;
  country: string;
  lat: string;
  lng: string;
  types: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
  specialties?: Array<{
    id: number;
    name: string;
  }>;
  cellphone?: string;
  rating: number | null;
  facebook: string;
  instagram: string;
  linkedin: string;
  pendingFields: Array<any> | null;
  status: IDoctorStatus;
  planStatus: IDoctorPlanStatus | null;
  financeSubscriptionId: string | null;
};

export type IDoctorQuery = {
  specialties?: Array<number>;
  doctorId?: number;
  types?: Array<number>;
  genders?: Array<number>;
  schedules?: Array<number>;
  ageGroup?: Array<number>;
  doctorName?: string;
  quiz?: any;
  quizId?: number;
  hide?: boolean;
};

/**
 * findALl
 */
export async function findAll(query: IQuery) {
  const queryString = qs.stringify(query, {
    arrayFormat: "brackets",
    encode: true,
    addQueryPrefix: true,
  });
  try {
    const response = await axios.get(`/doctors/${queryString}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * findById
 */
export async function findById(id: number) {
  try {
    const response = await axios.get(`/doctors/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * export
 */
export async function exportData() {
  try {
    const response = await axios.get(`/doctors/export`, {
      useToken: true,
      responseType: "blob",
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * create
 */
export async function create(body: any) {
  try {
    const response = await axios.post(`/doctors/create-by-admin`, body, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * update
 */
export async function update(body: any) {
  try {
    const response = await axios.put(`/doctors/${body.id}`, body, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * remove
 */
export async function remove(id: number) {
  try {
    const response = await axios.delete(`/doctors/${id}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * saveFile
 */
export async function saveFile(doctorId: number, data: FormData) {
  try {
    const response = await axios.post(`/doctors/${doctorId}/file`, data, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * removeFile
 */
export async function removeFile(doctorId: number, fileId: number) {
  try {
    const response = await axios.delete(`/doctors/${doctorId}/file/${fileId}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}

/**
 * downloadFile
 */
export async function downloadFile(doctorId: number, src: string) {
  try {
    const response = await axios.get(`/doctors/${doctorId}/file/${src}`, {
      useToken: true,
    });
    return response.data;
  } catch (e) {
    throw new HandlerError(e);
  }
}
