import { Button } from "@material-ui/core";
import React from "react";
import { RouteProps, Link } from "react-router-dom";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import * as Plan from "_components/Plan";
import { PrivatePage } from "_components/Layout";

const Component: React.FC<RouteProps> = () => {
  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "PLANOS",
              },
            ]}
          />
        }
        Right={
          <>
            <Button
              size="small"
              variant="contained"
              color="primary"
              component={Link}
              to="/planos/descontos"
              style={{ marginRight: 20 }}
            >
              DESCONTOS
            </Button>

            <Button
              size="small"
              variant="contained"
              color="primary"
              component={Link}
              to="/planos/recursos"
            >
              RECURSOS
            </Button>
          </>
        }
      ></AppBar>
      <Plan.List />
    </PrivatePage>
  );
};

Component.displayName = "screens_Plan_List";

export default Component;
