import React from "reactn";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  loading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 99999999,
    background: "#ffffff42",
  },
}));

type Props = {
  show: boolean;
  style?: any;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      {props.show && (
        <div className={classes.loading} style={props.style || {}}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );
};

export default Component;
