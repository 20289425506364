import { initialState as auth } from "./auth";
import { initialState as alert } from "./alert";
import { initialState as specialty } from "./specialty";
import { initialState as groupQuestion } from "./groupQuestion";
export default {
  auth,
  alert,
  specialty,
  groupQuestion: [],
};
