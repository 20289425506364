import React from "reactn";
import * as Table from "_components/Table";
import PaginateDatasource from "_services/PaginateDatasource";
import * as services from "_services";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import history from "../../../routes/history";

const datasource = new PaginateDatasource<{
  id: number;
  name: string;
}>(services.terms);

const cols = [
  // {
  //   id: "id",
  //   label: "ID",
  //   sort: true,
  // },
  {
    id: "version",
    label: "Versão",
    sort: true,
  },
  {
    id: "category",
    label: "Documento",
    sort: true,
    render: (term: any) => {
      return <span>{term.titlePt}</span>;
    },
  },
  {
    id: "isDraft",
    label: "Status",
    sort: true,
    render: (term: any) => {
      return (
        <div
          style={{
            background: term.isDraft ? "#ee9229" : "#4a0b61",
            padding: "3px 10px",
            borderRadius: "100px",
            display: "inline-block",
            fontSize: "12px",
            color: "#FFF",
          }}
        >
          {term.isDraft ? "RASCUNHO" : "PUBLICADO"}
        </div>
      );
    },
  },
  {
    id: "publishedAt",
    label: "Data da publicação",
    sort: true,
    render: (term: any) => {
      if (term.publishedAt === null) {
        return <span>--</span>;
      }
      return <span>{moment(term.publishedAt).format("DD-MM-YYYY")}</span>;
    },
  },
  {
    id: "id",
    label: "Opções",
    render: (term: any) => {
      // const history = useHistory();

      return (
        <>
          {term.isDraft && (
            <IconButton
              aria-label="Editar"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/documentos/editar/${term.id}`);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}

          {term.isDraft === false && (
            <IconButton
              aria-label="Ver detalhes"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/documentos/visualizar/?id=${term.id}`);
              }}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          )}
        </>
      );
    },
  },
];

type Props = {};

const Component: React.FC<Props> = () => {
  return (
    <>
      <Table.Service
        cols={cols}
        path="/documentos"
        hideActions
        datasource={datasource}
        onRemovedText="Documento deletado com sucesso"
        onRemovedErrorText="Erro ao deletar o documento"
        queryDefault={{
          order: "DESC",
          orderBy: "version",
        }}
      />
    </>
  );
};

Component.displayName = "Components_Term_List";

export default Component;
