import * as React from "reactn";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Paper } from "@material-ui/core";
import logo from "_assets/logo.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError from "_helpers/getInputError";
import { login as loginReducer } from "_state/auth";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "80vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    maxWidth: 280,
  },
  logo: {
    margin: theme.spacing(1),
    width: 190,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  center: {
    textAlign: "center",
  },
  error: {
    margin: theme.spacing(2, 0),
  },
}));

const schema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
  password: yup.string().required("Campo obrigatório"),
});

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const Component: React.FC<Props> = (props) => {
  const login = React.useDispatch<any>(loginReducer);
  const classes = useStyles();
  const [error, setError] = React.useState<AppError | null>(null);
  const [classError, setClassError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    setClassError("");
    setError(null);
    setLoading(true);
    login(data)
      .then(() => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      })
      .catch((e: any) => {
        setError(e as any);
        setClassError("animate__shakeX");
        if (props.onError) {
          props.onError();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Paper elevation={4} className={classes.paper}>
        <img src={logo} className={classes.logo} />
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className={classes.form}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            autoComplete="email"
            autoFocus
            error={errors.hasOwnProperty("email") || false}
            helperText={getInputError(error, errors, "email")}
            {...register("email")}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            error={errors.hasOwnProperty("password") || false}
            helperText={getInputError(error, errors, "password")}
            {...register("password")}
          />

          <ShowErrors.Field error={error} field="server" />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={`${classes.submit} animate__animated ${classError}`}
            disabled={loading}
          >
            {loading ? "ENTRANDO..." : "ENTRAR"}
          </Button>
          <Grid container>
            <Grid item xs className={classes.center}>
              <Link
                component={RouterLink}
                to="/recuperar-senha"
                variant="body2"
              >
                Recuperar senha
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

Component.displayName = "Components_Auth_Login";

export default Component;
