import * as React from "reactn";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Paper } from "@material-ui/core";
import logo from "_assets/logo.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as service from "_services";
import AppError from "src/errors/AppError";
import * as ShowErrors from "_UI/ShowErrors";
import getInputError from "_helpers/getInputError";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "80vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    maxWidth: 280,
  },
  logo: {
    margin: theme.spacing(1),
    width: 190,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
  },
  center: {
    textAlign: "center",
  },
  sended: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontSize: 24,
  },
}));

const schema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
});

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [classError, setClassError] = React.useState<string>("");
  const [error, setError] = React.useState<AppError | null>(null);
  const [sended, setSended] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    setClassError("");
    setError(null);
    setSended(false);
    setLoading(true);
    service.auth
      .forgotPassword(data.email)
      .then(() => {
        setSended(true);
        if (props.onSuccess) {
          props.onSuccess();
        }
      })
      .catch((e: AppError) => {
        setError(e as any);
        setClassError("animate__shakeX");
        if (props.onError) {
          props.onError();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onError = () => {
    setClassError("");
    setTimeout(() => {
      setClassError("animate__shakeX");
    }, 0);
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      {sended ? (
        <Paper
          elevation={4}
          className={`${classes.paper} animate__animated animate__flipInY`}
        >
          <p className={classes.sended}>
            Acesse seu e-mail e siga as instruções para definir a sua nova
            senha.
          </p>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/" variant="body2">
                Voltar para login
              </Link>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper elevation={4} className={classes.paper}>
          <img src={logo} className={classes.logo} />
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className={classes.form}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              autoComplete="email"
              autoFocus
              error={errors.hasOwnProperty("email") || false}
              helperText={getInputError(error, errors, "email")}
              {...register("email")}
            />

            <ShowErrors.Field error={error} field="server" />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={`${classes.submit} animate__animated ${classError}`}
              disabled={loading}
            >
              {loading ? "ENVIANDO..." : "ENVIAR"}
            </Button>
            <Grid container>
              <Grid item xs className={classes.center}>
                <Link component={RouterLink} to="/" variant="body2">
                  Voltar para login
                </Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </Container>
  );
};

Component.displayName = "Components_Auth_ForgotPassword";

export default Component;
