import { AppBar, Toolbar } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(4),
    background: "none",
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },

  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "none",
  },
}));

type Props = {
  Left?: React.ReactNode;
  Right?: React.ReactNode;
  Center?: React.ReactNode;
};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      {(props.Left || props.Right || props.Center) && (
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div>{props.Left}</div>
            <div>{props.Center}</div>
            <div>{props.Right}</div>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

Component.displayName = "components_Layout_AppBar";

export default Component;
