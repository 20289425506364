import { Dispatch, State } from "reactn/default";
import * as service from "_services";
import { IAuthState, ILoginResquest, IUpdatePasswordRequest } from "./types";
import { auth as authStorage } from "_helpers/localStorage";

const INITIAL_STATE: IAuthState = {
  id: "",
  email: "",
  role: "",
  isAuthenticated: false,
};

export const initialState = { ...INITIAL_STATE };

export async function setAuth(
  global: State,
  dispatch: Dispatch,
  data: Partial<IAuthState>
) {
  return {
    auth: {
      ...global.auth,
      ...data,
    },
  };
}

export async function login(
  global: State,
  dispatch: Dispatch,
  data: ILoginResquest
) {
  const response = await service.auth.login(data);

  if (response.role !== "ADMIN") {
    return {
      auth: global.auth,
    };
  }

  const authenticated: IAuthState = {
    ...INITIAL_STATE,
    ...response,
    isAuthenticated: true,
  };

  authStorage.set({
    access_token: response.access_token,
  });

  return {
    auth: {
      ...global.auth,
      ...authenticated,
    },
  };
}

export async function getMe(global: State, dispatch: Dispatch) {
  const response = await service.auth.getMe();
  return {
    auth: {
      ...global.auth,
      ...response,
      isAuthenticated: true,
    },
  };
}

/**
 * logout
 */
export async function logout() {
  authStorage.clear();
  return {
    auth: { ...INITIAL_STATE },
  };
}

/**
 * refreshToken

export async function refreshToken(global: State, dispatch: Dispatch) {
  const token = authStorage.get("refresh_token");
  if (!token) {
    throw "Token não encontrado";
  }

  const authUpdated = await service.auth.refreshToken(token);
  authStorage.set({
    access_token: authUpdated.access_token,
  });

  return {
    auth: {
      ...global.auth,
      login: authUpdated,
    },
  };
}
*/
/**
 * updatePassword
 */
export async function updatePassword(
  global: State,
  dispatch: Dispatch,
  data: IUpdatePasswordRequest
) {
  const response = await service.auth.updatePassword(data);

  const authenticated: IAuthState = {
    ...INITIAL_STATE,
    ...response,
    isAuthenticated: true,
  };

  authStorage.set({
    access_token: response.access_token,
  });

  return {
    auth: {
      ...global.auth,
      ...authenticated,
    },
  };
}
