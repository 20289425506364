import * as React from "reactn";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import AppBar from "_components/Layout/AppBar";
import Breadcrumbs from "_components/Layout/AppBar/Breadcrumbs";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as services from "_services";
import { PrivatePage } from "_components/Layout";
import * as Plan from "_components/Plan";
import { Resource } from "_services/plans";
import { error as errorReducer, success as successReducer } from "_state/alert";

const useStyles = makeStyles((theme) => ({}));

type Props = {};

const Component: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [allResources, setAllResources] = React.useState<Array<Resource>>([]);
  const success = React.useDispatch<any>(successReducer);
  const error = React.useDispatch<any>(errorReducer);

  React.useEffect(() => {
    services.plans.findAllResources().then((response: Array<Resource>) => {
      setAllResources(response);
    });
  }, []);

  const onUpdateResource = (resource: Resource) => {
    setAllResources(
      allResources.map((r: Resource) => {
        if (r.id === resource.id) {
          return resource;
        }
        return r;
      })
    );
    success("Recurso salvo com sucesso");
  };

  const onUpdateResourceError = () => {
    error("Ocorreu um erro ao salvar o recurso");
  };

  return (
    <PrivatePage>
      <AppBar
        Left={
          <Breadcrumbs
            breadcrumbs={[
              {
                label: "PLANOS",
                to: "/planos",
              },
              {
                label: "RECURSOS",
              },
            ]}
          />
        }
        Right={<></>}
      ></AppBar>

      {allResources.map((resource: Resource) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {resource.name}
          </AccordionSummary>
          <AccordionDetails>
            <Plan.Resources
              resource={resource}
              onError={onUpdateResourceError}
              onSuccess={onUpdateResource}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </PrivatePage>
  );
};

Component.displayName = "pages_PLans_Resources";

export default Component;
